import {useState} from "react";

export const getRoleDescription = (role: string) => {
  switch (role) {
    case "Admin":
      return "Authorized to perform any action on the platform including granting permissions to other users, creating policies and managing alerts, automating workloads and viewing all content.";
    case "Operator":
      return "Authorized to automate and attach policies to workloads and view all content.";
    case "Viewer":
      return "Authorized only to view all content.";
    default:
      return "";
  }
};

export enum AuthMethod {
  DISABLED = "Disabled",
  BUILT_IN = "Built-in",
  SSO_WITHOUT_AUTHORIZATION = "SSO",
  SSO_WITH_AUTHORIZATION = "SSO With Authorization",
}

export enum SSOIntegration {
  Okta = "Okta",
  OAuth2 = "OAuth2",
  Github = "Github",
  Google = "Google",
  Azure = "Azure",
  Openshift = "Openshift",
  LDAP = "LDAP",
}

export function getSSOIntegrationFromString(provider: string) {
  switch (provider.toString().toLowerCase()) {
    case "oauth2":
      return SSOIntegration.OAuth2;
    case "github":
      return SSOIntegration.Github;
    case "google":
      return SSOIntegration.Google;
    case "azure":
      return SSOIntegration.Azure;
    case "okta":
      return SSOIntegration.Okta;
    case "openshift":
      return SSOIntegration.Openshift;
    case "ldap":
      return SSOIntegration.LDAP;
    case "none":
    case "simpleauth":
      return SSOIntegration.Okta;
    default:
      return provider;
  }

}

export const SSOIntegrationDocLink = {
  [SSOIntegration.Okta]: "/sso-and-login-security/okta/",
  [SSOIntegration.OAuth2]: "/sso-and-login-security/oidc/",
  [SSOIntegration.Github]: "/sso-and-login-security/github/",
  [SSOIntegration.Google]: "/sso-and-login-security/google/",
  [SSOIntegration.Azure]: "/sso-and-login-security/azure/",
  [SSOIntegration.Openshift]: "/sso-and-login-security/openshift/",
  [SSOIntegration.LDAP]: "/sso-and-login-security/ldap/",
};

export interface SSOConf {
  defaultAdminGroup: string;
  setDefaultAdminGroup: (value: string) => void;
  issuerUrl: string;
  setIssuerUrl: (value: string) => void;
  clientID: string;
  setClientID: (value: string) => void;
  clientSecret: string;
  setClientSecret: (value: string) => void;
  tenantID: string;
  setTenantID: (value: string) => void;
  groupsClaim: string;
  setGroupsClaim: (value: string) => void;
}

export function GetSSOSetupConf(): SSOConf {
  const [defaultAdminGroup, setDefaultAdminGroup] = useState<string>("");
  const [issuerUrl, setIssuerUrl] = useState<string>("");
  const [clientID, setClientID] = useState<string>("");
  const [clientSecret, setClientSecret] = useState<string>("");
  const [tenantID, setTenantID] = useState<string>("");
  const [groupsClaim, setGroupsClaim] = useState<string>("");

  return {
    defaultAdminGroup: defaultAdminGroup,
    setDefaultAdminGroup: setDefaultAdminGroup,
    issuerUrl: issuerUrl,
    setIssuerUrl: setIssuerUrl,
    clientID: clientID,
    setClientID: setClientID,
    clientSecret: clientSecret,
    setClientSecret: setClientSecret,
    tenantID: tenantID,
    setTenantID: setTenantID,
    groupsClaim: groupsClaim,
    setGroupsClaim: setGroupsClaim,
  };
}