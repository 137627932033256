import SnapshotWrapper from "../components/SnapshotWrapper";
import MultipleCubesIcon from "../Icons/MultipleCubesIcon";
import RightSizingIcon from "../Icons/RightSizingIcon";
import UpAndDownCircleIcon from "../Icons/UpAndDownCircleIcon";
import { HAS_UNEVICTABLE_MENU } from "../utils/developmentFeatureFlags";
import useHasMultiProductLayoutWithReactQuery from "../utils/useHasMultiProductLayoutWithReactQuery";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MenuHorizontalRule, { HrType } from "./MenuHorizontalRule";
import MultiMenu from "./MultiMenu";

type Props = {
  hpaOptimizationEnabled?: boolean;
};
const MultiProductMenu = ({ hpaOptimizationEnabled }: Props) => {
  const [hasMultiProductLayout, hasMultiProductLayoutLoading] = useHasMultiProductLayoutWithReactQuery();

  return (
    <>
      <MenuHorizontalRule title="Optimization" />
      <MultiMenu
        baseUrl={{ name: "Pod Rightsizing", url: "/" }}
        urls={[
          { name: "Workloads", url: "/" },
          { name: "Policies", url: "/rightsize/policies" },
        ]}
        icon={<RightSizingIcon />}
        title="Pod Rightsizing"
        MainMenuItemSize={MainMenuItemSize.Small}
      />
      {(hasMultiProductLayoutLoading || hasMultiProductLayout) && (
        <SnapshotWrapper wrappedType="menu" inactive={hpaOptimizationEnabled === undefined || hpaOptimizationEnabled}>
          <MultiMenu
            baseUrl={{ name: "Predictive HPA", url: "/hpa/workloads" }}
            urls={[
              { name: "Workloads", url: "/hpa/workloads", isBeta: true },
              { name: "Policies", url: "/hpa/policies", isBeta: true },
            ]}
            icon={<UpAndDownCircleIcon />}
            title="Predictive HPA"
            MainMenuItemSize={MainMenuItemSize.Small}
          />
        </SnapshotWrapper>
      )}
      {HAS_UNEVICTABLE_MENU && (
        <MainMenuItem
          icon={<MultipleCubesIcon />}
          title="Pod Placement"
          to="/unevictable"
          newChipWrapperClassName="absolute right-[-41px]"
          isNew
        />
      )}
      <MenuHorizontalRule type={HrType.Dashed} />
    </>
  );
};

export default MultiProductMenu;
