import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import InfoIcon from "../../../../Icons/InfoIcon";
import { useMainContext } from "../../../../MainContext";
import { GetTopK, GetTopKParams, GetTopKResponse } from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import ChartTooltipItemsCount from "../../../../components/ChartTooltipItemsCount";
import ChartTooltipTitle from "../../../../components/ChartTooltipTitle";
import CustomLegend from "../../../../components/CustomLegend";
import InfoTooltip from "../../../../components/Tooltip";
import LinkableTooltipElement from "../../../../components/common/LinkableTooltipElement";
import useWindowSize from "../../../../components/useWindowSize";
import { getMaxFlatArray } from "../../../../utils/arrayUtils";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { HAS_NEW_CHARTS_DESIGN } from "../../../../utils/developmentFeatureFlags";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { MAX_Y_AXIS_DOMAIN, OverviewLinkType } from "../../../../utils/graphUtils";
import {
  DOT_RADIUS,
  DOT_STROKE_WIDTH,
  LINE_CHART_TYPE,
  NO_OUTLINE,
  SCROLLABLE_TROUBLESHOOT_TOOLTIP,
  TOOLTIP_WRAPPER_CLASS_NAME,
} from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType, getParsedData, ParsedDataResponse } from "../utils";
import { ANALYTICS_PAGE_DAILY_SYNC_ID } from "./graphUtils";
import FreezeTooltipWarning from "./hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "./hooks/useFreezeTooltip";
import { FrozenTooltipType } from "./hooks/utils";
import { PayloadItem, SecondaryValueFnc } from "./topKUtils";

const NUMBER_OF_ELEMENTS = 15;
const MAX_ELEMENTS_PER_TOOLTIP = 50;
const DEFAULT_SELECTED_ELEMENTS = Infinity;
const CUSTOM_HEIGHT = "h-[200px]";
export const SUM = "#sum";
export const QUANTILE = "#quantile";
const { queryFn, queryKey } = GetTopK();

const defaultRenderNameFunction = (name: string | undefined) => {
  switch (true) {
    case name === SUM:
      return "total";
    case name?.includes(QUANTILE):
      return `${String(name?.replace(QUANTILE, ""))}90th-percentile`;
    default:
      return name;
  }
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: PayloadItem[];
  label?: string;
  renderNameFunction: (name: string | undefined) => string | undefined;
  tooltipTrigger: TooltipTrigger | undefined;
  valueFormatter?: (tick: string) => string;
  frozenTooltipType?: FrozenTooltipType;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
  enableCopyTextOnClick?: boolean;
  targetResource?: OverviewLinkType;
  getSecondaryValue?: SecondaryValueFnc;
  valueTitle?: string;
  isMultiCluster?: boolean;
  keyParser?: (key: string | undefined) => string | undefined;
  elements?: Element[];
}

const CustomTooltip = ({
  active,
  payload,
  label,
  valueFormatter,
  tooltipTrigger,
  renderNameFunction,
  frozenTooltipType = FrozenTooltipType.FrozenAndClickable,
  tooltipId,
  updateActiveTooltips,
  enableCopyTextOnClick,
  targetResource = OverviewLinkType.Workload,
  getSecondaryValue,
  valueTitle,
  isMultiCluster,
  keyParser,
  elements,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  const tooltipData = payload?.[0]?.payload?.valueToolTipData?.filter((item) => !Number.isNaN(item?.value));

  if (active && tooltipData && tooltipData.length) {
    let sortedPayload = tooltipData.sort((a, b) => {
      return String(a.name).localeCompare(String(b.name), undefined, { numeric: true });
    });

    sortedPayload = sortedPayload.sort((a, b) => {
      if (a?.name === SUM) return -1;
      if (b?.name === SUM) return 1;
      return Number(b?.value) - Number(a?.value);
    });

    const maxItemsToShow = MAX_ELEMENTS_PER_TOOLTIP;

    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        {label && <ChartTooltipTitle timestamp={label} valueTitle={valueTitle} />}
        <div className={SCROLLABLE_TROUBLESHOOT_TOOLTIP}>
          {sortedPayload.slice(0, maxItemsToShow).map((item, index) => {
            const name = String(item?.name ?? "");
            const value = Number(item?.value ?? 0);
            const secondaryValues = payload?.[0]?.payload?.secondaryValues;

            const renderedName = renderNameFunction(name);
            const secondaryValue = getSecondaryValue
              ? getSecondaryValue(String(item.name), value, secondaryValues)
              : undefined;
            const parsedKey = keyParser ? keyParser(name) : name;

            return (
              <LinkableTooltipElement
                key={index}
                color={elements?.[index]?.color}
                value={valueFormatter ? valueFormatter(String(value)) : value}
                secondaryValue={secondaryValue}
                label={<div className="max-w-[277px] truncate rtl">{renderedName}</div>}
                rawLabel={String(parsedKey)}
                disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
                textToCopyOnClick={enableCopyTextOnClick ? renderedName : undefined}
                targetResource={targetResource}
                source={isMultiCluster ? OverviewLinkType.MultiCluster : OverviewLinkType.Workload}
                hasLimitedWidth
              />
            );
          })}
        </div>
        <ChartTooltipItemsCount count={sortedPayload.length} maxToShow={maxItemsToShow} />
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

type Element = {
  key: string;
  label: string;
  color: string;
  tooltipValueColor?: string;
  fill?: string;
  dataKey?: string;
};

interface Props {
  title: React.ReactNode;
  queryParams: GetTopKParams;
  showPercentage?: boolean;
  wrapDivClassName?: string;
  setDate: (date: DateType) => void;
  YAxisDomain?: AxisDomain | undefined;
  noGroupBy?: boolean;
  YAxisFormatter?: (tick: string) => string;
  isMulticluster?: boolean;
  topK?: number;
  withSum?: boolean;
  infoTooltip?: React.ReactNode;
  infoTooltipMaxWidth?: number;
  syncId?: string | null;
  renderNameFunction?: (name: string | undefined) => string | undefined;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  dotted?: boolean;
  enableCopyTextOnClick?: boolean;
  hasLegend?: boolean;
  customLegendBySeparator?: string;
  YAxisType?: "number" | "category";
  yTickLine?: boolean;
  allowDecimals?: boolean;
  getParsedDataFixedValue?: number;
  targetTooltipLink?: OverviewLinkType;
  getSecondaryValue?: SecondaryValueFnc;
  tooltipValueTitle?: string;
  children?: React.ReactNode;
  keyParser?: (key: string | undefined) => string | undefined;
  hasStackedOffset?: boolean;
  height?: string;
}

const TopValuesGraph = ({
  title,
  queryParams,
  showPercentage,
  wrapDivClassName,
  setDate,
  YAxisDomain,
  noGroupBy,
  YAxisFormatter = (tick: string) => tick,
  isMulticluster,
  topK,
  withSum,
  infoTooltip,
  infoTooltipMaxWidth = 660,
  syncId = ANALYTICS_PAGE_DAILY_SYNC_ID,
  renderNameFunction = defaultRenderNameFunction,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType = FrozenTooltipType.Disabled,
  updateActiveTooltips,
  dotted,
  enableCopyTextOnClick,
  hasLegend,
  customLegendBySeparator,
  YAxisType,
  yTickLine,
  allowDecimals,
  getParsedDataFixedValue,
  targetTooltipLink,
  getSecondaryValue,
  tooltipValueTitle,
  children,
  keyParser,
  hasStackedOffset,
  height = CUSTOM_HEIGHT,
}: Props) => {
  const { currentCluster, didClusterChanged } = useMainContext();

  const filtersQueryParams = useFilterQueryParams();
  const { width: windowWidth } = useWindowSize();

  const [elements, setElements] = useState<Element[]>([]);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const [chartComponents, setChartComponents] = useState<string[]>([]);
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>(chartComponents);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [viewPeriodInHours, setViewPeriodInHours] = useState<number>(3 * 24);
  const [graphData, setGraphData] = useState<ParsedDataResponse>([]);
  const [maxDataPoint, setMaxDataPoint] = useState<number>(0);

  const [firstXPointString, setFirstXPointString] = useState<string>("");
  const [lastXPointString, setLastXPointString] = useState<string>("");
  const [firstXPointEpoch, setFirstXPointEpoch] = useState<number>(0);
  const [lastXPointEpoch, setLastXPointEpoch] = useState<number>(0);

  const { data, isLoading, error, isError } = useQuery<GetTopKResponse, Error>({
    queryKey: [
      queryKey,
      queryParams,
      filtersQueryParams,
      isMulticluster ? "multicluster" : currentCluster,
      topK ? `topK-${topK}` : undefined,
      withSum ? "withSum" : undefined,
    ],
    queryFn: () =>
      queryFn({
        ...queryParams,
        multiCluster: isMulticluster,
        ...filtersQueryParams,
        topK,
        withSum,
        timeoutSeconds: timeoutSeconds,
      }),
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
    enabled: isQueryEnabled,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    if (data?.values) {
      const firstDate = adjustedDayjs(data.values[0].timestamp);
      const lastDate = adjustedDayjs(data.values[data.values.length - 1].timestamp);
      setViewPeriodInHours(lastDate.diff(firstDate, "hours"));
    }
  }, [queryKey, queryParams, filtersQueryParams, isMulticluster, topK, withSum]);

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (!data) return;
    let graphDataToSet: ParsedDataResponse = getParsedData(
      data,
      showPercentage,
      false,
      queryParams.from ? Number(queryParams.from) : undefined,
      queryParams.to ? Number(queryParams.to) : undefined,
      undefined,
      noGroupBy,
      false,
      true,
      getParsedDataFixedValue
    );

    graphDataToSet = graphDataToSet.map((item) => {
      const values = Object.entries(item).reduce((acc, [key, value]) => {
        return key === "date" && !Number.isNaN(value) ? acc : { ...acc, [key]: Number(value) };
      }, {});

      const sortedValues = Object.entries(values)
        .sort(([key1, value1], [key2, value2]) => {
          if (key1 === SUM) return -1;
          if (key2 === SUM) return 1;
          if (key1.includes(QUANTILE)) return -1;
          if (key2.includes(QUANTILE)) return 1;
          return Number(value2) - Number(value1);
        })
        .reduce((acc, [, value], index) => {
          if (index < NUMBER_OF_ELEMENTS) {
            return { ...acc, [`value-${index}`]: value };
          }
          return acc;
        }, {});

      return {
        date: item.date,
        ...sortedValues,
        valueToolTipData: Object.entries(item)
          .map(([key, value]) => {
            if (key === "date") return null;
            return {
              name: key,
              value: Number(value),
            };
          })
          .filter((item) => !!item && item?.name !== "date"),
        secondaryValues: item.secondaryValues as string | number | ({ name: string; value: number } | null)[],
      };
    });

    setGraphData(graphDataToSet);

    setMaxDataPoint(
      Math.ceil(
        getMaxFlatArray(
          graphDataToSet.map((item) => {
            return Object.values(item)
              .filter((value) => !Number.isNaN(Number(value)))
              .map((value) => Number(value));
          })
        ) * MAX_Y_AXIS_DOMAIN
      )
    );

    const firstXPointStringToSet = String(graphDataToSet[0]?.date);
    setFirstXPointString(firstXPointStringToSet);
    setFirstXPointEpoch(adjustedDayjs(firstXPointStringToSet).unix() * 1000);
    let lastXPointStringToSet = graphDataToSet && String(graphDataToSet[graphDataToSet.length - 1]?.date);
    lastXPointStringToSet = lastXPointString && `${lastXPointString} GMT`;
    setLastXPointString(lastXPointStringToSet);
    setLastXPointEpoch(adjustedDayjs(lastXPointStringToSet).unix() * 1000);
  }, [data, showPercentage, queryParams.from, queryParams.to, noGroupBy, getParsedDataFixedValue]);

  useEffect(() => {
    if (graphData) {
      const uniqueItemNames = Array.from({ length: NUMBER_OF_ELEMENTS }, (_, i) => `value-${i}`);
      setChartComponents(uniqueItemNames);
      setSelectedChartComponents(uniqueItemNames.slice(0, DEFAULT_SELECTED_ELEMENTS));
      setElements(
        uniqueItemNames.map((item, index) => {
          const color = SCALEOPS_COLORS.randomColors[index] ?? "#" + Math.random().toString(16).slice(2, 8);
          return {
            key: item,
            label: item,
            color,
          };
        })
      );
    }
  }, [graphData]);

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  elements.forEach((element) => {
    legendComponentStyle[element.key] = {
      color: element.color,
    };
  });

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [queryParams, filtersQueryParams]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div
        className={clsx(
          height,
          "bg-white border border-border rounded min-h-full w-full flex justify-center items-center"
        )}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error && didClusterChanged) {
    toast.error(`${queryParams?.queryKey ?? ""} chart could not load as for this cluster`);
    console.log("error loading chart", title, error);
  }

  if (isError) {
    console.log(error);
    return null;
  }

  return (
    <div
      className={clsx(wrapDivClassName, "bg-white min-h-full border rounded unselectable-svg-text", {
        "pt-8 pb-4": hasLegend,
        "py-8 pr-8": !hasLegend,
        "border-black": HAS_NEW_CHARTS_DESIGN,
        "border-border": !HAS_NEW_CHARTS_DESIGN,
      })}
    >
      <div className={height}>
        <Typography
          variant="body2"
          fontWeight={HAS_NEW_CHARTS_DESIGN ? 700 : undefined}
          className="w-full flex justify-center items-center gap-1"
        >
          {title} <span className="text-text-darkGray text-[0.625rem]"> (sorted)</span>
          {infoTooltip && (
            <InfoTooltip title={infoTooltip} placement="top" maxWidth={infoTooltipMaxWidth}>
              <InfoIcon width={14} height={14} />
            </InfoTooltip>
          )}
        </Typography>
        <ResponsiveContainer width="100%" height="100%" className="pr-4">
          <ComposedChart
            syncId={syncId ? syncId : undefined}
            data={graphData}
            onMouseDown={(e) => {
              e.activeLabel &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  from: adjustedDayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              selectPosition?.from &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  to: adjustedDayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  toX: e.activeLabel,
                });
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to) {
                if (selectPosition?.from < selectPosition?.to) {
                  setSelectPosition({
                    ...selectPosition,
                    to: lastXPointEpoch,
                    toX: lastXPointString,
                  });
                } else {
                  setSelectPosition({
                    to: selectPosition.from,
                    toX: selectPosition.fromX,
                    from: firstXPointEpoch,
                    fromX: firstXPointString,
                  });
                }
              }
            }}
          >
            <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
            <Tooltip
              content={
                <CustomTooltip
                  valueFormatter={YAxisFormatter}
                  renderNameFunction={renderNameFunction}
                  frozenTooltipType={frozenTooltipType}
                  tooltipTrigger={tooltipTrigger}
                  tooltipId={queryKey}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick={enableCopyTextOnClick}
                  targetResource={targetTooltipLink}
                  getSecondaryValue={getSecondaryValue}
                  valueTitle={tooltipValueTitle}
                  isMultiCluster={isMulticluster}
                  keyParser={keyParser}
                  elements={elements}
                />
              }
              trigger={tooltipTrigger}
              wrapperStyle={NO_OUTLINE}
            />
            <XAxis
              dataKey="date"
              style={{ fontSize: "x-small" }}
              interval={Math.floor(graphData.length / (Number(windowWidth) / 300))}
              strokeWidth={2}
              tickFormatter={(value) => {
                const epochValue = adjustedDayjs(String(value)).unix();
                return getTimeFormatFromEpochAndPeriodInHours(epochValue, viewPeriodInHours);
              }}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={YAxisDomain ?? [0, maxDataPoint || Infinity]}
              type={YAxisType}
              strokeWidth={2}
              tickFormatter={(tick) => YAxisFormatter(String(tick))}
              tickLine={yTickLine}
              allowDecimals={allowDecimals}
            />
            {elements.map((element, index) => {
              const elementData = elements.find((e) => e.key === element.key);
              if (!elementData || !selectedChartComponents.includes(elementData.key)) return null;
              const { key, label, color, fill } = elementData;

              return (
                <Area
                  type={LINE_CHART_TYPE}
                  strokeWidth={dotted ? 0 : key === SUM ? 2 : 2} //@barel22 keep in case we want to change the SUM in the future
                  dataKey={key}
                  name={label}
                  stroke={color}
                  fill={fill}
                  fillOpacity={fill ? 0.4 : 0}
                  radius={dotted ? DOT_RADIUS : 0}
                  dot={{ r: dotted ? 1 : 0, strokeWidth: dotted ? DOT_STROKE_WIDTH : 0 }}
                  isAnimationActive={!dotted}
                  style={hasStackedOffset ? { transform: `translate(0,-${index * 1.24}px)` } : undefined}
                />
              );
            })}
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="#3B8BFF"
                fill="#3B8BFF"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {hasLegend && (
        <div className="w-full flex flex-col justify-center items-end">
          <div className="w-[100%] px-[5%] max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md mt-8">
            <CustomLegend<string>
              selectedChartComponents={selectedChartComponents}
              setSelectedChartComponents={setSelectedChartComponents}
              componentStyle={legendComponentStyle}
              ChartComponents={chartComponents
                .map((component) => ({
                  [component]: component,
                }))
                .reduce((a, b) => ({ ...a, ...b }), {})}
              renderNameFunction={(key) => renderNameFunction(key) || ""}
              className="-mt-1"
              fontWeight={500}
              fontSpanClassName="truncate"
              disableTooltip={!isMulticluster}
              hasTooltip
            />
          </div>
        </div>
      )}
      {!!customLegendBySeparator && (
        <div className="w-full flex flex-col justify-center items-end">
          <div className="w-[100%] px-[5%] max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md mt-8">
            <CustomLegend<string>
              selectedChartComponents={[
                ...new Set(selectedChartComponents.map((e) => e.split(customLegendBySeparator)[0])),
              ]}
              setSelectedChartComponents={(newState: SetStateAction<string[]>) => {
                const newStateTmp = newState as string[];
                newState = chartComponents.filter((component) => {
                  const name = component.split(customLegendBySeparator)[0];
                  return newStateTmp.includes(name);
                });
                setSelectedChartComponents(newState);
              }}
              componentStyle={Object.entries(legendComponentStyle).reduce(
                (a, b) => ({ ...a, [b[0].split("/")[0]]: b[1] }),
                {}
              )}
              ChartComponents={chartComponents.reduce((a, b) => {
                const name = b.split(customLegendBySeparator)[0];
                return { ...a, [name]: name };
              }, {})}
              className="-mt-1"
              fontWeight={500}
              fontSpanClassName="truncate"
              hasTooltip
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default TopValuesGraph;
