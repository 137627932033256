import { SCALEOPS_COLORS } from "../../../colors";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";
import { OPTIMIZED_REQUEST } from "../../../utils/namesUtils";
import ChartTooltipElement from "../../ChartTooltipElement";
import ChartTooltipTime from "../../ChartTooltipTime";
import Styles, { ALLOCATABLE_COLOR, AverageUsageColor } from "./Styles";
import { ChartComponents } from "./utils";

interface ToolTipOptionProps {
  label: ChartComponents;
  value: number | string;
  color: string;
  includedChartComponents: ChartComponents[];
  selectedChartComponents: ChartComponents[];
  valueSuffix?: React.ReactNode;
  customPrefix?: string;
  isDashed?: boolean;
}

const TooltipOption = ({
  label,
  value,
  color,
  includedChartComponents,
  selectedChartComponents,
  valueSuffix,
  customPrefix,
  isDashed,
}: ToolTipOptionProps) => {
  if (!includedChartComponents.includes(label) || !selectedChartComponents.includes(label)) return null;

  const displayLabel =
    label === ChartComponents.Recommended ? OPTIMIZED_REQUEST.toLocaleLowerCase() : camelCaseToSnakeSpace(label);

  return (
    <ChartTooltipElement
      color={color}
      value={
        <>
          {value}
          {valueSuffix}
        </>
      }
      label={displayLabel}
      customPrefix={customPrefix}
      isDashed={isDashed}
    />
    // <p
    //   style={{
    //     color: color,
    //   }}
    //   className="flex gap-2 items-center"
    // >
    //   <div
    //     className="w-4 h-2"
    //     style={{
    //       backgroundColor: color,
    //     }}
    //   />
    //   {camelCaseToSnakeSpace(label)}: {value}
    //   {valueSuffix}
    // </p>
  );
};

export type CustomTooltipPayload = {
  timestamps: string;
  usage: number;
  recommended: number;
  recommendedAllocatable: number;
  request: number;
  allocatable: number;
  gpuTotalMemory: number;
  gpuUsedMemory: number;
  gpuSmActive: number;
  gpuUtilization: number;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload: CustomTooltipPayload;
  }[];
  getWasteValue?: (payload: CustomTooltipPayload) => number;
  includedChartComponents: ChartComponents[];
  selectedChartComponents: ChartComponents[];
  timeFormat?: string | undefined;
  valueFormatter: (value: number) => string | number;
};

const CustomTooltip = ({
  active,
  payload,
  getWasteValue,
  includedChartComponents,
  selectedChartComponents,
  valueFormatter,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const {
      timestamps,
      usage,
      recommended,
      request,
      allocatable,
      recommendedAllocatable,
      gpuTotalMemory,
      gpuUsedMemory,
      gpuSmActive,
      gpuUtilization,
    } = payload[0].payload;
    if (!timestamps) return null;

    let diff = getWasteValue ? getWasteValue(payload[0].payload) : 0;
    diff = Math.round(diff * 100) / 100;
    if (diff < 0) diff = 0;

    return (
      <div className="bg-[rgba(255,255,255,0.75)] text-black py-4 px-8 rounded-lg border border-border flex flex-col text-[10px]">
        <ChartTooltipTime timestamp={timestamps} />
        <TooltipOption
          label={ChartComponents.Allocatable}
          value={valueFormatter(allocatable)}
          color={ALLOCATABLE_COLOR}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.Request}
          value={valueFormatter(request)}
          color={Styles.currentRequest.stroke}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.Recommended}
          value={valueFormatter(recommended)}
          color={Styles.recommendedRequest.stroke}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.RecommendedAllocatable}
          value={valueFormatter(recommendedAllocatable)}
          color={Styles.recommendedAllocatable.stroke}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.Waste}
          value={valueFormatter(diff)}
          color={SCALEOPS_COLORS.main.lightRed}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
          isDashed
        />
        <TooltipOption
          label={ChartComponents.Usage}
          value={valueFormatter(usage)}
          color={AverageUsageColor}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuUsedMemory}
          customPrefix={"used memory"}
          value={valueFormatter(gpuUsedMemory)}
          color={SCALEOPS_COLORS.gpu.usedMemory}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuTotalMemory}
          customPrefix={"total memory"}
          value={valueFormatter(gpuTotalMemory)}
          color={SCALEOPS_COLORS.gpu.totalMemory}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuSmActive}
          customPrefix={"average utilization"}
          value={valueFormatter(gpuSmActive)}
          color={SCALEOPS_COLORS.gpu.smActive}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuUtilization}
          customPrefix={"% of active GPUs"}
          value={valueFormatter(gpuUtilization)}
          color={SCALEOPS_COLORS.gpu.utilization}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
