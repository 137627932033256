import { GetConfig } from "../utils/ConfigHelper";
import withDisabled, {TooltipContent} from "./withDisabled";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {GetRbac, GetRbacResponse, GetSimpleAuth, GetSimpleAuthResponse} from "../api/fetcher";
import {components} from "../api/schema";
import {toast} from "react-toastify";
import {Typography} from "@mui/material";
import clsx from "clsx";
import SSOWithAuthUserTable from "../pages/Roles/Permission/SSOWithAuthUserTable";
import useIsReadyOnlyFrontEnd from "../utils/useIsReadyOnlyFrontEnd";
import SSOWithAuthorization from "../pages/Roles/Permission/SSOWithAuthorization";
import {AuthMethod } from "../pages/Roles/Permission/utils";
import SimpleAuthUsersTable from "../pages/Roles/Permission/SimpleAuthUserTable";

const { queryKey, queryFn } = GetRbac();
const simpleAuth = GetSimpleAuth();
export type Rule = components["schemas"]["RbacRule"];
const WRAPPER_CLASS_NAME = "pb-10";

const SSOWithAuthUserTableWithDisabled = withDisabled(SSOWithAuthUserTable);
const SimpleAuthUserTableWithDisabled = withDisabled(SimpleAuthUsersTable);

interface UsersProps {
  showGroups?: boolean;
}

export default function Users({ showGroups } : UsersProps) {
  const config = GetConfig();
  const isInCloudAdmin = config.userGroups?.includes("Admin");
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isCurrentAuthMethod = !!config?.rbacEnabled;
  const conf = GetConfig();
  const [ssoConf, setSsoConf] = useState<components["schemas"]["TypesAuthInfo"]>({ enabled: false, provider: "none" });

  const [rows, setRows] = useState<Rule[]>([]);

  const [tags, setTags] = useState<string[]>([]);
  const [clusters, setClusters] = useState<string[]>([]);

  const { data, isError, error } = useQuery<GetRbacResponse>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
    refetchInterval: 5000,
  });

  if (isError) {
    toast.error("Error fetching roles");
    console.log("Error fetching roles", error);

    return (
      <Typography className={(clsx(WRAPPER_CLASS_NAME), "text-red-500")} variant="body2">
        Error fetching roles
      </Typography>
    );
  }

  useEffect(() => {
    if (data?.rules) {
      if (ssoConf.provider == "token") {
        setRows(data.rules.filter((r) => r.role === "Admin"));
      } else {
        setRows(data.rules.filter((r) => r.user !== showGroups));
      }
      const uniqueTags =
        [...new Set(data.clustersInfo?.map((c) => c.tags).flat())].filter((e) => !!e).map((t) => String(t)) ?? [];
      setTags(uniqueTags.sort());
      const uniqueClusters =
        [...new Set(data.clustersInfo?.map((c) => c.name).flat())].filter((e) => !!e).map((t) => String(t)) ?? [];
      setClusters(uniqueClusters.sort());
    }
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(ssoConf) !== JSON.stringify(conf.ssoConf))
      setSsoConf(
        conf.ssoConf || {
          enabled: false,
          provider: "none",
        }
      );
  }, [conf]);

  const { data: simpleAuthData, isLoading } = useQuery<GetSimpleAuthResponse, Error>({
    queryKey: [simpleAuth.queryKey],
    queryFn: simpleAuth.queryFn,
  });

  if (config.ssoConf?.provider === "simpleAuth") {
    return <SimpleAuthUserTableWithDisabled
      simpleAuthData={simpleAuthData}
      isLoading={isLoading}
      disabledTooltipContent={TooltipContent.None}
    />
  }

  if (config.inCloudMode && isInCloudAdmin) {
    return <div className="flex flex-col gap-10">
      <SSOWithAuthorization currentAuthMethod={AuthMethod.SSO_WITH_AUTHORIZATION}
                            updateProvider={() => {
                              void (0)
                            }}/>
    </div>
  }

  return (
    <SSOWithAuthUserTableWithDisabled
      rows={rows}
      clusters={clusters}
      tags={tags}
      ssoConf={ssoConf}
      disabled={!isCurrentAuthMethod || isReadyOnlyFrontEnd || ssoConf.provider == "token"}
      disabledTooltipContent={TooltipContent.None}
      isLoading={isLoading}
      groups={showGroups}
    />
  );
}
