// import { GetCategoryTopkQueryKey } from "../../../api/fetcher";
import { GetCategoryTopkQueryKey } from "../../../api/fetcher";
import CategoryTopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/CategoryTopKMultiLineChart";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import { GENERAL_SYNC_ID } from "../utils";
import { capitalizeFirstLetterAndCamelCaseToSnakeSpace, CommonGraphProps, GraphDisplayName } from "./utils";
import useGetCategoryGraphState from "../useGetCategoryGraphState";

const DowntimeEventsGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, aggregationView, numberOfElements, frozenAndClickableTab } = useGetCategoryGraphState();

  return (
    <CategoryTopKMultiLineChart
      title={GraphDisplayName.DowntimeEventsGraph}
      aggregationView={aggregationView as AggregateBy}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetCategoryTopkQueryKey.Downtime,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      dotted={true}
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      renderNameFunction={(name) => capitalizeFirstLetterAndCamelCaseToSnakeSpace(name)}
      hasLegend
    />
  );
};

export default DowntimeEventsGraph;
