import clsx from "clsx";
import { memo } from "react";
import PartialBorders from "../../components/PartialBorders";
import Tab from "../../components/Tab";
import RunningNumberBox from "../../pages/Overview/TopOverviewSection/RunningNumberBox";
import WasteVsOptimizedBarCharts from "../../pages/Overview/TopOverviewSection/WasteVsOptimizedBarCharts";
import { get24HoursAgoEpoch } from "../../utils/dateAndTimeUtils";
import { GRAY_SIDE_TEXT, MAIN_SECTION_CLASSNAME } from "../../utils/styleUtils";
import { TabSelectedTime } from "../../utils/tabUtils";
import PercentagePieChart from "../PercentagePieChart";
import useWindowSize from "../useWindowSize";
import SnapshotWrapper from "../../components/SnapshotWrapper";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";
import { BooleanParam, useQueryParam } from "use-query-params";

const MAIN_GAP = "gap-4";
const MAIN_WIDTH = "w-1/3";
const CENTER_FLEX_ITEMS = "flex flex-col justify-center items-center";

interface Props {
  monthlyCost: number;
  activeSavings: number;
  totalOptimizedPods: number;
  totalUnOptimizedPods: number;
  wastedSpend: number;
  blockedNodes: number;
  unevictableBlockedNodes: number;
  disableAnimation?: boolean;
  selectedTime: TabSelectedTime;
  setSelectedTime: (selectedTime: TabSelectedTime) => void;
  setStartTime: React.Dispatch<React.SetStateAction<number | undefined>>;
  isLoading?: boolean;
}

const TopSection: React.FC<Props> = memo(
  ({
    monthlyCost,
    activeSavings,
    totalOptimizedPods,
    totalUnOptimizedPods,
    wastedSpend,
    blockedNodes,
    unevictableBlockedNodes,
    disableAnimation,
    selectedTime,
    setSelectedTime,
    setStartTime,
    isLoading = false,
  }: Props) => {
    const size = useWindowSize();
    const isSnapshotServer = IsSnapshotServer();
    const [enableActiveSavings] = useQueryParam("enableActiveSavings", BooleanParam);

    return (
      <div className="w-full flex gap-4 items-end">
        <div className="flex flex-col w-[75%]">
          <div className={"flex gap-[3px]"}>
            <Tab
              name="Live"
              dataTestId="top-unevictable-live-tab"
              isSelected={selectedTime === TabSelectedTime.LIVE}
              onClick={() => {
                setSelectedTime(TabSelectedTime.LIVE);
                setStartTime(undefined);
              }}
            />
            <Tab
              name="1 day"
              dataTestId="top-unevictable-30-days-tab"
              isSelected={selectedTime === TabSelectedTime.LAST_1_DAY}
              onClick={() => {
                setSelectedTime(TabSelectedTime.LAST_1_DAY);
                setStartTime(get24HoursAgoEpoch(Date.now()));
              }}
            />
          </div>
          <div className={clsx("mt-[0px] shadow-none", MAIN_SECTION_CLASSNAME)}>
            {isSnapshotServer && enableActiveSavings ? (
              <div className={clsx(MAIN_GAP, MAIN_WIDTH, "mt-[30px]")}>
                <PartialBorders>
                  <div className="flex flex-col gap-4 justify-center items-center">
                    <RunningNumberBox
                      value={monthlyCost}
                      className={clsx(MAIN_GAP, "mt-[-30px]")}
                      title={
                        <>
                          Cost <span className={GRAY_SIDE_TEXT}>(monthly)</span>
                        </>
                      }
                      valueDataTestId="top-overview-monthly-cost-value"
                      numberVariant="h4"
                      prefix="$"
                      numberClassName="text-text-lightBlack"
                      disableAnimation={disableAnimation}
                      isLoading={isLoading}
                    />
                    <div className="bg-strongBorder h-[1px] w-[80%]" />
                    <SnapshotWrapper>
                      <RunningNumberBox
                        value={activeSavings}
                        valueDataTestId="top-overview-current-savings-value"
                        title={
                          <>
                            Active savings <span className={GRAY_SIDE_TEXT}>(monthly)</span>
                          </>
                        }
                        numberVariant="h4"
                        prefix="$"
                        numberClassName="text-main-green"
                        disableAnimation={disableAnimation}
                        isLoading={isLoading}
                      />
                    </SnapshotWrapper>
                  </div>
                </PartialBorders>
              </div>
            ) : (
              <div className={clsx(MAIN_GAP, MAIN_WIDTH, "mt-[12px]")}>
                <PartialBorders>
                  <div className={CENTER_FLEX_ITEMS}>
                    <RunningNumberBox
                      value={monthlyCost}
                      className={clsx(MAIN_GAP, "mt-[-30px]")}
                      title={
                        <>
                          Cost <span className={GRAY_SIDE_TEXT}>(monthly)</span>
                        </>
                      }
                      valueDataTestId="top-unevictable-monthly-cost-value"
                      numberVariant="h2"
                      prefix="$"
                      numberClassName="text-text-lightBlack"
                      disableAnimation={disableAnimation}
                      isLoading={isLoading}
                    />
                  </div>
                </PartialBorders>
              </div>
            )}
            <div className={MAIN_WIDTH}>
              <PartialBorders left>
                <div className={CENTER_FLEX_ITEMS}>
                  <RunningNumberBox
                    title="Wasted spend"
                    valueDataTestId="top-unevictable-wasted-spend-value"
                    value={wastedSpend}
                    suffix="%"
                    numberVariant="h2"
                    numberClassName={clsx({
                      "text-main-red": wastedSpend > 0,
                      "text-text-lightBlack": wastedSpend <= 0,
                    })}
                    className={clsx(MAIN_GAP)}
                    disableAnimation={disableAnimation}
                    isLoading={isLoading}
                  />
                  <WasteVsOptimizedBarCharts
                    waste={wastedSpend}
                    optimized={100 - wastedSpend}
                    disableTooltip={wastedSpend <= 0}
                    disableAnimation={disableAnimation}
                  />
                </div>
              </PartialBorders>
            </div>
            <div className={MAIN_WIDTH}>
              <PartialBorders left>
                <div className="flex flex-col gap-4 justify-center items-center w-full">
                  <RunningNumberBox
                    value={blockedNodes}
                    title="Total blocked nodes"
                    numberVariant="h4"
                    valueDataTestId="top-unevictable-blocked-nodes"
                    disableAnimation={disableAnimation}
                    isLoading={isLoading}
                  />

                  <div className="bg-strongBorder w-[80%] h-[1px]" />
                  <RunningNumberBox
                    value={unevictableBlockedNodes}
                    title="Blocked nodes by unevictable"
                    numberVariant="h4"
                    valueDataTestId="top-unevictable-insight"
                    disableAnimation={disableAnimation}
                    isLoading={isLoading}
                  />
                </div>
              </PartialBorders>
            </div>
          </div>
        </div>
        <div className="shadow-none flex items-center justify-center rounded-lg w-[25%] h-[220px] py-[20px] bg-white relative">
          <PercentagePieChart
            xLabel="optimized"
            yLabel="un-optimized"
            title="Optimized"
            subtitle="Unevictable pods"
            xValue={totalOptimizedPods}
            yValue={totalUnOptimizedPods}
            showLabels={!!(size.width && size.width > 1560)}
            disableAnimation={disableAnimation}
          />
        </div>
      </div>
    );
  }
);

export default TopSection;
