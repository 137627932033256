import {useEffect, useState} from "react";
import { Typography } from "@mui/material";
import clsx from "clsx";
import MultiSelect from "../../components/MultiSelect";
import { usePatchRole } from "./mutations/usePatchRole";
import {GridRowModel} from "@mui/x-data-grid/models/gridRows";
import {Rule} from "../../components/users";

const HAS_TAGS_FILTER = false;

const Label = ({ label, hasOptionalSpan }: { label: string; hasOptionalSpan?: boolean }) => (
  <Typography variant="caption">
    {label}
    {hasOptionalSpan && (
      <>
        {" "}
        <span className="text-text-disable text-[10px]">(optional)</span>
      </>
    )}
  </Typography>
);

interface Props {
  isDisabled: boolean;
  clusters: string[],
  tags: string[],
  row: GridRowModel<Rule>,
}

const ClustersRules = ({ isDisabled, clusters, tags, row}: Props) => {
  const update = usePatchRole();
  const [selectedTags, setSelectedTags] = useState<string[]>(row.targetTags ?? []);
  const [selectedClusters, setSelectedClusters] = useState<string[]>(row.targetClusters ?? []);

  useEffect(() => {
    if (JSON.stringify(row.targetTags) !== JSON.stringify(selectedTags)) {
      setSelectedTags(row.targetTags ?? []);
    }
  }, [row.targetTags]);

  useEffect(() => {
    if (JSON.stringify(row.targetClusters) !== JSON.stringify(selectedClusters)) {
      setSelectedClusters(row.targetClusters ?? []);
    }
  }, [row.targetClusters]);

  return (
    <div
      className={clsx("min-w-full h-full flex flex-col gap-2 items-center justify-center relative px-[5%]", {
        "mt-[-20px]": HAS_TAGS_FILTER,
      })}
    >
      <MultiSelect
        label={HAS_TAGS_FILTER ? <Label label="Clusters" hasOptionalSpan /> : undefined}
        wrapperClassName="w-full"
        className="h-[34px] w-full"
        selected={selectedClusters}
        setSelected={(option) => {
          const optionsToSet: string[] = option.filter((o) => o).map((o) => String(o));
          setSelectedClusters(optionsToSet);

          row.id &&
          update.mutate({
            id: row.id,
            targetClusters: optionsToSet,
          });
        }}
        options={clusters}
        fontSize="12px"
        renderValue={(selected: (string | undefined)[]) => {
          if (selected.length === 0) {
            return "All";
          }
          return `${selected?.join(", ")}`;
        }}
        disabled={isDisabled}
      />
      {HAS_TAGS_FILTER && (
        <MultiSelect
          label={<Label label="Tags" hasOptionalSpan />}
          wrapperClassName="w-full"
          className="h-[34px] w-full"
          selected={selectedTags}
          setSelected={(option) => {
            const optionsToSet: string[] = option.filter((o) => o).map((o) => String(o));
            setSelectedTags(optionsToSet);

            row.id &&
            update.mutate({
              id: row.id,
              targetTags: optionsToSet,
            });
          }}
          options={tags}
          fontSize="12px"
          disabled={isDisabled}
        />
      )}
    </div>
  );
};

export default ClustersRules;
