import { METRIC_BOX_CLASS_NAME } from "./utils";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { GetPolicyTuningHpaCurrentDataResponse } from "../../../api/fetcher";
import { Typography } from "@mui/material";
import Tooltip from "../../../components/Tooltip";

const BOX_CLASS_WRAPPER = `${METRIC_BOX_CLASS_NAME}  gap-1`;

interface Props {
  data: GetPolicyTuningHpaCurrentDataResponse | undefined;
}

const HpaMisconfigurationBadge = ({ data }: Props) => {
  const warnings = data?.misconfigurationWarnings;
  if (!warnings?.length) return null;
  return (
    <Tooltip
      title={
        <Typography variant="caption">
          <p>
            {warnings.map((warning, index) => (
              <div className="italic" key={index}>
                {warning}
              </div>
            ))}
          </p>
        </Typography>
      }
      placement="left"
      maxWidth={700}
    >
      <div className={BOX_CLASS_WRAPPER}>
        Misconfigured HPA{" "}
        <div className="w-[12px]">
          <CreativeInfoIcon width={12} height={12} />
        </div>
      </div>
    </Tooltip>
  );
};

export default HpaMisconfigurationBadge;
