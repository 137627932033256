import { Typography } from "@mui/material";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { GetPolicyTuningHpaCurrentDataResponse } from "../../../api/fetcher";
import Tooltip from "../../../components/Tooltip";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";
import { METRIC_BOX_CLASS_NAME } from "./utils";

const ICON_SIZE = 12;
const MAX_TOOLTIP_WIDTH = 500;
const BOX_CLASS_WRAPPER = `${METRIC_BOX_CLASS_NAME}  gap-1`;

interface Props {
  data: GetPolicyTuningHpaCurrentDataResponse | undefined;
}

const OptimizationGapBadge = ({ data }: Props) => {
  if (!data?.optimizationGap) return null;
  const reason = data?.optimizationGap ?? "tempMessage";

  return (
    <Tooltip
      title={
        <Typography variant="caption">
          Optimization gap
          <p className="italic">HPA optimization could not be achieved</p>
          <p>
            Workload has optimization gap due to: <span className="italic">{camelCaseToSnakeSpace(reason)}</span>.
          </p>
        </Typography>
      }
      placement="left"
      maxWidth={MAX_TOOLTIP_WIDTH}
    >
      <div className={BOX_CLASS_WRAPPER}>
        Optimization gap
        <div className="w-[12px]">
          <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </div>
      </div>
    </Tooltip>
  );
};

export default OptimizationGapBadge;
