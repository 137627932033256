import Button from "../../../../components/Button";
import Tooltip from "../../../../components/Tooltip";
import LockIcon from "../../../../Icons/LockIcon";
import { IsSnapshotAdmin } from "../../../../utils/FeaturesHelper";

interface Props {
  isFormDirty: boolean;
  resetForm: () => void;
}

const ActionButtons = ({ isFormDirty, resetForm }: Props) => {
  const isSnapshotAdmin = IsSnapshotAdmin();
  return (
    <div className="flex gap-4 mt-auto flex-row-reverse">
      <Button
        type="submit"
        label={
          <Tooltip
            title={<b>This operation is restricted to admins only</b>}
            maxWidth={500}
            disabled={isSnapshotAdmin}
            className="flex items-center gap-1"
          >
            Save
            {!isSnapshotAdmin && <LockIcon width={18} height={18} />}
          </Tooltip>
        }
        disabled={!isSnapshotAdmin || !isFormDirty}
      />
      <Button label="Cancel" type="button" onClick={() => resetForm()} disabled={!isFormDirty} />
    </div>
  );
};

export default ActionButtons;
