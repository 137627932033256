import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CheckedIcon from "../Icons/CheckedIcon";
import LinkIcon from "../Icons/LinkIcon";
import Tooltip from "./Tooltip";

const SUCCESS_MESSAGE_TIMEOUT = 3000;
const ICON_SIZE = 12;

interface Props {
  title: React.ReactNode;
  url: string;
  hasIcon?: boolean;
}

const CopyLink = ({ title, url, hasIcon = true }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, SUCCESS_MESSAGE_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return (
    <Tooltip
      title={
        <Typography variant="caption" className="flex items-end gap-2">
          <CheckedIcon className="text-primary-purpleBlue" /> <p>Linked copied to clipboard</p>
        </Typography>
      }
      placement="top-start"
      className="flex gap-2 items-center group cursor-pointer relative"
      onClick={() => {
        window.navigator.clipboard.writeText(url);
        setIsCopied(true);
      }}
      maxWidth={750}
    >
      {hasIcon && (
        <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center bg-primary-purpleBlue rounded-full">
          <LinkIcon width={ICON_SIZE} fill="white" />
        </div>
      )}
      <Typography variant="caption" fontWeight={700} className="group-hover:underline text-primary-purpleBlue">
        {title}
      </Typography>
    </Tooltip>
  );
};

export default CopyLink;
