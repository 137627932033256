import { Typography } from "@mui/material";
import clsx from "clsx";
import CustomCheckBox, { THEME as CHECKBOX_THEME } from "../../../components/CustomCheckBox";
import Tooltip from "../../../components/Tooltip";

interface Props {
  Title: string;
  description: React.ReactNode;
  isSelected: boolean;
  isDefault: boolean;
  onClick: () => void;
  providerName: string;
}

const AuthOptionBox = ({ Title, description, isSelected, isDefault, onClick, providerName }: Props) => {
  return (
    <div
      className={clsx("px-[40px] py-[25px] w-[340px] border rounded-lg relative flex gap-5 items-center", {
        "border-strongerBorder hover:bg-guideline-lightGray cursor-pointer": !isSelected && !isDefault,
        "border-primary-purpleBlue bg-guideline-lighterPurple cursor-default": isSelected && !isDefault,
        "border-primary-purpleBlue bg-guideline-lighterGreen cursor-default": isDefault,
        "border-primary-purpleBlue bg-guideline-lighterGreen cursor-pointer": !isSelected && isDefault,
      })}
      onClick={onClick}
    >
      <Tooltip
        title={`${providerName} is your current authentication method`}
        disabled={!isDefault}
      >
        <div className="absolute top-[15px] right-[15px]">
          <CustomCheckBox
            value={isSelected}
            onClick={() => {
              console.log(`selected ${Title}`);
            }}
            theme={CHECKBOX_THEME.PURPLE}
          />
        </div>
        <div>
          <Typography
            variant="body1"
            fontWeight={700}
            className={clsx({
              "text-text-lightBlack": !isSelected,
              "text-primary-purpleBlue": isSelected,
            })}
          >
            {Title}
          </Typography>
          <div
            className={clsx({
              "text-text-lightBlack": !isSelected,
              "text-primary-black": isSelected,
            })}
            style={{
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            {description}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default AuthOptionBox;
