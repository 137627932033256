import { ErrorBoundary } from "react-error-boundary";
import { components } from "../../../api/schema";
import CustomLegend from "../../../components/CustomLegend";
import DefaultFallback from "../../../components/DefaultFallback";
import UsageAndMemoryCharts from "./UsageAndMemoryCharts";
import { ChartComponents, componentStyle, displayNameFormatter, Policy } from "./utils";

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  selectedPolicy: Policy | undefined;
  selectedChartComponents: ChartComponents[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<ChartComponents[]>>;
  cpuCappingConfig: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined;
  setCpuCappingConfig: React.Dispatch<
    React.SetStateAction<components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined>
  >;
  memoryCappingConfig: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined;
  setMemoryCappingConfig: React.Dispatch<
    React.SetStateAction<components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined>
  >;
  isAutomate: boolean;
  setIsAutomate: React.Dispatch<React.SetStateAction<boolean>>;
  isGpu: boolean;
}

const UsageAndMemoryContainer = ({
  selectedWorkload,
  selectedPolicy,
  selectedChartComponents,
  setSelectedChartComponents,
  setCpuCappingConfig,
  setMemoryCappingConfig,
  isGpu,
}: Props) => {
  return (
    <ErrorBoundary
      fallback={<DefaultFallback message="Failed to load Usage and Memory Chart. Please check your setup." />}
    >
      <div className="border rounded-lg border-border">
        <UsageAndMemoryCharts
          selectedWorkload={selectedWorkload}
          policyName={selectedPolicy?.name}
          selectedChartComponents={selectedChartComponents}
          setCpuCappingConfig={setCpuCappingConfig}
          setMemoryCappingConfig={setMemoryCappingConfig}
          isGpu={isGpu}
        />
        <CustomLegend<ChartComponents>
          selectedChartComponents={selectedChartComponents}
          setSelectedChartComponents={setSelectedChartComponents}
          componentStyle={componentStyle}
          isDashedFnc={(key: string) => key.includes("Limit")}
          ChartComponents={ChartComponents}
          className="mt-1 pb-3"
          displayNameFormatter={displayNameFormatter}
          onlyShowOptions={isGpu ? [ChartComponents.CurrentRequest, ChartComponents.AverageUsage] : undefined}
          displayNameStringTransformer={(key: string) => {
            if (isGpu && key === ChartComponents.CurrentRequest) {
              return "Request";
            }

            return key;
          }}
        />
      </div>
    </ErrorBoundary>
  );
};

export default UsageAndMemoryContainer;
