import { components } from "../../api/schema";
import { SCALEOPS_CRON_JOB_COG_NAME, SCALEOPS_KEDA_SCALED_JOB_COG_NAME } from "../../utils/namesUtils";

export const OVERVIEW_TAB_QUERY_KEY = "overviewTab";
export const WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME = "w-full h-full flex justify-center items-center gap-2";
export const SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM = "selectedWorkloadOverviewId";
export const CURRENT_CLUSTER_URL_QUERY_PARAM = "currentClusterURLParam";

export enum OverviewTableTabOptions {
  Workloads = "Workloads",
  Aggregation = "Aggregation",
}

export interface DashOverUnderProvisioningFilters {
  overProvisioned?: boolean;
  underProvisioned?: boolean;
  priorityClassName?: string;
  namespace?: string;
  labels?: string;
}

export type DashWorkloadWithIssues = components["schemas"]["UtilsWorkload"] & {
  issues?: components["schemas"]["DashIssues"];
} & DashOverUnderProvisioningFilters;

export const SCALEOPS_ROLLOUT = "scaleops-rollout";

export enum WorkloadType {
  AlgoRollout = "argo-rollout",
  GenericType = "Custom-Workload",
  Family = "Family",
  CronJon = "CronJob",
  KedaScaledJob = "KedaScaledJob",
}

export const getWorkloadType = (wl: components["schemas"]["UtilsWorkload"]) => {
  const isScaleOpsRollout = wl.workloadName.startsWith(SCALEOPS_ROLLOUT);

  let workloadType = wl?.type;

  switch (true) {
    case workloadType == SCALEOPS_KEDA_SCALED_JOB_COG_NAME:
      workloadType = WorkloadType.KedaScaledJob;
      break;
    case workloadType === SCALEOPS_CRON_JOB_COG_NAME:
      workloadType = WorkloadType.CronJon;
      break;
    case workloadType === WorkloadType.Family && isScaleOpsRollout:
      workloadType = WorkloadType.AlgoRollout;
      break;
    case workloadType === WorkloadType.Family && !isScaleOpsRollout:
      workloadType = WorkloadType.GenericType;
      break;
    default:
      workloadType = wl?.type;
  }

  return workloadType;
};

export const groupByNamespace = (workloads: DashWorkloadWithIssues[]) => {
  const groupedByNamespace = workloads.reduce((acc, workload) => {
    const namespace = workload.namespace || "default";
    acc[namespace] = acc[namespace] || [];
    acc[namespace].push(workload);
    return acc;
  }, {} as Record<string, DashWorkloadWithIssues[]>);

  return groupedByNamespace;
};

export enum OverviewTableFields {
  Issues = "issues",
  Workload = "workloadNameSortValue",
  TotalCost = "totalCost",
  SavingsAvailable = "savingsAvailable",
  ActiveSavings = "activeSavings",
  CPUDiff = "cpuDiff",
  OriginalCPURequest = "ownerCpuRequests",
  MemoryDiff = "memoryDiff",
  OriginalMemoryRequest = "ownerMemoryRequests",
  Replicas = "runningReplicas",
  ReadyReplicas = "readyReplicas",
  PolicyName = "policyName",
  Automated = "autoSortValue",
  OverProvisioned = "overProvisioned",
  UnderProvisioned = "underProvisioned",
  ReplicasDiff = "replicasRecommendation",
  MinReplicasDiff = "minReplicasRecommendation",
  PredictableSavings = "predictableSavings",
  IsIdleStable = "predictionIdleStable",
  OriginalGPURequest = "ownerGpuRequests",
  Unevictable = "unevictable",
  OriginalMinReplicas = "originMinReplicas",
}

export enum WorkloadExportColumns {
  WorkloadName = "workloadName",
  Namespace = "namespace",
  Automated = "auto",
  TotalCost = "totalCost",
  ActiveSavings = "activeSavings",
  SavingsAvailable = "savingsAvailable",
  CPURequests = "cpuRequests",
  OwnerCPURequests = "ownerCpuRequests",
  CPURecommended = "cpuRecommended",
  CPUDiff = "cpuDiff",
  MemoryRequests = "memRequests",
  OwnerMemoryRequests = "ownerMemoryRequests",
  MemoryRecommended = "memRecommended",
  MemoryDiff = "memoryDiff",
  PolicyName = "policyName",
  Replicas = "replicas",
  OptimizedReplicas = "optimizedReplicas",
}

export const WorkloadExportColumnsNames = {
  [WorkloadExportColumns.WorkloadName]: "workload name",
  [WorkloadExportColumns.Namespace]: "namespace",
  [WorkloadExportColumns.Automated]: "automated",
  [WorkloadExportColumns.TotalCost]: "total cost",
  [WorkloadExportColumns.ActiveSavings]: "active savings",
  [WorkloadExportColumns.SavingsAvailable]: "savings available",
  [WorkloadExportColumns.CPURequests]: "cpu requests",
  [WorkloadExportColumns.OwnerCPURequests]: "owner cpu requests",
  [WorkloadExportColumns.CPURecommended]: "cpu recommended",
  [WorkloadExportColumns.CPUDiff]: "cpu diff",
  [WorkloadExportColumns.MemoryRequests]: "memory requests",
  [WorkloadExportColumns.OwnerMemoryRequests]: "owner memory requests",
  [WorkloadExportColumns.MemoryRecommended]: "memory recommended",
  [WorkloadExportColumns.MemoryDiff]: "memory diff",
  [WorkloadExportColumns.PolicyName]: "policy name",
  [WorkloadExportColumns.Replicas]: "replicas",
  [WorkloadExportColumns.OptimizedReplicas]: "optimized replicas",
};

export enum WorkloadWarningsTypes {
  // WorkloadErrorDoubleSelected = "Pods with multiple owners",
  WorkloadErrorResourceQuota = "Resource quota exceeded for workload",
  // WorkloadErrorOther = "Other",
  WorkloadErrorFailedCreate = "Pods failed to be created",
  WorkloadErrorStuckRollout = "Rollout in progress",
  WorkloadErrorOutOfMemory = "Workload has out of memory events",
}

export enum WastedResourcesTypes {
  WorkloadErrorStuckTerminating = "Pod stuck terminating",
  WorkloadErrorImagePullBackOff = "Pod stuck pulling image",
  WorkloadErrorPodInitializing = "Pod stuck initializing",
  // WorkloadErrorOther = "Other",
}

export enum HpaOptimizationTypes {
  predictable = "Predictable workloads",
  maxValueMinReplicas = "Static workloads",
}

export enum RolloutStrategyTypes {
  Ongoing = "Ongoing",
  OnCreate = "Upon pod creation",
}

export enum UnevictableTypes {
  unevictable = "Unevictable",
  notHealthy = "Not healthy",
  LocalStorageRequested = "Local storage requested",
  unevictableOrNotHealthy = "Unevictable or not Healthy",
}
