import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { SCALEOPS_COLORS } from "../../colors";
import useGetClusterNames from "../../MainMenu/useGetClusterNames";
import Input from "../Input";
import MultiSelectByQueryParams from "../MultiSelectByQueryParams";
import useGetAnnotations from "../WorkloadStatusByNamespaceSection/OverviewHooks/useGetAnnotations";
import useGetLabels from "../WorkloadStatusByNamespaceSection/OverviewHooks/useGetLabels";
import useGetNamespaces from "../WorkloadStatusByNamespaceSection/OverviewHooks/useGetNamespaces";
import useGetTagsFromClusters from "./useGetTagsFromClusters";
import useOptimizedFilter, { CostOptimizedTypes, PerformanceOptimizedTypes } from "./useOptimizedFilter";
import CustomFilterChip from "../CustomFilterChip";
import React from "react";

const HAS_TAGS_FILTER = false;

interface Props {
  isMultiCluster?: boolean;
}

const SummaryReportFilters = ({ isMultiCluster }: Props) => {
  useOptimizedFilter();
  const tags = useGetTagsFromClusters();
  const clusterNames = useGetClusterNames();
  const annotations = useGetAnnotations(isMultiCluster);
  const labels = useGetLabels(isMultiCluster);
  const namespaces = useGetNamespaces(isMultiCluster);

  const [searchTerm, setSearchTerm] = useQueryParam("setSummarySearchTerm", StringParam);
  const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);
  const [, setFilteredClustersParam] = useQueryParam("clusters", ArrayParam);
  const [isHpaAuto, setIsHpaAuto] = useQueryParam("isHpaAuto", BooleanParam);
  return (
    <div className="flex gap-2 items-center h-8">
      <Input
        placeholder="search..."
        borderColor={SCALEOPS_COLORS.black}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setFilteredClustersParam(
            clusterNames.filter((clusterName) => e.target.value === "" || clusterName.includes(e.target.value))
          );
        }}
        value={searchTerm ?? ""}
      />
      {!isMultiCluster && (
        <>
          <MultiSelectByQueryParams
            hasIsExclude={false}
            options={namespaces}
            queryKey="namespaces"
            hasVirtualizedList
          />
          <MultiSelectByQueryParams hasIsExclude={false} options={labels} queryKey="labels" hasVirtualizedList />
          <MultiSelectByQueryParams
            hasIsExclude={false}
            options={annotations}
            queryKey="annotations"
            hasVirtualizedList
          />
          <MultiSelectByQueryParams
            options={Object.values(PerformanceOptimizedTypes).map((type) => type) as string[]}
            queryKey="optimizedPerformanceFilter"
            name="optimized performance"
            hasIsExclude={false}
            hasSelectAllAndClearSelection={false}
            isSearchable={false}
            disableSort
          />
          <MultiSelectByQueryParams
            options={Object.values(CostOptimizedTypes).map((type) => type) as string[]}
            queryKey="optimizedCostFilter"
            name="optimized cost"
            hasIsExclude={false}
            hasSelectAllAndClearSelection={false}
            isSearchable={false}
            disableSort
          />
          <CustomFilterChip
            label="hpa optimized"
            onClick={() => {
              setIsHpaAuto(!isHpaAuto ? true : undefined);
            }}
            selected={!!isHpaAuto}
          />
        </>
      )}
      {isMultiCluster && (
        <>
          {HAS_TAGS_FILTER && (
            <MultiSelectByQueryParams
              hasIsExclude={false}
              options={tags}
              queryKey="tags"
              tooltipPrefix={
                isTagsExclude ? (
                  <>
                    tags <b>exclude: </b>
                  </>
                ) : (
                  <>
                    tags <b>include: </b>
                  </>
                )
              }
              hasVirtualizedList
            />
          )}
          <MultiSelectByQueryParams
            hasIsExclude={false}
            options={clusterNames}
            queryKey="clusters"
            hasVirtualizedList
          />
        </>
      )}
    </div>
  );
};

export default SummaryReportFilters;
