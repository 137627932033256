import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-github_dark";
import "ace-builds/src-noconflict/theme-nord_dark";
import "ace-builds/src-noconflict/ext-searchbox";
import { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { Typography } from "@mui/material";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import Tooltip from "../Tooltip";
import { exportStringAsFile, FileExtension } from "../../utils/exportUtils";
import MainDownloadIcon from "../../Icons/MainDownloadIcon";
import SearchIcon from "../../Icons/SearchIcon";

interface Props {
  yaml: string;
  maxLines?: number;
  minLines?: number;
  exportFileName?: string;
}

const YamlViewer = ({ yaml, maxLines, minLines, exportFileName }: Props) => {
  const [showCopied, setShowCopied] = useState(false);
  const editorRef = useRef<AceEditor | null>(null);

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showCopied]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(yaml ? `\n${yaml}` : yaml)
      .then(() => {
        setShowCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleExport = () => {
    exportStringAsFile({
      fileString: yaml,
      fileName: exportFileName ?? "export",
      extension: FileExtension.YAML,
    });
  };

  const handleSearch = () => {
    const editor = editorRef.current?.editor;
    if (editor) {
      editor.execCommand("find");
    }
  };

  return (
    <div className="relative h-full">
      <div className="absolute right-0 z-10 flex mr-1 ">
        <Tooltip title={"Copy to clipboard"} placement="top-end">
          <button onClick={handleCopy} className="text-white px-1 py-1 hover:text-mainMenuSelected">
            <DuplicateIcon width={18} height={18} />
          </button>
        </Tooltip>
        <Tooltip title={"Export YAML"} placement="top-end">
          <button onClick={handleExport} className="text-white px-[6px] py-[6px] hover:text-mainMenuSelected">
            <MainDownloadIcon width={14} height={14} />
          </button>
        </Tooltip>
        <Tooltip title={"Search"} placement="top-end">
          <button onClick={handleSearch} className="text-white px-[6px] py-[6px] hover:text-mainMenuSelected">
            <SearchIcon width={14} height={14} />
          </button>
        </Tooltip>
      </div>
      <AceEditor
        ref={editorRef}
        mode={"yaml"}
        theme={"nord_dark"}
        width={"100%"}
        height={"100%"}
        className="rounded border border-gray-300 h-full"
        fontSize={14}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={false}
        wrapEnabled={true}
        readOnly={true}
        setOptions={{
          autoScrollEditorIntoView: true,
          useWorker: true,
          showLineNumbers: false,
          tabSize: 2,
          maxLines: maxLines,
          minLines: minLines,
          behavioursEnabled: false,
          indentedSoftWrap: true,
          enableSearch: true,
        }}
        value={yaml ? `\n${yaml}` : yaml}
      />
      <div
        className={clsx(
          "bg-[rgba(255,255,255,0.8)] absolute text-black rounded-md fade-in-and-out bottom-2 right-2 px-1 ",
          {
            hidden: !showCopied,
          }
        )}
      >
        <Typography>Copied to clipboard</Typography>
      </div>
    </div>
  );
};

export default YamlViewer;
