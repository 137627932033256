import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { GetUnevictableGraph, GetUnevictableGraphResponse } from "../../api/fetcher";
import { SCALEOPS_COLORS } from "../../colors";
import { getEpochStartDate } from "../../utils/dateAndTimeUtils";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";
import ComposeChart from "../ComposeChart/ComposeChart";
import useGetComposeChartState from "../ComposeChart/useGetComposeChartState";
import { ChartData, ChartElement, renderNameFunction, WASTE_KEY, YAxisTickFormatterType } from "../ComposeChart/utils";
import CustomLegend from "../CustomLegend";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../Tab";
import { ChartsTabOptions } from "./Utils";

const OPTIMIZED_ALLOCATABLE_KEY = "OptimizedAllocatable";
const ESTIMATED_BLOCKED_ALLOCATABLE_KEY = "EstimatedBlockedAllocatable";
const UnevictableSpan = () => <span className="text-strongBorder"></span>;
const SHOW_WASTE = { fromKey: "allocatable", toKey: OPTIMIZED_ALLOCATABLE_KEY };
const syncId = "unevictable";
const CHART_HEIGHT = 250;
const WRAPPER_DIV_CLASS_NAME = "border-none";

const { queryFn, queryKey } = GetUnevictableGraph();

const isSnapshotServer = IsSnapshotServer();
const getInitialState = (daysAgo: ChartsTabOptions) => {
  const now = Date.now();
  const daysAgoInt = Number(daysAgo.replace(/\D/g, ""));
  const startDate = getEpochStartDate(daysAgoInt, now);
  return {
    from: startDate,
    to: now,
  };
};

const transformKey = (key: string): string =>
  ({
    changeClusterAutoScalerConfiguration: "Cluster auto scaler configuration",
    exploreNodeRestriction: "Node restriction",
    exploreSchedulingReasons: "Scheduling reasons",
    optimizeUnevictablePods: "Unevictable pods",
  }[key] || key);

const elements: ChartElement[] = [
  {
    key: OPTIMIZED_ALLOCATABLE_KEY,
    color: SCALEOPS_COLORS.main.green,
    name: "Optimized allocatable",
  },
  {
    key: "allocatable",
    color: SCALEOPS_COLORS.main.orange,
    name: "Allocatable",
  },
];

const snapshotElements: ChartElement[] = [
  {
    key: OPTIMIZED_ALLOCATABLE_KEY,
    color: SCALEOPS_COLORS.main.green,
    name: "Optimized allocatable",
  },
  {
    key: "allocatable",
    color: SCALEOPS_COLORS.main.orange,
    name: "Allocatable",
  },
  {
    key: ESTIMATED_BLOCKED_ALLOCATABLE_KEY,
    color: SCALEOPS_COLORS.main.red,
    name: "Estimated Unoptimized Allocatable",
  },
];

const UnevictableResourcesCharts = () => {
  const [enableActiveSavings] = useQueryParam("enableActiveSavings", BooleanParam);
  const composeChartState = useGetComposeChartState({});
  const [cpuGraphData, setCpuGraphData] = useState<ChartData>([]);
  const [memoryGraphData, setMemoryGraphData] = useState<ChartData>([]);
  const [selectedTab, setSelectedTab] = useState(ChartsTabOptions.Week);
  const [dateRange, setRange] = useState(getInitialState(selectedTab));

  const { data, isLoading, error } = useQuery<GetUnevictableGraphResponse, Error>({
    queryKey: [queryKey, dateRange.from, dateRange.to],
    queryFn: () =>
      queryFn({
        from: dateRange.from,
        to: dateRange.to,
      }),
  });

  useEffect(() => {
    setRange(getInitialState(selectedTab));
  }, [selectedTab]);

  useEffect(() => {
    const cpu = [] as ChartData;
    const memory = [] as ChartData;
    if (data) {
      if (isSnapshotServer) {
        data.resources?.map((el) => {
          cpu.push({
            timestamp: String(el.timestamps),
            values: {
              ...(el?.cpu as { [key: string]: number }),
              [OPTIMIZED_ALLOCATABLE_KEY]:
                Number(el.cpu?.allocatable ?? 0) - Number(el.cpu?.blockedNodesAllocatable ?? 0),
              [ESTIMATED_BLOCKED_ALLOCATABLE_KEY]: Number(el.cpu?.estimatedUnoptimizedAllocatable ?? 0),
            },
          });
          memory.push({
            timestamp: String(el.timestamps),
            values: {
              ...(el?.memory as { [key: string]: number }),
              [OPTIMIZED_ALLOCATABLE_KEY]:
                Number(el.memory?.allocatable ?? 0) - Number(el.memory?.blockedNodesAllocatable ?? 0),
              [ESTIMATED_BLOCKED_ALLOCATABLE_KEY]: Number(el.memory?.estimatedUnoptimizedAllocatable ?? 0),
            },
          });
        }) ?? [];
      } else {
        data.resources?.map((el) => {
          cpu.push({
            timestamp: String(el.timestamps),
            values: {
              ...(el?.cpu as { [key: string]: number }),
              [OPTIMIZED_ALLOCATABLE_KEY]:
                Number(el.cpu?.allocatable ?? 0) - Number(el.cpu?.blockedNodesAllocatable ?? 0),
            },
            subValues: el.cpu?.wasteByReason
              ? {
                  Waste: Object.entries(el.cpu?.wasteByReason as { [key: string]: number }).map(([key, value]) => ({
                    key: transformKey(key),
                    value,
                  })),
                }
              : undefined,
          });
          memory.push({
            timestamp: String(el.timestamps),
            values: {
              ...(el?.memory as { [key: string]: number }),
              [OPTIMIZED_ALLOCATABLE_KEY]:
                Number(el.memory?.allocatable ?? 0) - Number(el.memory?.blockedNodesAllocatable ?? 0),
            },
            subValues: el.memory?.wasteByReason
              ? {
                  Waste: Object.entries(el.memory?.wasteByReason as { [key: string]: number }).map(([key, value]) => ({
                    key: transformKey(key),
                    value,
                  })),
                }
              : undefined,
          });
        }) ?? [];
      }

      setCpuGraphData(cpu);
      setMemoryGraphData(memory);
    }
  }, [data, dateRange]);

  if (error) {
    console.log("Error fetching unevictable graphs", error);
    return null;
  }

  return (
    <div className="flex flex-col mt-[36px] relative bg-white w-full border border-border rounded rounded-tl-none items-center justify-center">
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-[-1px] w-full")}>
        {Object.entries(ChartsTabOptions).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={String(value)}
              dataTestId={`unevictable-chart-${key}-tab`}
              hasDefaultGrayBorders
            />
          );
        })}
      </div>
      <div className="flex w-full p-[20px] pb-[0px] gap-4">
        <div className="w-[50%]">
          <ComposeChart
            data={cpuGraphData}
            title={
              <>
                CPU <UnevictableSpan />
              </>
            }
            isLoading={isLoading}
            elements={isSnapshotServer && enableActiveSavings ? snapshotElements : elements}
            yAxisTickFormatterType={YAxisTickFormatterType.CPU}
            yAxisDomain={[0, (dataMax: number) => dataMax * 1.2]}
            syncId={syncId}
            tooltip={{
              tooltipId: "unevictable-tooltip-id",
            }}
            hasLegend={false}
            externalLegend={composeChartState}
            showWaste={SHOW_WASTE}
            height={CHART_HEIGHT}
            wrapDivClassName={WRAPPER_DIV_CLASS_NAME}
            hasLimitedTooltipWidth={false}
          />
        </div>
        <div className="w-[50%]">
          <ComposeChart
            data={memoryGraphData}
            title={
              <>
                Memory <UnevictableSpan />
              </>
            }
            isLoading={isLoading}
            elements={isSnapshotServer && enableActiveSavings ? snapshotElements : elements}
            yAxisTickFormatterType={YAxisTickFormatterType.Memory}
            yAxisDomain={[0, (dataMax: number) => dataMax * 1.2]}
            syncId={syncId}
            tooltip={{
              tooltipId: "unevictable-tooltip-id",
            }}
            showWaste={SHOW_WASTE}
            hasLegend={false}
            externalLegend={composeChartState}
            height={CHART_HEIGHT}
            wrapDivClassName={WRAPPER_DIV_CLASS_NAME}
            hasLimitedTooltipWidth={false}
          />
        </div>
      </div>
      <div
        className={clsx("p-3", {
          "opacity-0": isLoading,
        })}
      >
        <CustomLegend<string>
          selectedChartComponents={composeChartState.selectedChartComponents}
          setSelectedChartComponents={composeChartState.setSelectedChartComponents}
          componentStyle={composeChartState.legendComponentStyle}
          ChartComponents={composeChartState.chartComponents}
          renderNameFunction={(key) =>
            renderNameFunction(key, isSnapshotServer && enableActiveSavings ? snapshotElements : elements)
          }
          isDashedFnc={(key: string) => key.includes(WASTE_KEY)}
          className="-mt-1"
          fontWeight={500}
          fontSpanClassName="truncate"
          hasTooltip
          isSnapshotElementFnc={
            isSnapshotServer ? (key: string) => [ESTIMATED_BLOCKED_ALLOCATABLE_KEY].includes(key) : undefined
          }
        />
      </div>
    </div>
  );
};

export default UnevictableResourcesCharts;
