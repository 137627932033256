import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getConfig, GetConfigResponse, GetSimpleAuth, GetSimpleAuthResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CodeSnippet, { THEME } from "../../../components/CodeSnippet";
import { getIntegrationCommand } from "./commandsUtils";
import PermissionsDocsLink from "./PermissionsDocsLink";
import { AuthMethod } from "./utils";
import useGetVersion from "../../Cost/hooks/useGetVersion";

const configApi = getConfig();
const simpleAuth = GetSimpleAuth();

const DockLink = () => (
  <PermissionsDocsLink
    relativePath="/sso-and-login-security/built-in-authentication/"
    linkTitle="Built-In Authentication docs"
  />
);

interface Props {
  currentAuthMethod: AuthMethod;
}

const SimpleAuthContainer = ({ currentAuthMethod }: Props) => {
  const isCurrentSelectedMethod = currentAuthMethod === AuthMethod.BUILT_IN;
  const [ssoConf, setSsoConf] = useState<components["schemas"]["TypesAuthInfo"]>({ enabled: false, provider: "none" });
  const [adminCredentials, setAdminCredentials] = useState<{ username: string; password: string } | null>(null);

  const { data: confData } = useQuery<GetConfigResponse, Error>({
    queryKey: [configApi.queryKey],
    queryFn: configApi.queryFn,
    enabled: !isCurrentSelectedMethod,
  });

  const { data: simpleAuthData } = useQuery<GetSimpleAuthResponse, Error>({
    queryKey: [simpleAuth.queryKey],
    queryFn: simpleAuth.queryFn,
  });

  const { data: versionData } = useGetVersion();

  useEffect(() => {
    if (simpleAuthData?.users && simpleAuthData.users.length > 0) {
      const adminUser = simpleAuthData.users.find((user) => user.role === "Admin");
      const password = adminUser?.password || "";
      const username = adminUser?.username || "";

      setAdminCredentials({
        username,
        password,
      });
    }
  }, [simpleAuthData]);

  useEffect(() => {
    if (JSON.stringify(ssoConf) !== JSON.stringify(confData?.ssoConf))
      setSsoConf(
        confData?.ssoConf || {
          enabled: false,
          provider: "none",
        }
      );
  }, [confData]);

  return (
    <>
      {!isCurrentSelectedMethod && (
        <div className="flex flex-col gap-4">
          <CodeSnippet
            description={
              <div className="flex flex-col gap-4">
                <Typography variant="body2" className="text-text-lightBlack">
                  Run the code below to enable <b>ScaleOps built-in authentication</b> and <b>authorization</b> on your
                  cluster.
                  <br />
                </Typography>
              </div>
            }
            codeSnippet={getIntegrationCommand({
              currentIntegration: currentAuthMethod,
              selectedIntegration: AuthMethod.BUILT_IN,
              token: confData?.token,
              username: adminCredentials?.username,
              password: adminCredentials?.password,
              repository: versionData?.helmCommandConfig?.repositoryURL,
              hideToken: true,
              namespace: versionData?.namespace,
            })}
            codeSnippetToCopy={getIntegrationCommand({
              currentIntegration: currentAuthMethod,
              selectedIntegration: AuthMethod.BUILT_IN,
              token: confData?.token,
              username: adminCredentials?.username,
              password: adminCredentials?.password,
              repository: versionData?.helmCommandConfig?.repositoryURL,
              namespace: versionData?.namespace,
            })}
            theme={THEME.light}
          />
          <DockLink />
        </div>
      )}
      <div className="flex flex-col gap-4 text-text-lightBlack">
        <Typography variant="body2">
          {isCurrentSelectedMethod ? (
            <span>
              Explore the list of <b>users and passwords</b>.
            </span>
          ) : (
            <span>
              After enabling <b>ScaleOps built-in authentication</b> and <b>authorization</b>, you will have{" "}
              <b>admin</b>, <b>operator</b> and <b>viewer</b> users to operate in the platform
            </span>
          )}
        </Typography>
      </div>
    </>
  );
};

export default SimpleAuthContainer;
