import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import { ObjectParam, useQueryParam } from "use-query-params";
import InfoIcon from "../../../../Icons/InfoIcon";
import {
  GetWorkloadAnalytics,
  GetWorkloadAnalyticsParams,
  GetWorkloadAnalyticsResponse,
} from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import ChartTooltipItemsCount from "../../../../components/ChartTooltipItemsCount";
import ChartTooltipTitle from "../../../../components/ChartTooltipTitle";
import CustomLegend from "../../../../components/CustomLegend";
import Loading from "../../../../components/Loading";
import InfoTooltip from "../../../../components/Tooltip";
import LinkableTooltipElement from "../../../../components/common/LinkableTooltipElement";
import { getMaxFlatArray } from "../../../../utils/arrayUtils";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { formatLegendText, OverviewLinkType } from "../../../../utils/graphUtils";
import {
  DOT_RADIUS,
  DOT_STROKE_WIDTH,
  LINE_CHART_TYPE,
  NO_OUTLINE,
  TOOLTIP_WRAPPER_CLASS_NAME,
} from "../../../../utils/styleUtils";
import FreezeTooltipWarning from "../../../Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import { CHART_HEIGHT, TooltipSortType, WORKLOAD_ANALYTICS_SYNCH_ID } from "./utils";

const NUMBER_OF_CHART_COMPONENTS_TO_SHOW_ON_LOAD = Infinity;
const MAX_TOOLTIP_COMPONENTS = 10;

const defaultGetElementsFormat = (key: string, regexp: string, color: string) => ({
  key: key,
  dataKey: key,
  label: formatLegendText(key, regexp),
  color: color,
  tooltipValueColor: color,
  fill: "none",
});

const defaultLegendSortFunction = (a: { [x: string]: string }, b: { [x: string]: string }) => {
  return Object.keys(a)[0].localeCompare(Object.keys(b)[0]);
};

const isNumber = (value: number | string) => {
  return !isNaN(Number(value));
};
type PayloadItem = {
  value: string | number;
  name?: string;
  dataKey: string;
  stroke: string;
  payload: { [key: string]: string };
};
interface CustomTooltipProps {
  displayNameFnc: (name?: string) => string | undefined;
  active?: boolean;
  payload?: PayloadItem[];
  label?: string;
  selectedChartComponents: string[];
  tooltipValueSuffix?: React.ReactNode;
  tickFormatter?: (value: number) => string;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
  tooltipSortFnc?: TooltipSortType;
  targetTooltipLink?: OverviewLinkType;
  secondaryValue?: (item?: PayloadItem) => React.ReactNode;
  valueTitle?: string;
}

const CustomTooltip = ({
  displayNameFnc,
  active,
  payload,
  label,
  selectedChartComponents,
  tooltipValueSuffix,
  tickFormatter,
  tooltipTrigger,
  frozenTooltipType,
  tooltipId,
  updateActiveTooltips,
  tooltipSortFnc = (a, b) => Number(b.value) - Number(a.value),
  targetTooltipLink = OverviewLinkType.Workload,
  secondaryValue,
  valueTitle,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  if (active && payload && payload.length) {
    const allItems = payload
      .sort(tooltipSortFnc)
      .filter((item) => selectedChartComponents.includes(item.dataKey ?? "") && isNumber(item.value));

    const allItemsLength = allItems.length;

    return (
      <div className={clsx(TOOLTIP_WRAPPER_CLASS_NAME, "pointer-events-auto")}>
        {label && <ChartTooltipTitle timestamp={label} valueTitle={valueTitle} />}
        {allItems.slice(0, MAX_TOOLTIP_COMPONENTS).map((item, index) => {
          const displayName = displayNameFnc(item.name);

          return (
            <LinkableTooltipElement
              key={index}
              color={item?.stroke ?? ""}
              value={
                <>
                  {tickFormatter ? tickFormatter(Number(item.value)) : item.value}
                  {tooltipValueSuffix ?? ""}
                </>
              }
              label={displayName}
              rawLabel={String(item.name)}
              disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
              targetResource={targetTooltipLink}
              secondaryValue={secondaryValue ? secondaryValue(item) : undefined}
              textToCopyOnClick={item.name}
              hasLimitedWidth
            />
          );
        })}
        <ChartTooltipItemsCount count={allItemsLength} maxToShow={MAX_TOOLTIP_COMPONENTS} />
        <FreezeTooltipWarning
          tooltipTrigger={tooltipTrigger}
          frozenTooltipType={frozenTooltipType}
          entityType={targetTooltipLink}
        />
      </div>
    );
  }

  return null;
};

type Element = {
  key: string;
  label: string;
  color: string;
  tooltipValueColor?: string;
  fill?: string;
  dataKey?: string;
};

interface Props {
  title: string;
  regexp: string;
  displayNameFnc?: (name?: string) => string;
  whitelist?: string[];
  queryParams: GetWorkloadAnalyticsParams;
  wrapDivClassName?: string;
  infoTooltip?: React.ReactNode;
  YAxisDomain?: AxisDomain | undefined;
  xAxisInterval?: number;
  tooltipValueSuffix?: React.ReactNode;
  roundValues?: boolean;
  colors?: string[];
  tickFormatter?: (value: number) => string;
  YAxisDomainWithHeadroomAndWholeNumbers?: boolean;
  YAxisAllowDecimals?: boolean;
  maxValueLimit?: number;
  topk?: number;
  getElementsFormat?: (key: string, regexp: string, color: string) => Element;
  legendSortFunction?: (a: { [x: string]: string }, b: { [x: string]: string }) => number;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  dotted?: boolean;
  tooltipSortFnc?: TooltipSortType;
  targetTooltipLink?: OverviewLinkType;
  secondaryValue?: (item?: PayloadItem) => React.ReactNode;
  tooltipValueTitle?: string;
  hasStackedOffset?: boolean;
}

const MultipleRegexpLineChart = ({
  title,
  regexp,
  displayNameFnc = (name) => String(name ?? ""),
  infoTooltip,
  whitelist,
  queryParams,
  YAxisDomain,
  xAxisInterval,
  tooltipValueSuffix,
  roundValues,
  colors = SCALEOPS_COLORS.randomColors,
  tickFormatter = undefined,
  YAxisDomainWithHeadroomAndWholeNumbers,
  YAxisAllowDecimals,
  maxValueLimit,
  getElementsFormat = defaultGetElementsFormat,
  legendSortFunction = defaultLegendSortFunction,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType,
  updateActiveTooltips,
  dotted,
  tooltipSortFnc,
  targetTooltipLink,
  secondaryValue,
  tooltipValueTitle,
  hasStackedOffset,
}: Props) => {
  const { queryFn, queryKey } = GetWorkloadAnalytics();
  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();

  const [chartComponents, setChartComponents] = useState<Element[]>([]);
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>([]);
  const [autoYDomain, setAutoYDomain] = useState<AxisDomain | undefined>(undefined);

  const [legendComponentStyle, setLegendComponentStyle] = useState<{ [key: string]: { color: string } }>({});

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const { data, isLoading, error, isError } = useQuery<GetWorkloadAnalyticsResponse, Error>({
    queryKey: [
      queryKey,
      queryParams.uniqueQueryKey,
      queryParams.from,
      queryParams.to,
      queryParams.name,
      queryParams.namespace,
      queryParams.workloadType,
      queryParams.types,
      queryParams.container,
      queryParams.topk,
    ],
    queryFn: () => queryFn(queryParams),
  });

  useEffect(() => {
    if (data && data.values && data.values[0] && data.values[0].values) {
      const chartComponentsValues = data?.values
        .map((item) => item.values && Object.keys(item.values))
        .flat()
        .map((item) => String(item))
        .filter((key) => key.match(regexp) || whitelist?.includes(key));

      const chartComponentsObjects = [...new Set(chartComponentsValues)].map((key, index) => {
        const color = colors[index] ?? "#" + Math.random().toString(16).slice(2, 8);

        return getElementsFormat(key, regexp, color);
      });

      setLegendComponentStyle(
        chartComponentsObjects.reduce((acc, item) => {
          acc[item.key] = { color: item.color };
          return acc;
        }, {} as { [key: string]: { color: string } })
      );

      setChartComponents(chartComponentsObjects);
      setSelectedChartComponents(chartComponentsValues.slice(0, NUMBER_OF_CHART_COMPONENTS_TO_SHOW_ON_LOAD));
    }
  }, [data]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDates({ from: String(from), to: String(to) });
      if (from && to && setSelectedViewPeriod) {
        setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
      }
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  useEffect(() => {
    if (YAxisDomainWithHeadroomAndWholeNumbers && data && data.values && data.values.length) {
      const values = data.values.map((item) => item.values && Object.values(item.values)).flat();
      const maxValue = Math.ceil(Math.max(...values.map((value) => Number(value))) * 1.1);
      setAutoYDomain([0, maxValue]);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className={clsx(CHART_HEIGHT, "px-4 py-16 bg-white border border-border rounded")}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  let graphData = data.values
    ? data.values.map((item) => ({
        timestamp: item.timestamp,
        ...(item.values || {}),
      }))
    : [];

  // round all values except timestamp to 2 decimal places max
  graphData = roundValues
    ? graphData.map((item) => {
        const newItem = { ...item };
        Object.keys(item).forEach((key) => {
          if (key !== "timestamp") {
            newItem[key as keyof typeof newItem] = String(
              Math.round(Number(item[key as keyof typeof newItem]) * 100) / 100
            );
          }
        });
        return newItem;
      })
    : graphData;
  const firstXPointString = String(graphData[0]?.timestamp);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix();
  const lastXPointString = String(graphData[graphData.length - 1]?.timestamp);
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix();

  let maxValue = getMaxFlatArray(
    graphData.map((item) => {
      return Object.values(item)
        .filter((value) => !Number.isNaN(Number(value)))
        .map((value) => Number(value));
    })
  );

  maxValue = Math.round(maxValue * 122) / 100;
  if (maxValueLimit) maxValue = maxValue > maxValueLimit || !maxValue ? 100 : maxValue;

  return (
    <div
      className={clsx(
        CHART_HEIGHT,
        "flex flex-col gap2 px-4 pt-8 pb-4 border border-border rounded unselectable-svg-text bg-white"
      )}
    >
      <Typography variant="body2" className="w-full flex justify-center items-center gap-1" style={{ fontWeight: 600 }}>
        <span>{title}</span>
        {infoTooltip && (
          <InfoTooltip title={infoTooltip} placement="top" maxWidth={600}>
            <InfoIcon width={14} height={14} />
          </InfoTooltip>
        )}
      </Typography>
      <div className="grow relative">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={graphData}
            syncId={WORKLOAD_ANALYTICS_SYNCH_ID}
            onMouseDown={(e) => {
              e.activeLabel &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  from: adjustedDayjs(e.activeLabel).unix(),
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              selectPosition?.from &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  to: adjustedDayjs(e.activeLabel).unix(),
                  toX: e.activeLabel,
                });
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to) {
                if (selectPosition?.from < selectPosition?.to) {
                  setSelectPosition({
                    ...selectPosition,
                    to: lastXPointEpoch,
                    toX: lastXPointString,
                  });
                } else {
                  setSelectPosition({
                    to: selectPosition.from,
                    toX: selectPosition.fromX,
                    from: firstXPointEpoch,
                    fromX: firstXPointString,
                  });
                }
              }
            }}
          >
            <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
            <Tooltip
              content={
                <CustomTooltip
                  selectedChartComponents={selectedChartComponents}
                  tooltipValueSuffix={tooltipValueSuffix}
                  tickFormatter={tickFormatter}
                  tooltipTrigger={tooltipTrigger}
                  frozenTooltipType={frozenTooltipType}
                  tooltipId={queryKey}
                  updateActiveTooltips={updateActiveTooltips}
                  tooltipSortFnc={tooltipSortFnc}
                  targetTooltipLink={targetTooltipLink}
                  secondaryValue={secondaryValue}
                  valueTitle={tooltipValueTitle}
                  displayNameFnc={displayNameFnc}
                />
              }
              trigger={tooltipTrigger}
              wrapperStyle={NO_OUTLINE}
            />
            {chartComponents.map((element, index) => {
              if (!element || !selectedChartComponents.includes(String(element.dataKey))) return null;
              return (
                <Area
                  type={LINE_CHART_TYPE}
                  strokeWidth={dotted ? 0 : 1}
                  dataKey={String(element.dataKey)}
                  name={element.label}
                  stroke={element.color}
                  fill="none"
                  radius={dotted ? DOT_RADIUS : 0}
                  dot={{ r: dotted ? 1 : 0, strokeWidth: dotted ? DOT_STROKE_WIDTH : 0 }}
                  isAnimationActive={!dotted}
                  style={hasStackedOffset ? { transform: `translate(0,-${index * 1.24}px)` } : undefined}
                />
              );
            })}
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="#3B8BFF"
                fill="#3B8BFF"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
            <XAxis
              dataKey="timestamp"
              interval={Math.floor(graphData.length / (xAxisInterval ?? 5))}
              style={{ fontSize: "x-small" }}
              tickFormatter={(value) => {
                const epochValue = adjustedDayjs(String(value)).unix();
                return getTimeFormatFromEpochAndPeriodInHours(epochValue, selectedViewPeriod);
              }}
              strokeWidth={2}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={YAxisDomain || autoYDomain || [0, maxValue]}
              tickFormatter={
                maxValue
                  ? tickFormatter ||
                    ((value: number) => {
                      return `${value}%`;
                    })
                  : () => ""
              }
              strokeWidth={2}
              tickCount={autoYDomain ? Math.floor(maxValue) + 1 : undefined}
              tickLine={!!maxValue}
              allowDecimals={YAxisAllowDecimals}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full mt-3 bg-white">
        <div className="w-full max-h-[80px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md">
          <CustomLegend<string>
            selectedChartComponents={selectedChartComponents}
            setSelectedChartComponents={setSelectedChartComponents}
            componentStyle={legendComponentStyle}
            ChartComponents={chartComponents
              .map((component) => ({
                [String(component.dataKey)]: String(component.dataKey),
              }))
              .sort(legendSortFunction)
              .reduce((a, b) => ({ ...a, ...b }), {})}
            renderNameFunction={(key) => {
              const element = chartComponents.find((e) => e.key === key);
              return element ? displayNameFnc(element.label) : "";
            }}
            className="-mt-1"
            fontWeight={400}
            fontSpanClassName="text-[10px]"
            hasTooltip
          />
        </div>
      </div>
    </div>
  );
};

export default MultipleRegexpLineChart;
