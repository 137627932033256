import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "../components/Tooltip";
import BetaIcon from "../Icons/BetaIcon";
import { useMainContext } from "../MainContext";
import MainMenuItem, { BETA_ICON_SIZE, MainMenuItemSize } from "./MainMenuItem";

const ARROW_ICON_SIZE = 18;

type URL = {
  name: string;
  url: string;
  variant?: "caption" | "body2";
  disabled?: boolean;
  isBeta?: boolean;
};

interface Props {
  baseUrl: URL;
  urls: URL[];
  title: ReactNode;
  icon?: ReactElement;
  MainMenuItemSize?: MainMenuItemSize;
  ItemsWrapperClassName?: string;
  openByDefault?: boolean;
  isBeta?: boolean;
  disabled?: boolean;
  disableInfo?: React.ReactNode;
  children?: ReactNode;
  className?: string;
}

const MultiMenu = ({
  baseUrl,
  urls,
  title,
  icon,
  MainMenuItemSize,
  ItemsWrapperClassName,
  openByDefault,
  isBeta,
  disabled,
  disableInfo,
  children,
  className,
}: Props) => {
  const URL_ARRAY_VALUES = [baseUrl.url, ...urls.map((url) => url.url)];

  const location = useLocation();
  const navigate = useNavigate();
  const { currentCluster } = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClusterNamesHistory, setSelectedClusterNamesHistory] = useState<string[]>([]);
  const [isInnerItemsSelected, setIsInnerItemsSelected] = useState(false);

  useEffect(() => {
    if (openByDefault) {
      setIsOpen(true);
    }
  }, [openByDefault]);

  useEffect(() => {
    if (currentCluster && !selectedClusterNamesHistory.includes(currentCluster)) {
      setSelectedClusterNamesHistory([...selectedClusterNamesHistory, currentCluster]);
    }
  }, [currentCluster]);

  useEffect(() => {
    const isInnerItemsSelectedValue = URL_ARRAY_VALUES.some((url) => location.pathname === url);
    setIsInnerItemsSelected(isInnerItemsSelectedValue);
    isInnerItemsSelectedValue && setIsOpen(isInnerItemsSelectedValue);
  }, [location.pathname]);

  return (
    <Tooltip title={disableInfo} disabled={!disableInfo || !disabled}>
      <div
        className={clsx(
          "flex flex-col relative",
          {
            "gap-2": isOpen,
            "opacity-50 cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
          },
          className
        )}
      >
        {!!isBeta && (
          <BetaIcon
            width={BETA_ICON_SIZE}
            height={BETA_ICON_SIZE}
            className="text-guideline-lessDarkPurple absolute top-[4px] left-[-10px]"
          />
        )}
        <Typography
          onClick={() => {
            const isUrlIncluded = URL_ARRAY_VALUES.some((url) => location.pathname === url);
            if (isUrlIncluded || isOpen || disabled) {
              setIsOpen(!isOpen);
            } else {
              const url = currentCluster ? `${baseUrl.url}?currentClusterURLParam=${currentCluster}` : baseUrl.url;
              !disabled && navigate(url);
            }
          }}
          className="flex px-4 py-1 rounded-lg items-center"
          variant={baseUrl.variant ?? "body2"}
        >
          <div
            className={clsx("grow flex justify-start items-center gap-2", {
              underline: isInnerItemsSelected && !isOpen,
            })}
          >
            {icon}
            {title}
          </div>
          <div>
            {isOpen ? (
              <KeyboardArrowUp
                sx={{
                  width: ARROW_ICON_SIZE,
                  height: ARROW_ICON_SIZE,
                }}
              />
            ) : (
              <KeyboardArrowDown
                sx={{
                  width: ARROW_ICON_SIZE,
                  height: ARROW_ICON_SIZE,
                }}
              />
            )}
          </div>
        </Typography>
        <div
          className={clsx(ItemsWrapperClassName, "ml-[25px] border-l border-text-lightBlack pl-4 flex flex-col gap-2")}
          style={{
            maxHeight: isOpen ? "100vh" : 0,
            overflow: "hidden",
            transition: isOpen ? "max-height 0.2s ease-in" : undefined,
          }}
        >
          {urls.map((url) => (
            <MainMenuItem
              title={url.name}
              to={url.url}
              disabled={disabled || url.disabled}
              variant="caption"
              size={MainMenuItemSize}
              isBeta={url.isBeta}
            />
          ))}
        </div>
        {children}
      </div>
    </Tooltip>
  );
};

export default MultiMenu;
