import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetDashboardByNamespaceResponse, RestoreClusterPolicy, getDashboardByNamespace } from "../../api/fetcher";
import { useMainContext } from "../../MainContext";
import { useWorkloadsContext } from "../../WorkloadsContext";

const dashboardByNamespace = getDashboardByNamespace();
const restorePolicy = RestoreClusterPolicy();

const useRestoreClusterPolicy = ({ disableQueries }: { disableQueries?: boolean }) => {
  const { currentCluster } = useMainContext();
  const clusterName = currentCluster || "current";
  const queryClient = useQueryClient();
  const { updateOverriddenWorkloadsByUpdateFunction, deleteOverriddenWorkloadsProperties } = useWorkloadsContext();

  const { data } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey],
    queryFn: () => dashboardByNamespace.queryFn({}),
    enabled: !disableQueries,
  });
  const rowsIds = data?.workloads?.filter((wl) => wl.isPolicyForced !== true).map((wl) => wl.id) || [];

  return useMutation(() => restorePolicy.queryFn(), {
    onMutate: () => {
      updateOverriddenWorkloadsByUpdateFunction({
        ids: rowsIds,
        updateFunction: (workload) => {
          const smartPolicyName = data?.workloads?.find((wl) => wl.id === workload.id)?.smartPolicyName;
          return { ...workload, policyName: smartPolicyName || "production" };
        },
      });
    },
    onError: (error: Error) => {
      deleteOverriddenWorkloadsProperties({
        ids: rowsIds,
        propertyNames: ["policyName"],
      });
      updateOverriddenWorkloadsByUpdateFunction({
        ids: rowsIds,
        updateFunction: (workload) => {
          const policyName = data?.workloads?.find((wl) => wl.id === workload.id)?.policyName;
          return { ...workload, policyName };
        },
      });
      console.log(`Error: ${error.message}`);
      toast.error(`Failed to restore suggested policy on cluster: ${clusterName}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([dashboardByNamespace.queryKey]).catch((error) => console.log(error));
      toast.success(`Successfully restore suggested policy on cluster: ${clusterName}`);
    },
  });
};

export default useRestoreClusterPolicy;
