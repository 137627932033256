import {SSOConf, SSOIntegration} from "./utils";
import React from "react";
import FormInput from "../../../components/formComponents/FormInput";

interface Props {
  type: SSOIntegration;
  ssoSetupConf?: SSOConf;
  authorization?: boolean;
}

const AuthConfForm = ({ type, authorization, ssoSetupConf }: Props) => {
  return (
    <div className="gap-10 relative h-fit flex">
      {authorization && (
        <>
          <FormInput
            label="Default Admin Group"
            name="defaultAdminGroup"
            type="text"
            value={ssoSetupConf?.defaultAdminGroup}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setDefaultAdminGroup(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.Okta && (
        <>
          <FormInput
            label="Issuer URL"
            name="issuerUrl"
            type="text"
            value={ssoSetupConf?.issuerUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setIssuerUrl(e.target.value.trim())}
          />
          <FormInput
            label="Client ID"
            name="clientID"
            type="text"
            value={ssoSetupConf?.clientID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientID(e.target.value.trim())}
          />
          <FormInput
            label="Client Secret"
            name="clientSecret"
            type="password"
            showHidePasswordToggle
            value={ssoSetupConf?.clientSecret}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientSecret(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.OAuth2 && (
        <>
          <FormInput
            label="Issuer URL"
            name="issuerUrl"
            type="text"
            value={ssoSetupConf?.issuerUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setIssuerUrl(e.target.value.trim())}
          />
          <FormInput
            label="Client ID"
            name="clientID"
            type="text"
            value={ssoSetupConf?.clientID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientID(e.target.value.trim())}
          />
          <FormInput
            label="Client Secret"
            name="clientSecret"
            type="password"
            showHidePasswordToggle
            value={ssoSetupConf?.clientSecret}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientSecret(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.Github && (
        <>
          <FormInput
            label="Client ID"
            name="clientID"
            type="text"
            value={ssoSetupConf?.clientID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientID(e.target.value.trim())}
          />
          <FormInput
            label="Client Secret"
            name="clientSecret"
            type="password"
            showHidePasswordToggle
            value={ssoSetupConf?.clientSecret}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientSecret(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.Google && (
        <>
          <FormInput
            label="Client ID"
            name="clientID"
            type="text"
            value={ssoSetupConf?.clientID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientID(e.target.value.trim())}
          />
          <FormInput
            label="Client Secret"
            name="clientSecret"
            type="password"
            showHidePasswordToggle
            value={ssoSetupConf?.clientSecret}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientSecret(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.Azure && (
        <>
          <FormInput
            label="Tenant ID"
            name="tenantID"
            type="text"
            value={ssoSetupConf?.tenantID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setTenantID(e.target.value.trim())}
          />
          <FormInput
            label="Client ID"
            name="clientID"
            type="text"
            value={ssoSetupConf?.clientID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientID(e.target.value.trim())}
          />
          <FormInput
            label="Client Secret"
            name="clientSecret"
            type="password"
            showHidePasswordToggle
            value={ssoSetupConf?.clientSecret}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientSecret(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.Openshift && (
        <>
          <FormInput
            label="Client ID"
            name="clientID"
            type="text"
            value={ssoSetupConf?.clientID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientID(e.target.value.trim())}
          />
          <FormInput
            label="Client Secret"
            name="clientSecret"
            type="password"
            showHidePasswordToggle
            value={ssoSetupConf?.clientSecret}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setClientSecret(e.target.value.trim())}
          />
        </>
      )}
      {type == SSOIntegration.LDAP && (
        <>
          <FormInput
            label="Server"
            name="server"
            type="text"
          />
          <FormInput
            label="Base DN"
            name="baseDN"
            type="text"
          />
          <FormInput
            label="Bind User"
            name="bindUser"
            type="text"
          />
          <FormInput
            label="Bind Password"
            name="bindPassword"
            type="password"
            showHidePasswordToggle
          />
        </>
      )}
      {authorization && (
        <>
          {type == SSOIntegration.Okta && (
            <>
              <FormInput
                label="Groups Claim"
                name="groupsClaim"
                type="text"
                value={ssoSetupConf?.groupsClaim}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setGroupsClaim(e.target.value.trim())}
              />
            </>
          )}
          {type == SSOIntegration.OAuth2 && (
            <>
              <FormInput
                label="Groups Claim"
                name="groupsClaim"
                type="text"
                value={ssoSetupConf?.groupsClaim}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setGroupsClaim(e.target.value.trim())}
              />
            </>
          )}
          {type == SSOIntegration.Google && (
            <>
              <FormInput
                label="Account Credentials"
                name="credentials"
                type="text"
              />
            </>
          )}
          {type == SSOIntegration.Azure && (
            <>
              <FormInput
                label="Groups Claim"
                name="groupsClaim"
                defaultValue={"groups"}
                type="text"
                value={ssoSetupConf?.groupsClaim}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => ssoSetupConf?.setGroupsClaim(e.target.value.trim())}
              />
            </>
          )}
          {type == SSOIntegration.LDAP && (
            <>
              <FormInput
                label="Port"
                name="port"
                type="number"
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AuthConfForm;
