import clsx from "clsx";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import DragIcon from "../../Icons/DragIcon";
import { Cols, Elements, GirdLayout, LayoutEntry } from "./utils";

const ROW_HEIGHT = 266;
const NUMBER_OF_COLUMNS = 2;
const DEFAULT_ELEMENT_COLSPAN = 1;
const DEFAULT_ELEMENT_ROWSPAN = 1;
const PRINT_CHANGED_LAYOUT = false;
const DRAG_ICON_SIZE = 15;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface GridLayoutProps {
  layoutId: string;
  elements: Elements;
  layout: LayoutEntry[];
  setLayout?: React.Dispatch<React.SetStateAction<LayoutEntry[]>>;
  saveLayout?: (layout: GirdLayout[]) => void;
  rowHeight?: number;
  cols?: Cols;
  numberOfColumns?: number;
  elementColspan?: number;
  elementRowspan?: number;
  isReadOnly?: boolean;
  wrapperClassName?: string;
  onDragStart?: () => void;
  onDragStop?: () => void;
}

const GridLayout = ({
  layoutId,
  layout,
  setLayout,
  elements,
  rowHeight = ROW_HEIGHT,
  cols,
  numberOfColumns = NUMBER_OF_COLUMNS,
  elementColspan = DEFAULT_ELEMENT_COLSPAN,
  elementRowspan = DEFAULT_ELEMENT_ROWSPAN,
  isReadOnly,
  wrapperClassName,
  onDragStart,
  onDragStop,
}: GridLayoutProps) => {
  const [displayLayout, setDisplayLayout] = useState<GirdLayout[]>([]);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);

  useEffect(() => {
    const newLayout = layout.map((element) => {
      return {
        ...element,
        w: elementColspan,
        h: elementRowspan,
      };
    });

    setDisplayLayout(newLayout);
  }, [layout]);

  return (
    <div className={clsx(wrapperClassName, "border border-border rounded-lg p-3")} layout-id={layoutId}>
      <ResponsiveReactGridLayout
        isBounded={false}
        isResizable={false}
        isDraggable={isReadOnly ? false : isDraggable}
        containerPadding={[0, 0]}
        rowHeight={rowHeight}
        cols={
          cols ?? {
            lg: numberOfColumns,
            md: numberOfColumns,
            sm: numberOfColumns,
            xs: numberOfColumns,
            xxs: numberOfColumns,
          }
        }
        compactType="vertical"
        onLayoutChange={(changedLayout) => {
          if (PRINT_CHANGED_LAYOUT)
            /**
             * Do not remove this console.log
             * @barel22
             */
            console.log(
              "xxx changedLayout:",
              changedLayout?.map((element) => ({
                x: element.x,
                y: element.y,
                i: element.i,
              }))
            );
          if (setLayout && JSON.stringify(displayLayout) !== JSON.stringify(changedLayout)) {
            setLayout(changedLayout);
          }
        }}
        layouts={{ lg: displayLayout, md: displayLayout, sm: displayLayout, xs: displayLayout, xxs: displayLayout }}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
      >
        {displayLayout.map((item) => {
          const element = elements[item.i];
          if (!element) return null;

          const zIndex = 900 - (item.y ?? 0);

          return (
            <div
              key={item.i}
              data-grid={item}
              className={clsx("block relative", {
                "hover:shadow-lg": isDraggable,
              })}
              style={{
                zIndex,
              }}
            >
              <div
                className={clsx(
                  "position absolute left-0 top-0 h-[35px] text-black w-full transparent flex justify-end group p-2",
                  {
                    "cursor-move": !isReadOnly,
                    "cursor-pointer": isReadOnly,
                  }
                )}
                onMouseEnter={() => setIsDraggable(true)}
                onMouseLeave={() => setIsDraggable(false)}
              >
                {!isReadOnly && (
                  <DragIcon
                    className="text-background-chip group-hover:text-black"
                    width={DRAG_ICON_SIZE}
                    height={DRAG_ICON_SIZE}
                  />
                )}
              </div>
              {element}
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default GridLayout;
