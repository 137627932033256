import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../../MainContext";
import { SchedulingBlockersFilterOptions } from "../../autoscalers/Utils";
import { BlockedNodeReasons } from "./utils";

interface ActionProps {
  node?: string;
  schedulerBlocker?: string;
}

const DEFAULT_EXPLORE_SCHEDULING_REASONS = Object.values(SchedulingBlockersFilterOptions)
  .map((blocker) => `schedulerBlockers=${blocker}`)
  .join("&");

const useGetNodeAction = () => {
  const navigate = useNavigate();
  const { currentCluster } = useMainContext();

  const actions = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [BlockedNodeReasons.OptimizeUnevictablePods]: (_: ActionProps) => {
      navigate(`/unevictable?currentClusterURLParam=${currentCluster ?? ""}&scrollToAutomateUnevictableWorkloads=1`);
    },
    [BlockedNodeReasons.ExploreSchedulingReasons]: ({ schedulerBlocker }: ActionProps) => {
      navigate(
        `/?currentClusterURLParam=${currentCluster ?? ""}&scrollTableIntoView=1&${
          schedulerBlocker ? `schedulerBlockers=${schedulerBlocker}` : DEFAULT_EXPLORE_SCHEDULING_REASONS
        }`
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [BlockedNodeReasons.ChangeClusterAutoScalerConfiguration]: (_: ActionProps) => {
      navigate(`/nodes?currentClusterURLParam=${currentCluster ?? ""}&scrollToNodeActions=1`);
    },
    [BlockedNodeReasons.ExploreNodeRestriction]: ({ node }: ActionProps) => {
      navigate(
        `/nodes?currentClusterURLParam=${currentCluster ?? ""}&selectedNodeOverviewId=${
          node ?? ""
        }&nodeOverviewSelectedTab=Yaml`
      );
    },
  };

  return actions;
};

export default useGetNodeAction;
