import {AuthMethod, SSOIntegration, SSOConf} from "./utils";
import { components } from "../../../api/schema";

interface GetIntegrationCommandProps {
  currentIntegration: AuthMethod;
  selectedIntegration: AuthMethod;
  selectedSsoIntegration?: SSOIntegration;
  token?: string;
  username?: string;
  password?: string;
  repository?: string;
  hideToken?: boolean;
  namespace?: string;
  ssoSetupConf?: SSOConf;
}

export const helmSetToString = (
  helmSets: components["schemas"]["VersionHelmCommandConfig"]["extraHelmSets"] | undefined
) => {
  if (!helmSets) {
    return "";
  }
  return helmSets.map(({ name, value }) => (name != null && value != null ? `--set ${name}=${value}` : "")).join(" ");
};

export const getIntegrationCommand = ({
  currentIntegration,
  selectedIntegration,
  selectedSsoIntegration,
  token,
  username,
  password,
  repository,
  hideToken,
  namespace,
  ssoSetupConf,
}: GetIntegrationCommandProps) => {
  let tokenToDisplay = "";
  namespace = namespace ?? "<SCALEOPS_NAMESPACE>";
  switch (true) {
    case !!hideToken && !!token && token.length > 0:
      tokenToDisplay = String(token).replace(/./g, "*");
      break;
    case !!token:
      tokenToDisplay = String(token);
      break;
    default:
      tokenToDisplay = "<SCALEOPS-TOKEN>";
  }

  let passwordToDisplay = "";
  switch (true) {
    case !!hideToken && !!password && password.length > 0:
      passwordToDisplay = String(password).replace(/./g, "*");
      break;
    case !!password:
      passwordToDisplay = String(password);
      break;
    default:
      passwordToDisplay = "<PASSWORD>";
  }
  let secretToDisplay = "";
  switch (true) {
    case !!hideToken && !!ssoSetupConf?.clientSecret && ssoSetupConf.clientSecret.length > 0:
      secretToDisplay = String(ssoSetupConf?.clientSecret).replace(/./g, "*");
      break;
    case !!ssoSetupConf?.clientSecret:
      secretToDisplay = String(ssoSetupConf?.clientSecret);
      break;
    default:
      secretToDisplay = "<CLIENT-SECRET>";
  }
  const repositoryToDisplay = repository ?? "<SCALEOPS_REPOSITORY>";

  switch (true) {
    case selectedIntegration === AuthMethod.DISABLED && currentIntegration !== AuthMethod.DISABLED:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
    --set authProvider.provider=none
`;
    case selectedIntegration === AuthMethod.BUILT_IN &&
      (currentIntegration === AuthMethod.SSO_WITH_AUTHORIZATION ||
        currentIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION):
      return `
### Save the following credentials before executing the command
### Username: ${username ?? ""} 
### Password: ${passwordToDisplay}
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
    --set authProvider.provider=builtInAuth
`;
    case selectedIntegration === AuthMethod.BUILT_IN && currentIntegration === AuthMethod.DISABLED:
      return `
### Save the following credentials before executing the command
### Username: ${username ?? ""} 
### Password: ${passwordToDisplay}
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
    --set authProvider.provider=builtInAuth
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Okta:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=okta \\
  --set authProvider.okta.issuerUrl=${ssoSetupConf?.issuerUrl ?? "<ISSUER-URL>"} \\
  --set authProvider.okta.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.okta.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.OAuth2:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=oauth2 \\
  --set authProvider.oauth2.issuerUrl=${ssoSetupConf?.issuerUrl ?? "<ISSUER-URL>"} \\
  --set authProvider.oauth2.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.oauth2.clientSecret=${secretToDisplay} \\
  --set authProvider.oauth2.scopes="{openid,profile,email}"
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Github:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=github \\
  --set authProvider.github.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.github.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Google:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=google \\
  --set authProvider.google.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.google.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Azure:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=azure \\
  --set authProvider.azure.tenantID=${ssoSetupConf?.tenantID ?? "<TENANT-ID>"} \\
  --set authProvider.azure.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.azure.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.LDAP:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay} \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=ldap \\
  --set authProvider.ldap.server=<LDAP-SERVER> \\
  --set authProvider.ldap.baseDN=<BASE-DN> \\
  --set authProvider.ldap.bindUserName=<BIND-USER> \\
  --set authProvider.ldap.bindUserPassword=<BIND-PASSWORD>
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Openshift:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=openshift \\
  --set authProvider.openshift.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.openshift.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Okta:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=okta \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.okta.groupsClaim=${ssoSetupConf?.groupsClaim ?? "<JWT-CLAIM-NAME-TO-USE>"} \\
  --set authProvider.okta.issuerUrl=${ssoSetupConf?.issuerUrl ?? "<ISSUER-URL>"} \\
  --set authProvider.okta.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.okta.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.OAuth2:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=oauth2 \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.oauth2.groupsClaim=${ssoSetupConf?.groupsClaim ?? "<JWT-CLAIM-NAME-TO-USE>"} \\
  --set authProvider.oauth2.issuerUrl=${ssoSetupConf?.issuerUrl ?? "<ISSUER-URL>"} \\
  --set authProvider.oauth2.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.oauth2.clientSecret=${secretToDisplay}
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Github:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=github \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.github.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.github.clientSecret=${secretToDisplay}      
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Google:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=google \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.google.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.google.clientSecret=${secretToDisplay} \\
  --set-file googleServiceAccountCredentials=/full/path/to/downloads/credentials.json
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Azure:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=azure \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.azure.tenantID=${ssoSetupConf?.tenantID ?? "<TENANT-ID>"} \\
  --set authProvider.azure.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.azure.clientSecret=${secretToDisplay} \\
  --set authProvider.azure.groupsClaim=${ssoSetupConf?.groupsClaim ?? "groups"}
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.LDAP:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=ldap \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.ldap.server=<LDAP-SERVER> \\
  --set authProvider.ldap.baseDN=<BASE-DN> \\
  --set authProvider.ldap.bindUserName=<BIND-USER> \\
  --set authProvider.ldap.bindUserPassword=<BIND-PASSWORD>
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Openshift:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  ${repositoryToDisplay}  \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${namespace} -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n ${namespace} -f - \\
  --set authProvider.provider=openshift \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=${ssoSetupConf?.defaultAdminGroup ?? "<ADMIN-GROUP>"} \\
  --set authProvider.openshift.clientID=${ssoSetupConf?.clientID ?? "<CLIENT-ID>"} \\
  --set authProvider.openshift.clientSecret=${secretToDisplay}
`;
    default:
      return "";
  }
};
