import { Typography } from "@mui/material";
import clsx from "clsx";
import InfoIcon from "../Icons/InfoIcon";
import Tooltip from "./Tooltip";

export const TABS_CONTAINER_GAP = "gap-[3px]";
export const TABS_CONTAINER_CLASS_NAME = `flex ${TABS_CONTAINER_GAP} absolute`;

interface Props {
  name: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
  dataTestId?: string;
  hoverClassName?: string;
  hasDefaultGrayBorders?: boolean;
  hasSelectedUnderline?: boolean;
  disabled?: boolean;
  tooltipContent?: React.ReactNode;
  star?: boolean;
  className?: string;
  tabIcon?: React.ReactNode;
  children?: React.ReactNode;
}

const Tab = ({
  name,
  onClick,
  isSelected,
  dataTestId,
  hoverClassName = "hover:bg-[#f4f5f6] hover:border hover:border-border hover:px-[35px] hover:border-b-0",
  hasDefaultGrayBorders,
  hasSelectedUnderline,
  disabled,
  tooltipContent,
  star,
  className,
  tabIcon,
  children,
}: Props) => {
  return (
    <Tooltip title={tooltipContent} disabled={!tooltipContent} maxWidth={500}>
      <div
        onClick={() => !disabled && onClick()}
        className={clsx(
          "flex justify-center items-center px-[36px] h-9 rounded-tl-lg rounded-tr-lg select-none",
          {
            [hoverClassName]: !isSelected && !disabled,
            "bg-background-tab": !isSelected,
            "bg-white": isSelected,
            "border border-b-0": isSelected && hasDefaultGrayBorders,
            "opacity-50 cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
          },
          { [className || ""]: className }
        )}
        aria-selected={isSelected}
        data-testid={dataTestId && `${dataTestId}-wrapper`}
      >
        <Typography
          variant="body2"
          data-testid={dataTestId && `${dataTestId}-option`}
          className={clsx("flex flex-col items-center justify-center", {
            "gap-1": tabIcon,
          })}
        >
          <div className="flex items-center justify-center gap-1">
            <span>{name}</span>
            {star && <InfoIcon width={10} height={10} />}
            {tabIcon}
          </div>
          {hasSelectedUnderline && isSelected && <div className="h-[1px] bg-guideline-lightPurple w-[120%]" />}
        </Typography>
        {children}
      </div>
    </Tooltip>
  );
};

export default Tab;
