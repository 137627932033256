import { components } from "../../../../api/schema";
import SelectDashboard from "../../../../components/Dashboard/SelectDashboard";
import SaveAndDuplicateButtons from "../../../../components/GridLayout/SaveAndDuplicateButtons";
import MultiSelect from "../../../../components/MultiSelect";
import VerticalSeparator from "../../../../components/VerticalSeparator";
import ContainerFilter from "../ContainerFilter";
import SelectViewPeriod from "../SelectViewPeriod";
import { categoryOptions, CustomDashboard, WORKLOAD_TROUBLESHOOT_DASHBOARD_CATEGORY } from "./utils";

const DEFAULT_DASHBOARDS = Object.entries(CustomDashboard).map((option) => {
  return [option[0], option[1]];
});

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setIsDuplicateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  setIsCreateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  existingDashboardNames: string[];
  deleteDashboard: (dashboardName: string) => void;
  selectedElements: (string | undefined)[];
  setSelectedElements: (selectedElements: (string | undefined)[]) => void;
  wasLayoutChanged: boolean;
  saveDashboard: () => void;
  isReadOnly: boolean;
}

const WorkloadTroubleshootTopSection = ({
  selectedWorkload,
  setIsDuplicateDialogueOpen,
  setIsCreateDialogueOpen,
  existingDashboardNames,
  deleteDashboard,
  selectedElements,
  setSelectedElements,
  wasLayoutChanged,
  saveDashboard,
  isReadOnly,
}: Props) => {
  return (
    <div className="p-4 h-[6.5rem] w-full border border-border rounded-lg flex gap-4 relative">
      <SelectDashboard
        setIsDuplicateDialogueOpen={setIsDuplicateDialogueOpen}
        setIsCreateDialogueOpen={setIsCreateDialogueOpen}
        existingDashboardNames={existingDashboardNames}
        deleteDashboard={deleteDashboard}
        defaultDashboards={DEFAULT_DASHBOARDS}
        defaultDashboard={CustomDashboard.Performance}
        dashBoardCategory={WORKLOAD_TROUBLESHOOT_DASHBOARD_CATEGORY}
        className="w-[215px]"
      />
      <MultiSelect
        label="Charts"
        selected={selectedElements}
        setSelected={setSelectedElements}
        categoryOptions={categoryOptions}
        // modifyOptionText={(option) => Graph[option as keyof typeof Graph]}
        width={200}
        className="h-[2.5rem]"
        showNumberOfSelectedValue
        hasSelectAllAndClearSelection={false}
        isSearchable={true}
      />
      <VerticalSeparator />
      <div className="w-full">
        <ContainerFilter
          namespace={selectedWorkload.namespace}
          workloadName={selectedWorkload.workloadName}
          workloadType={selectedWorkload.type}
        />
      </div>
      <VerticalSeparator />
      <SaveAndDuplicateButtons
        saveDashboard={saveDashboard}
        wasLayoutChanged={wasLayoutChanged}
        setIsDuplicateDialogueOpen={setIsDuplicateDialogueOpen}
        isReadOnly={isReadOnly}
      />
      <VerticalSeparator />
      <SelectViewPeriod />
    </div>
  );
};

export default WorkloadTroubleshootTopSection;
