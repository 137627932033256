import { Typography } from "@mui/material";
import clsx from "clsx";
import ScaleOpsSpan from "./ScaleOpsSpan";
import Tooltip from "./Tooltip";

const BetaFeatureTooltipContent = () => {
  return (
    <p>
      <ScaleOpsSpan className="mt-[4px]" /> beta feature.
    </p>
  );
};

interface Props {
  wrapperClassName?: string;
  chipClassName?: string;
  isTooltipDisabled?: boolean;
}

const BetaChip = ({ wrapperClassName, chipClassName, isTooltipDisabled }: Props) => {
  return (
    <Tooltip title={<BetaFeatureTooltipContent />} className={wrapperClassName} disabled={isTooltipDisabled}>
      <Typography
        className={clsx(
          chipClassName,
          "text-primary-purpleBlue bg-white border-primary-purpleBlue border-2 px-1 py-1 rounded-lg flex justify-center items-center shadow-sm hover:shadow-none "
        )}
        fontSize={12}
        fontWeight={500}
        style={{
          height: 18,
        }}
      >
        Beta
      </Typography>
    </Tooltip>
  );
};

export default BetaChip;
