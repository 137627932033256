import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import {
  GetCaConfiguration,
  GetCaConfigurationDeployment,
  GetCaConfigurationDeploymentResponse,
} from "../../api/fetcher";
import { GetConfig } from "../../utils/ConfigHelper";
import { FeatureEnabled } from "../../utils/FeaturesHelper";
import NodesActions from "./NodesActions";
import ProvisionersActions from "./ProvisionersActions";

const NodesActionsContainer = () => {
  const { queryFn, queryKey } = GetCaConfiguration();
  const { queryFn: queryFnDeployment, queryKey: queryKeyDeployment } = GetCaConfigurationDeployment();
  const [ownerName, setOwnerName] = useState<string | undefined>(undefined);
  const [podNamespace, setPodNamespace] = useState<string | undefined>(undefined);
  const [scrollToNodeActions, setScrollToNodeActions] = useQueryParam("scrollToNodeActions", BooleanParam);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { data } = useQuery({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
    refetchOnWindowFocus: false,
  });

  const { data: deploymentData, isLoading: deploymentIsLoading } = useQuery<
    GetCaConfigurationDeploymentResponse,
    Error
  >({
    queryKey: [queryKeyDeployment, ownerName, podNamespace],
    queryFn: () =>
      queryFnDeployment({
        name: ownerName,
        namespace: podNamespace,
      }),
    enabled: !!ownerName && !!podNamespace,
  });

  useEffect(() => {
    if (scrollToNodeActions) containerRef?.current?.scrollIntoView();

    const timeoutID = setTimeout(() => {
      if (scrollToNodeActions) {
        setScrollToNodeActions(undefined);
      }
    }, 200);

    return () => clearTimeout(timeoutID);
  }, [containerRef, scrollToNodeActions]);

  useEffect(() => {
    if (data) {
      setOwnerName(data.ownerName);
      setPodNamespace(data.podNamespace);

      if (deploymentData && !deploymentData.ownerName && !deploymentData.podNamespace) {
        deploymentData.ownerName = data.ownerName;
        deploymentData.podNamespace = data.podNamespace;
      }
    }
  }, [data, deploymentData]);

  if (FeatureEnabled("ShowNodeSimulation")) return null;

  // display if:
  // 1. CAS found OR
  // 2. cloudProvider is Azure && we're not on prem
  const showNodeActions =
    data?.caFound ||
    ((data?.cloudProvider?.toLowerCase() === "azure" || data?.cloudProvider?.toLowerCase() === "gcp") &&
      !GetConfig().onPrem);

  return (
    <div ref={containerRef}>
      <ProvisionersActions />
      {showNodeActions && (
        <NodesActions isLoading={deploymentIsLoading && !data} data={deploymentData} rawConfigurationData={data} />
      )}
    </div>
  );
};

export default NodesActionsContainer;
