import { Dialog, ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import RolesIcon from "../../Icons/RolesIcon";
import Button from "../../components/Button";
import SingleSelect from "../../components/SingleSelect";
import FormInput from "../../components/formComponents/FormInput";
import FormMultiSelect from "../../components/formComponents/FormMultiSelect";
import FormSingleSelect from "../../components/formComponents/FormSingleSelect";
import { GetConfig } from "../../utils/ConfigHelper";
import { camelCaseToSnakeSpace, capitalizeFirstLetter } from "../../utils/formatterUtils";
import { documentationUrl } from "../../utils/urlUtils";
import { FilterType, Operators } from "./NamespaceRules";
import { getRoleDescription } from "./Permission/utils";
import getNamespacesAndLabels from "./getNamespacesAndLabels";
import useCreateRole from "./mutations/useCreateRole";

const renderValueWithAllOption = (selected: (string | undefined)[]) => {
  if (selected.length === 0) {
    return "All";
  }
  return `${selected?.join(", ")}`;
};

const HAS_TAGS_FILTER = false;
const PARAGRAPH_CLASS_NAME = "gap-2 items-start";
const SPAN_STYLE = {
  fontWeight: 600,
};
interface Props {
  tags: string[];
  clusters: string[];
  isLoading?: boolean;
  groups?: boolean;
}

const AddRole = ({ tags, clusters, isLoading, groups }: Props) => {
  const { namespaces, labelsByKey } = getNamespacesAndLabels();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState<FilterType>(FilterType.All);

  const createRole = useCreateRole();

  const handleClose = () => {
    setIsOpen(false);
  };

  const config = GetConfig();

  return (
    <div>
      <Button
        className={clsx({ "hover:bg-[#4A154B]": !isLoading })}
        onClick={() => {
          setIsOpen(true);
        }}
        label={
          <Typography className="flex gap-2 items-center" variant="body1">
            <RolesIcon />
            {config.inCloudMode ? "Invite user" : (groups ? "Add group" : "Add user")}
          </Typography>
        }
        disabled={isLoading}
      />
      <Dialog open={isOpen} onClose={handleClose}>
        <div className="p-4 w-[600px] relative flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <Typography className="flex gap-2 items-center" variant="body1" fontWeight={600}>
              <RolesIcon />
              {config.inCloudMode ? "Invite user" : "Add permission rule"}
            </Typography>
            <Typography className="" variant="body2">
              {config.inCloudMode ? (
                <Typography variant="body2">
                  Invite a <b>new user</b> to your organization by entering their email address.
                  <br />
                  The invited user will receive an email with a link to <b>create an account.</b>
                </Typography>
              ) : (
                "Add your customized rules by including groups, roles, clusters and tags."
              )}
            </Typography>
          </div>

          <Typography variant="caption">
            {config.inCloudMode ? (
              <></>
            ) : (
              <>
                <p>
                  <b>Role types</b>
                </p>
                <ul className="list-disc ml-[20px]">
                  <li className={PARAGRAPH_CLASS_NAME}>
                    <span style={SPAN_STYLE}>Admin:</span> {getRoleDescription("Admin")}
                  </li>
                  <li className={PARAGRAPH_CLASS_NAME}>
                    <span style={SPAN_STYLE}>Operator:</span> {getRoleDescription("Operator")}
                  </li>
                  <li className={PARAGRAPH_CLASS_NAME}>
                    <span style={SPAN_STYLE}>Viewer:</span> {getRoleDescription("Viewer")}
                  </li>
                </ul>
                <br />
              </>
            )}
            <p>
              For more details please visit our{" "}
              <a
                href={documentationUrl({
                  path: config?.inCloudMode
                    ? "sso-and-login-security/roles-management"
                    : "sso-and-login-security/user-management",
                  token: config.token,
                })}
                target="_blank"
                rel="noreferrer"
                className="text-main-linkBlue hover:underline"
              >
                {config?.inCloudMode ? <b>Roles Management docs</b> : <b>User Management docs</b>}
              </a>
              .
            </p>
          </Typography>
          <Formik
            initialValues={{
              groupName: undefined,
              role: config.inCloudMode ? "Admin" : undefined,
              targetClusters: undefined,
              targetTags: undefined,
              namespaceNames: undefined,
              key: undefined,
              operator: undefined,
              values: undefined,
            }}
            validationSchema={Yup.object({
              groupName: config.inCloudMode
                ? Yup.string().email("Invalid email").required("Required")
                : Yup.string().min(1, "group must be 1 character or more character").required("Required"),
              role: Yup.string().min(1, "group must be 1 character or more character").required("Required"),
              targetClusters: Yup.array().of(
                Yup.string().min(1, "group must be 1 character or more character").required("Required")
              ),
              targetTags: Yup.array().of(
                Yup.string().min(1, "group must be 1 character or more character").required("Required")
              ),
              namespaceNames: Yup.array().of(Yup.string().min(1, "group must be 1 character or more character")),
              key: Yup.string(),
              operator: Yup.string(),
              values: Yup.array().of(Yup.string()),
            })}
            onSubmit={(values) => {
              const valuesForMutation = {
                groupName: values.groupName,
                role: values.role,
                user: !groups,
                targetClusters: values.targetClusters,
                targetTags: values.targetTags,
                targetNamespaces: [
                  {
                    namespaceNames: values.namespaceNames,
                    labelSelector: {
                      matchExpressions: values.key
                        ? [
                            {
                              key: values.key,
                              operator: values.operator,
                              values: values.values,
                            },
                          ]
                        : [],
                    },
                  },
                ],
              };

              createRole.mutate(valuesForMutation);
              handleClose();
            }}
          >
            {(formik) => {
              useEffect(() => {
                formik.validateForm();
              }, []);

              return (
                <Form>
                  <div className="flex flex-col gap-6 relative">
                    <FormInput
                      label={config.inCloudMode ? "Email" : (groups ? "Group name" : "User Name")}
                      name="groupName"
                      placeholder={config.inCloudMode ? "Enter Email" : (groups ? "Enter group name" : "Enter user name")}
                      type="text"
                      required
                      inputWidth={300}
                    />
                    <FormSingleSelect
                      label="Role"
                      name="role"
                      options={[
                        ["Admin", "Admin"],
                        ["Operator", "Operator"],
                        ["Viewer", "Viewer"],
                      ]}
                      className="w-[300px] relative"
                      required
                    />
                    {!config.inCloudMode && (
                      <FormMultiSelect
                        label="Allowed clusters"
                        name="targetClusters"
                        placeholder="Select cluster"
                        options={clusters}
                        className="w-[300px]"
                        renderValue={renderValueWithAllOption}
                        required
                      />
                    )}
                    {HAS_TAGS_FILTER && (
                      <FormMultiSelect
                        label="Target tags"
                        name="targetTags"
                        placeholder="Select tag"
                        options={tags}
                        className="w-[300px]"
                        required
                      />
                    )}
                    {!config.inCloudMode && (
                      <SingleSelect<string>
                        label={
                          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
                            Allowed namespaces
                          </Typography>
                        }
                        className="w-[300px]"
                        selected={selectedFilterType}
                        setSelected={(option) => {
                          setSelectedFilterType(option as FilterType);
                        }}
                        renderOptionsFunc={() =>
                          Object.values(FilterType).map((option) => {
                            return (
                              <MenuItem value={option} key={option}>
                                <ListItemText
                                  primary={capitalizeFirstLetter(option)}
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                />
                              </MenuItem>
                            );
                          })
                        }
                      />
                    )}
                    {selectedFilterType === FilterType.ByNamespace && namespaces && (
                      <FormMultiSelect
                        label="Namespace names"
                        name="namespaceNames"
                        placeholder="Select namespace"
                        options={namespaces}
                        className="w-[300px]"
                        renderValue={renderValueWithAllOption}
                      />
                    )}
                    {selectedFilterType === FilterType.ByLabel && labelsByKey && (
                      <>
                        <FormSingleSelect
                          label="Key"
                          name="key"
                          placeholder="Select key"
                          options={Object.keys(labelsByKey ?? {}).map((option) => [option, option])}
                          className="w-[300px]"
                          required
                        />
                        <FormSingleSelect
                          label="Operator"
                          name="operator"
                          placeholder="Select operator"
                          options={Object.values(Operators).map((option) => [camelCaseToSnakeSpace(option), option])}
                          className="w-[300px]"
                          required
                        />
                        {formik.values.operator !== Operators.Exists &&
                          formik.values.operator !== Operators.DoesNotExist && (
                            <FormMultiSelect
                              label="Values"
                              name="values"
                              placeholder="Select value"
                              options={formik.values.key ? labelsByKey[formik.values.key] : []}
                              className="w-[300px]"
                              required
                            />
                          )}
                      </>
                    )}

                    <div className={"flex justify-end gap-2 mt-10"}>
                      <Button type="button" label="Cancel" onClick={handleClose} variant="regular" />
                      <Button
                        type="submit"
                        label="Save"
                        disabled={!(formik.isValid && formik.dirty)}
                        variant="regular"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
};

export default AddRole;
