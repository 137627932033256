import FormInput from "../../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../../components/formComponents/FormTitleAndDescription";
import { cpuQuantityToScalar, memoryQuantityToScalar, PolicyType } from "./utils";
import PolicyValue from "./PolicyValue";

interface Props {
  selectedPolicy?: PolicyType;
}

const MaximumResourceBoundaries = ({ selectedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Maximum resource requests boundaries"
        description="Determine the maximum CPU and memory requests."
      />
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col gap-1">
          <FormInput
            label="Max CPU"
            name="cpuMaxAllowed"
            type="number"
            errorLabelClassName="w-[350px]"
            placeholder="No override"
          />
          <PolicyValue
            value={
              cpuQuantityToScalar(
                selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.maxAllowed
              ) ?? 0
            }
          />
        </div>
        <div className={"flex flex-col gap-1"}>
          <FormInput
            label="Max memory (GiB)"
            name="memoryMaxAllowed"
            type="number"
            errorLabelClassName="w-[350px]"
            placeholder="No override"
          />
          <PolicyValue
            value={
              memoryQuantityToScalar(
                selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.maxAllowed
              ) ?? 0
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MaximumResourceBoundaries;
