import FormTitleAndDescription from "../../../../../components/formComponents/FormTitleAndDescription";
import FormSingleSelect from "../../../../../components/formComponents/FormSingleSelect";
import { getDisabledByKeepRequestTooltipContent, NO_OVERRIDE_NUMERIC_VALUE, PolicyType } from "./utils";
import { useFormikContext } from "formik";
import PolicyValue from "./PolicyValue";

interface Props {
  selectedPolicy?: PolicyType;
}

const HistogramRequestPercentileConfiguration = ({ selectedPolicy }: Props) => {
  const { values } = useFormikContext<{
    keepCpuRequest: number;
    keepMemoryRequest: number;
    historyWindowCpu?: string;
    historyWindowMemory?: string;
  }>();

  const currentHistoryWindowCpu30or90 = values.historyWindowCpu === "720h" || values.historyWindowCpu === "2160h";
  const currentHistoryWindowMemory30or90 =
    values.historyWindowMemory === "720h" || values.historyWindowMemory === "2160h";

  return (
    <div className="flex flex-col gap-4">
      <FormTitleAndDescription
        title="Histogram request percentile"
        description="Define how close should ScaleOps be to the usage that measured."
      />
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col gap-1">
          <FormSingleSelect
            label="CPU Request (%)"
            name="histogramCPUPercentile"
            options={[
              ["No override", NO_OVERRIDE_NUMERIC_VALUE],
              ["80%", 80],
              ["85%", 85],
              ["90%", 90],
              ["95%", 95],
              ["99%", 99],
              ["100% (MAX)", 100],
            ]}
            disabled={values.keepCpuRequest > 0}
            tooltipContent={values.keepCpuRequest > 0 ? getDisabledByKeepRequestTooltipContent("CPU") : undefined}
            additionalOnChange={(value) => {
              if (value !== 100 && currentHistoryWindowCpu30or90) {
                values.historyWindowCpu = "24h";
              }
            }}
          />
          <PolicyValue
            value={`${
              selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.percentilePercentage ?? ""
            }%`}
          />
        </div>
        <div className={"flex flex-col gap-1"}>
          <FormSingleSelect
            label="Memory Request (%)"
            name="histogramMemoryPercentile"
            options={[
              ["No override", NO_OVERRIDE_NUMERIC_VALUE],
              ["80%", 80],
              ["85%", 85],
              ["90%", 90],
              ["95%", 95],
              ["99%", 99],
              ["100% (MAX)", 100],
            ]}
            disabled={values.keepMemoryRequest > 0}
            tooltipContent={values.keepMemoryRequest > 0 ? getDisabledByKeepRequestTooltipContent("memory") : undefined}
            additionalOnChange={(value) => {
              if (value !== 100 && currentHistoryWindowMemory30or90) {
                values.historyWindowMemory = "24h";
              }
            }}
          />
          <PolicyValue
            value={`${
              selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.percentilePercentage ?? ""
            }%`}
          />
        </div>
      </div>
    </div>
  );
};

export default HistogramRequestPercentileConfiguration;
