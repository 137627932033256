import useGetGraphState from "./useGetGraphsState";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { CATEGORY_OPTION, NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME } from "../Analytics/AnalyticsV2/NumberOfElementsFilter";

const useGetCategoryGraphState = () => {
  const {
    date,
    setDate,
    aggregationView,
    frozenAndClickableTab,
    enableHpaOptimization,
    tooltipTrigger,
    disabledZoom,
    updateActiveTooltips,
    onDragStart,
    onDragStop,
  } = useGetGraphState();
  const [numberOfElements] = useQueryParam(
    NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME,
    withDefault(NumberParam, CATEGORY_OPTION)
  );
  return {
    date,
    setDate,
    aggregationView,
    numberOfElements,
    frozenAndClickableTab,
    enableHpaOptimization,
    tooltipTrigger,
    disabledZoom,
    updateActiveTooltips,
    onDragStart,
    onDragStop,
  };
};
export default useGetCategoryGraphState;
