import { useEffect, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";
import AccordionSection from "../../components/AccordionSection";
import SelectDashboard from "../../components/Dashboard/SelectDashboard";
import SaveAndDuplicateButtons from "../../components/GridLayout/SaveAndDuplicateButtons";
import MultiSelect from "../../components/MultiSelect";
import RangePicker, { RangeOptions } from "../../components/RangePicker/RangePicker";
import VerticalSeparator from "../../components/VerticalSeparator";
import TopKFilters from "../Analytics/AnalyticsV2/Graphs/TopKFilters";
import NumberOfElementsFilter from "../Analytics/AnalyticsV2/NumberOfElementsFilter";
import SelectAggregationView from "../Analytics/AnalyticsV2/SelectAggregationView";
import { GraphDisplayName } from "./Graphs/utils";
import { categoryOptions } from "./categoryOptions";
import useGetGraphState from "./useGetGraphsState";
import { CustomDashboard, TROUBLESHOOT_DASHBOARD_CATEGORY } from "./utils";

const SHOW_NUMBER_OF_ELEMENTS_FILTER = false;

const DEFAULT_DASHBOARDS = Object.entries(CustomDashboard).map((option) => {
  return [option[0], option[1]];
});

interface Props {
  selectedElements: (string | undefined)[];
  setSelectedElements: (selectedElements: (string | undefined)[]) => void;
  saveDashboard: () => void;
  wasLayoutChanged: boolean;
  setIsDuplicateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  setIsCreateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  existingDashboardNames: string[];
  deleteDashboard: (dashboardName: string) => void;
  isReadOnly?: boolean;
  isMultiCluster?: boolean;
}

const TroubleshootTopSection = ({
  selectedElements,
  setSelectedElements,
  saveDashboard,
  wasLayoutChanged,
  setIsDuplicateDialogueOpen,
  setIsCreateDialogueOpen,
  existingDashboardNames,
  deleteDashboard,
  isReadOnly,
  isMultiCluster,
}: Props) => {
  const { date, setDate } = useGetGraphState();

  const [namespacesFilter] = useQueryParam("namespaces", ArrayParam);
  const [labelsFilter] = useQueryParam("labels", ArrayParam);
  const [annotationsFilter] = useQueryParam("annotations", ArrayParam);
  const [isFiltersOpenOnMount, setIsFiltersOpenOnMount] = useState<boolean>(false);

  useEffect(() => {
    setIsFiltersOpenOnMount(!!namespacesFilter || !!labelsFilter || !!annotationsFilter);
  }, [namespacesFilter, labelsFilter, annotationsFilter]);

  return (
    <div className="w-full rounded-lg bg-white px-8 py-5 flex flex-col">
      <div className="w-full rounded-lg flex gap-4">
        <div className="w-full flex gap-4 items-center">
          <SelectDashboard
            setIsDuplicateDialogueOpen={setIsDuplicateDialogueOpen}
            setIsCreateDialogueOpen={setIsCreateDialogueOpen}
            existingDashboardNames={existingDashboardNames}
            deleteDashboard={deleteDashboard}
            defaultDashboards={DEFAULT_DASHBOARDS}
            defaultDashboard={CustomDashboard.Performance}
            dashBoardCategory={TROUBLESHOOT_DASHBOARD_CATEGORY}
          />
          <MultiSelect
            label="Charts"
            selected={selectedElements}
            setSelected={setSelectedElements}
            categoryOptions={categoryOptions}
            modifyOptionText={(option) => GraphDisplayName[option as keyof typeof GraphDisplayName]}
            width={200}
            className="h-[2.5rem]"
            showNumberOfSelectedValue
            hasSelectAllAndClearSelection={false}
            isSearchable={true}
          />
          <VerticalSeparator />
          {SHOW_NUMBER_OF_ELEMENTS_FILTER && <NumberOfElementsFilter />}
          {!isMultiCluster && <SelectAggregationView hasLabel />}
        </div>
        <div className="flex items-center gap-4">
          <VerticalSeparator />
          <SaveAndDuplicateButtons
            saveDashboard={saveDashboard}
            wasLayoutChanged={wasLayoutChanged}
            setIsDuplicateDialogueOpen={setIsDuplicateDialogueOpen}
            isReadOnly={isReadOnly}
          />
          <VerticalSeparator />
          <div>
            <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.SHORT} minDateRangeInDays={14} />
          </div>
        </div>
      </div>
      <AccordionSection
        title="Filters"
        titleVariant="caption"
        className="py-2 mb-[-20px] px-[0px]"
        childrenWrapperClassName="mt-[0.25rem]"
        openOnMount={isFiltersOpenOnMount}
      >
        <TopKFilters isMultiCluster={isMultiCluster} />
      </AccordionSection>
    </div>
  );
};

export default TroubleshootTopSection;
