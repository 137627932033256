import FormInput from "../../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../../components/formComponents/FormTitleAndDescription";
import {
  GetEphemeralStorageRecommendation,
  GetEphemeralStorageRecommendationResponse,
} from "../../../../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { ephemeralStorageQuantityToScalar } from "./utils";
import Button from "../../../../../components/Button";
import Tooltip from "../../../../../components/Tooltip";
import { useFormikContext } from "formik";

interface Props {
  namespace: string;
  name: string;
  containerName: string;
}

const EphemeralStorage = ({ namespace, name, containerName }: Props) => {
  const [recommendedEphemeralStorage, setRecommendedEphemeralStorage] = useState<number | undefined>(undefined);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const { setFieldValue } = useFormikContext<{ [key: string]: number | undefined }>();
  const { queryFn, queryKey } = GetEphemeralStorageRecommendation();
  const { data, isLoading } = useQuery<GetEphemeralStorageRecommendationResponse, Error>({
    queryKey: [queryKey, namespace, name, containerName],
    queryFn: () => queryFn({ namespace, name, containerName }),
    enabled: isQueryEnabled,
  });

  useEffect(() => {
    if (isQueryEnabled) {
      setIsQueryEnabled(false);
    }

    let ephemeralStorage = (data as GetEphemeralStorageRecommendationResponse)?.value;
    ephemeralStorage = ephemeralStorage ? ephemeralStorageQuantityToScalar(ephemeralStorage.toString()) : undefined;
    ephemeralStorage = ephemeralStorage ? Math.ceil(ephemeralStorage) : undefined;
    setRecommendedEphemeralStorage(ephemeralStorage);
  }, [data, isQueryEnabled]);

  const handleSetValue = () => {
    setFieldValue(containerName, recommendedEphemeralStorage);
  };

  return (
    <div className="flex flex-col gap-4 pb-2">
      <div className="flex gap-2">
        Container: <FormTitleAndDescription title={containerName} />
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col gap-1">
          <FormInput label="Ephemeral Storage (MiB)" name={containerName} type="number" placeholder="No override" />
          <Typography
            variant="body2"
            className={"flex gap-1 px-[1px] text-text-lightBlack font-xs items-center"}
            style={{ fontSize: "0.75rem" }}
          >
            recommended value:
            {isLoading ? (
              <CircularProgress size={10}></CircularProgress>
            ) : (
              <div className="flex gap-2 items-center">
                <div className="flex flex-col font-bold">{recommendedEphemeralStorage ?? "none"}</div>
                {!!recommendedEphemeralStorage && (
                  <Tooltip title="Set recommended value" maxWidth={500} className="flex items-center gap-1">
                    <Button label="set" type="button" variant={"extraSmall"} fontSize={11} onClick={handleSetValue} />
                  </Tooltip>
                )}
              </div>
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EphemeralStorage;
