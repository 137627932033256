import { getWorkloadRecommendation, GetWorkloadRecommendationResponse } from "../../../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import CustomizedIcon from "../../../../Icons/CustomizedIcon";
import SelectPolicy from "../SelectPolicy";
import { Policy } from "../utils";
import Tab from "../../../../components/Tab";
import PolicyTab from "./PolicyTab/PolicyTab";
import EphemeralStorageTab from "./EphemeralStorage/EphemeralStorageTab";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

enum Tabs {
  POLICY = "Policy",
  EPHEMERAL_STORAGE = "Ephemeral Storage",
}

interface Props {
  namespace: string;
  name: string;
  kind: string;
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: (policy: Policy | undefined) => void;
  smartPolicyName?: string;
}

const WorkloadOverrides = ({ namespace, name, kind, selectedPolicy, setSelectedPolicy, smartPolicyName }: Props) => {
  const [selectedTab, setSelectedTab] = useQueryParam("overridesTab", withDefault(StringParam, Tabs.POLICY));
  const [successfulSave, setSuccessfulSave] = useState(false);

  const { queryFn, queryKey } = getWorkloadRecommendation();
  const { data, isLoading: isRecommendationLoading } = useQuery<GetWorkloadRecommendationResponse, Error>({
    queryKey: [queryKey, namespace, name, kind, successfulSave],
    queryFn: () => queryFn({ namespace, name: `${kind.toLowerCase()}-${name}` }),
  });

  const recommendation = (data as GetWorkloadRecommendationResponse)?.recommendation;

  return (
    <div className={"flex flex-col"} style={{ minHeight: "inherit" }}>
      <div className="flex border border-border rounded p-4 items-center gap-10 min-h-[102px]">
        <CustomizedIcon width={30} height={30} />
        <div>
          <b>Override recommendation</b>
          <Typography variant="body2">
            <p>Customize this workload's recommendation parameters</p>
          </Typography>
        </div>
        {selectedTab === Tabs.POLICY && (
          <div className="ml-auto border-border border-l pl-10">
            <SelectPolicy
              selectedPolicy={selectedPolicy}
              setSelectedPolicy={setSelectedPolicy}
              smartPolicyName={smartPolicyName}
              isOverridePolicy={false}
              disabled
            />
          </div>
        )}
      </div>
      {isRecommendationLoading && (
        <div className="flex m-auto items-center justify-center grow h-full w-full">
          <CircularProgress />
        </div>
      )}

      {!isRecommendationLoading && (
        <>
          <div className="flex mt-5 -mb-[1px] z-10">
            {Object.entries(Tabs).map(([key, value]) => (
              <Tab
                key={key}
                name={value}
                onClick={() => setSelectedTab(value as Tabs)}
                isSelected={value === selectedTab}
                hasDefaultGrayBorders
                hasSelectedUnderline
              />
            ))}
          </div>
          {selectedTab === Tabs.POLICY && (
            <PolicyTab
              namespace={namespace}
              name={name}
              kind={kind}
              isLoading={isRecommendationLoading}
              overridePolicy={recommendation?.spec?.overridePolicies}
              selectedPolicy={selectedPolicy}
              setSuccessfulSave={setSuccessfulSave}
            />
          )}
          {selectedTab === Tabs.EPHEMERAL_STORAGE && (
            <EphemeralStorageTab
              namespace={namespace}
              name={name}
              kind={kind}
              setSuccessfulSave={setSuccessfulSave}
              containers={recommendation?.status?.rightSize?.containers}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WorkloadOverrides;
