import { ListItemText, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CodeSnippet, { THEME as CodeSnippetTheme } from "../../../components/CodeSnippet";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";
import { GetConfig } from "../../../utils/ConfigHelper";
import { capitalizeFirstLetter } from "../../../utils/formatterUtils";
import useGetVersion from "../../Cost/hooks/useGetVersion";
import PermissionsDocsLink from "../Permission/PermissionsDocsLink";
import { getIntegrationCommand } from "./commandsUtils";
import {AuthMethod, SSOIntegration, SSOIntegrationDocLink, SSOConf, getSSOIntegrationFromString} from "./utils";
import AuthConfForm from "./AuthConfForm";

export const HAS_ROLES = true;

const DockLink = ({ selectedIntegration }: { selectedIntegration: SSOIntegration }) => (
  <PermissionsDocsLink
    relativePath={SSOIntegrationDocLink[selectedIntegration]}
    linkTitle={`${capitalizeFirstLetter(selectedIntegration)} docs`}
  />
);


interface SSOContainerProps {
  currentAuthMethod: AuthMethod;
  updateProvider: (provider: string) => void;
  ssoSetupConf?: SSOConf;
}

const SSOWithAuthorization = ({ currentAuthMethod, updateProvider, ssoSetupConf }: SSOContainerProps) => {
  const isCurrentAuthMethod = currentAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION;
  const navigate = useNavigate();
  const conf = GetConfig();

  const [selectedIntegration, setSelectedIntegration] = useState<SSOIntegration>(getSSOIntegrationFromString(conf?.ssoConf?.providerName || SSOIntegration.Okta) as SSOIntegration);
  const { data: versionData } = useGetVersion();

  useEffect(() => {
    if (!HAS_ROLES) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    updateProvider(selectedIntegration);
  }, [selectedIntegration]);

  return (
    <>
      <div className={"flex flex-col gap-4"}>
        <CodeSnippet
          description={
            <div className="flex flex-col gap-4">
              <Typography variant="body2" className="text-text-lightBlack">
                Configure <b>SSO authentication</b> and <b>authorization</b> on your cluster.
              </Typography>
              <div className="flex gap-2 items-center">
                <Typography variant="caption" className="text-text-lightBlack">
                  SSO integration:
                </Typography>
                <SingleSelect<SSOIntegration>
                  selected={selectedIntegration}
                  setSelected={(option) => {
                    setSelectedIntegration(option);
                  }}
                  renderOptionsFunc={() =>
                    Object.values(SSOIntegration).map((option) => {
                      return (
                        <MenuItem value={option} key={option}>
                          <ListItemText
                            primary={capitalizeFirstLetter(option)}
                            sx={{
                              fontSize: "12px",
                            }}
                          />
                        </MenuItem>
                      );
                    })
                  }
                  size={SingleSelectSize.Small}
                />
              </div>
            </div>
          }
          theme={CodeSnippetTheme.light}
          codeSnippet={getIntegrationCommand({
            currentIntegration: currentAuthMethod,
            selectedIntegration: AuthMethod.SSO_WITH_AUTHORIZATION,
            selectedSsoIntegration: selectedIntegration,
            repository: versionData?.helmCommandConfig?.repositoryURL,
            token: conf.token,
            hideToken: true,
            namespace: versionData?.namespace,
            ssoSetupConf: ssoSetupConf,
          })}
          codeSnippetToCopy={getIntegrationCommand({
            currentIntegration: currentAuthMethod,
            selectedIntegration: AuthMethod.SSO_WITH_AUTHORIZATION,
            selectedSsoIntegration: selectedIntegration,
            repository: versionData?.helmCommandConfig?.repositoryURL,
            token: conf.token,
            namespace: versionData?.namespace,
            ssoSetupConf: ssoSetupConf,
          })}
          className="w-full"
        />
        <div className="flex flex-col">
          <DockLink selectedIntegration={selectedIntegration} />
          <PermissionsDocsLink
            relativePath="/sso-and-login-security/roles-management/"
            linkTitle={`Roles Management docs`}
          />
        </div>
        <AuthConfForm type={selectedIntegration}
                      authorization
                      ssoSetupConf={ssoSetupConf}/>
      </div>
      <div className={"flex flex-col gap-4"}>
        <div>
          <Typography variant="body2" className="text-text-lightBlack">
            {conf.inCloudMode ? (
              <></>
            ) : (
              <>
                {isCurrentAuthMethod ? (
                  <Typography variant="body2" className="text-text-lightBlack">
                    You currently have <b>SSO authentication</b> and <b>authorization</b> configured on your cluster.
                  </Typography>
                ) : (
                  <span>
                    After configuring <b>SSO authentication</b> and <b>authorization</b>, You will be able to manage and
                    control your permissions usings groups, roles, clusters and namespaces.{" "}
                  </span>
                )}
              </>
            )}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SSOWithAuthorization;
