import clsx from "clsx";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SCALEOPS_COLORS } from "../../colors";
import RightArrowIcon from "../../Icons/RightArrowIcon";
import FreezeTooltipWarning from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import LinkableWorkloadChartTooltipElement from "../../pages/Analytics/AnalyticsV2/Graphs/LinkableWorkloadChartTooltipElement";
import { DEFAULT_DATE_TIME_FORMAT } from "../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../utils/styleUtils";
import ChartTooltipItemsCount from "../ChartTooltipItemsCount";
import ChartTooltipTime from "../ChartTooltipTime";
import { ChartData, ChartElement, WASTE_KEY } from "./utils";

export const SUM = "#sum";
export const QUANTILE = "#quantile";
const MAX_ELEMENTS_PER_TOOLTIP = 10;

interface CustomTooltipProps {
  elements: ChartElement[];
  data: ChartData;
  active?: boolean;
  payload?: { value: string | number; name?: string; dataKey: string; stroke: string }[];
  label?: string;
  selectedChartComponents: string[];
  renderNameFunction: (key: string, elements: ChartElement[]) => string;
  valueFormatter?: (tick: number) => string;
  hasItemsCount?: boolean;
  tooltipId?: string;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  enableCopyTextOnClick?: boolean;
  showWaste?: {
    fromKey: string;
    toKey: string;
  };
  isDashedFnc?: (key: string) => boolean;
  hasLimitedTooltipWidth?: boolean;
}

const CustomTooltip = ({
  elements,
  data,
  active,
  payload,
  label,
  selectedChartComponents,
  valueFormatter,
  renderNameFunction,
  hasItemsCount,
  tooltipTrigger,
  tooltipId,
  frozenTooltipType,
  updateActiveTooltips,
  enableCopyTextOnClick,
  showWaste,
  isDashedFnc,
  hasLimitedTooltipWidth,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  if (active && payload && payload.length) {
    let sortedPayload = payload.sort((a, b) => {
      return String(a.name).localeCompare(String(b.name), undefined, { numeric: true });
    });

    // unique sortedPayload by dataKey
    sortedPayload = sortedPayload.filter(
      (item, index, self) => index === self.findIndex((t) => t.dataKey === item.dataKey)
    );

    sortedPayload = sortedPayload.sort((a, b) => {
      if (a?.name === SUM) return -1;
      if (b?.name === SUM) return 1;
      if (a?.name?.includes(QUANTILE)) return -1;
      if (b?.name?.includes(QUANTILE)) return 1;
      return Number(b?.value) - Number(a?.value);
    });

    const numberOfPercentileOrSumElements = sortedPayload.filter(
      (item) => item?.name?.includes(QUANTILE) || item?.name === SUM
    ).length;

    const maxItemsToShow = MAX_ELEMENTS_PER_TOOLTIP + numberOfPercentileOrSumElements;

    const wasteElement =
      showWaste &&
      selectedChartComponents.includes(showWaste.fromKey) &&
      selectedChartComponents.includes(showWaste.toKey)
        ? {
            dataKey: WASTE_KEY,
            name: WASTE_KEY,
            color: SCALEOPS_COLORS.main.waste,
            stroke: SCALEOPS_COLORS.main.waste,
            value:
              Number(sortedPayload.find((item) => item.dataKey === showWaste.fromKey)?.value ?? 0) -
              Number(sortedPayload.find((item) => item.dataKey === showWaste.toKey)?.value ?? 0),
          }
        : undefined;

    if (showWaste && wasteElement) {
      sortedPayload = [...sortedPayload, wasteElement];
    }

    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        {label && <ChartTooltipTime timestamp={label} timeFormat={DEFAULT_DATE_TIME_FORMAT} />}
        {sortedPayload.slice(0, maxItemsToShow).map((item, index) => {
          if (selectedChartComponents.includes(item.dataKey ?? "")) {
            const renderedName = renderNameFunction(String(item.name), elements);

            const subValues = data.find((d) => d.timestamp === label)?.subValues?.[item.dataKey];

            return (
              <>
                <LinkableWorkloadChartTooltipElement
                  key={index}
                  color={item?.stroke ?? ""}
                  value={valueFormatter ? valueFormatter(Number(item.value)) : item.value}
                  label={<div className="max-w-[277px] truncate rtl">{renderedName}</div>}
                  rawLabel={String(item.name)}
                  disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
                  textToCopyOnClick={enableCopyTextOnClick ? renderedName : undefined}
                  isDashed={isDashedFnc ? isDashedFnc(String(item.name)) : false}
                  hasLimitedWidth={hasLimitedTooltipWidth}
                />
                {subValues && (
                  <div className="max-h-[100px] overflow-y-scroll scrollbar-none">
                    {subValues.map((subValue, index) => (
                      <NavLink
                        to={subValue.link ?? ""}
                        key={index}
                        className={clsx("flex items-center gap-1 text-[10px] text-text-lightBlack", {
                          "underline hover:text-main-linkBlue": subValue.link,
                          "cursor-default": !subValue.link,
                        })}
                        onClick={(e) => {
                          if (!subValue?.link) e.preventDefault();
                        }}
                      >
                        <RightArrowIcon className="w-2 h-2 ml-[30px]" />
                        <span className="grow">{subValue.key}</span>
                        <span>{valueFormatter ? valueFormatter(Number(subValue.value)) : subValue.value}</span>
                      </NavLink>
                    ))}
                  </div>
                )}
              </>
            );
          }
        })}
        {hasItemsCount && <ChartTooltipItemsCount count={sortedPayload.length} maxToShow={maxItemsToShow} />}
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
