import { Typography } from "@mui/material";
import RightArrowWithFill from "../Icons/RightArrowWithFill";
import { LIGHT_PURPLE_BLUE } from "../colors";

interface Props {
  content: React.ReactNode;
}

const ArrowLine = ({ content }: Props) => (
  <div className="flex gap-2.5 items-start">
    <div className="h-[20px] flex items-center">
      <RightArrowWithFill width={14} height={14} fill={LIGHT_PURPLE_BLUE} outline="none" />
    </div>
    <Typography variant="caption" sx={{ maxWidth: "500px" }}>
      {content}
    </Typography>
  </div>
);

export default ArrowLine;
