import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import LockedIcon from "../../../../../Icons/LockedIcon";
import { getDuration, getPercentageValue } from "../../../../../utils/formatterUtils";
import { eBPFTableRow } from "../utils";
import MethodCell from "./MethodCell";
import SecureCell from "./SecureCell";

export const columns: GridColDef[] = [
  {
    field: "path",
    headerName: "Path",
    renderCell: (params: GridRenderCellParams<string, eBPFTableRow, string>) => {
      return (
        <div className="flex items-center gap-2">
          <span>{params.value}</span>
          <Typography fontSize={10} className="text-white bg-guideline-darkBlack  px-2 rounded-sm">
            {params.row?.protocol?.toLowerCase() === "https" ? "HTTPS" : "HTTP"}
          </Typography>
        </div>
      );
    },
    type: "string",
    align: "left",
    flex: 2,
    disableColumnMenu: true,
  },
  {
    field: "method",
    headerName: "Method",
    renderCell: (params) => {
      return <MethodCell method={String(params.value)} />;
    },
    type: "string",
    align: "center",
    flex: 1,
    maxWidth: 300,
    disableColumnMenu: true,
  },
  {
    field: "secure",
    hide: true,
    headerName: "Secure",
    renderHeader: () => <LockedIcon />,
    headerAlign: "center",
    renderCell: (params) => {
      return <SecureCell isSecure={Boolean(params.value)} />;
    },
    type: "string",
    align: "center",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "errorRate",
    headerName: "Error Rate",
    renderCell: (params) => getPercentageValue(Number(params.value)),
    type: "number",
    align: "center",
    flex: 1,
    maxWidth: 300,
    disableColumnMenu: true,
  },
  {
    field: "p50Latency",
    headerName: "Latency (P50)",
    renderCell: (params) => getDuration(Number(params.value)),
    type: "number",
    align: "center",
    flex: 1,
    maxWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: "p95Latency",
    headerName: "Latency (P95)",
    renderCell: (params) => getDuration(Number(params.value)),
    type: "number",
    align: "center",
    flex: 1,
    maxWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: "requestsPerSecond",
    headerName: "Requests per Second",
    renderCell: (params) => {
      return <div>{Math.round((params.value ?? 0) * 100) / 100} req/s</div>;
    },
    type: "number",
    align: "center",
    flex: 1,
    maxWidth: 400,
    disableColumnMenu: true,
  },
];
