// import LogIcon from "../../../Icons/LogIcon";
import { Typography } from "@mui/material";
import WorkloadYaml from "./WorkloadYaml";
import WorkloadHpaYaml from "./WorkloadHpaYaml";
import Tab from "../../../components/Tab";
import Tooltip from "../../Tooltip";
import { useEffect, useState } from "react";
import YamlIcon from "../../../Icons/YamlIcon";
import {
  GetWorkloadHpaYaml,
  GetWorkloadHpaYamlParams,
  GetWorkloadHpaYamlResponse,
  GetWorkloadYaml,
  GetWorkloadYamlParams,
  GetWorkloadYamlResponse,
} from "../../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import { ApiError } from "openapi-typescript-fetch";

export enum YamlTabs {
  WorkloadYaml = "Workload Yaml",
  WorkloadHpaYaml = "HPA Yaml",
}

interface Props {
  namespace: string;
  name: string;
  kind: string;
  hasHpa: boolean;
  selectedTab?: YamlTabs;
  setSelectedTab?: (tab: YamlTabs) => void;
}

const WorkloadYamlsTab = ({ namespace, name, kind, hasHpa, selectedTab, setSelectedTab }: Props) => {
  const [workloadYaml, setWorkloadYaml] = useState<string>("");
  const { queryFn: workloadYamlQueryFn, queryKey: workloadYamlQueryKey } = GetWorkloadYaml();
  const {
    data: workloadYamlData,
    isLoading: workloadYamlIsLoading,
    error: workloadYamlError,
  } = useQuery<GetWorkloadYamlResponse, Error>({
    queryKey: [workloadYamlQueryKey, name],
    queryFn: () => workloadYamlQueryFn({ namespace: namespace, name: name, kind: kind } as GetWorkloadYamlParams),
    enabled: !!name,
    refetchInterval: 60 * 1000,
  });

  const [hpaYaml, setHpaYaml] = useState<string>("");
  const { queryFn: hpaYamlQueryFn, queryKey: hpaYamlQueryKey } = GetWorkloadHpaYaml();
  const {
    data: hpaYamlData,
    isLoading: hpaYamlIsLoading,
    error: hpaYamlError,
  } = useQuery<GetWorkloadHpaYamlResponse, Error>({
    queryKey: [hpaYamlQueryKey, name],
    queryFn: () => hpaYamlQueryFn({ namespace: namespace, name: name, kind: kind } as GetWorkloadHpaYamlParams),
    enabled: !!name,
    refetchInterval: 60 * 1000,
  });

  useEffect(() => {
    if (workloadYamlData) {
      setWorkloadYaml(workloadYamlData.yaml ?? "");
    }
  }, [workloadYamlData]);

  useEffect(() => {
    if (hpaYamlData) {
      setHpaYaml(hpaYamlData.yaml ?? "");
    }
  }, [hpaYamlData]);

  useEffect(() => {
    if (!hasHpa && setSelectedTab) {
      setTimeout(() => {
        setSelectedTab(YamlTabs.WorkloadYaml);
      }, 0);
    }
  }, [hasHpa, setSelectedTab]);

  return (
    <div className={"flex flex-col"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <YamlIcon width={38} height={38} />
        <Typography variant="body2">
          <b>YAMLs</b>
          <p>Explore workload & HPA YAML files</p>
        </Typography>
      </div>
      <div className="flex flex-col">
        <div className="flex mt-5 -mb-[1px] z-10">
          {Object.entries(YamlTabs).map(([key, value]) => (
            <Tooltip title={"No HPA was found"} disabled={!(value === YamlTabs.WorkloadHpaYaml && !hasHpa)}>
              <Tab
                key={key}
                name={value}
                onClick={() => setSelectedTab && setSelectedTab(value as YamlTabs)}
                isSelected={value === selectedTab}
                hasDefaultGrayBorders
                hasSelectedUnderline
                disabled={value === YamlTabs.WorkloadHpaYaml && !hasHpa}
              />
            </Tooltip>
          ))}
        </div>
        <div className="border border-border rounded rounded-tl-none p-4">
          {selectedTab === YamlTabs.WorkloadYaml && (
            <WorkloadYaml
              name={name}
              yaml={workloadYaml}
              isLoading={workloadYamlIsLoading}
              error={workloadYamlError as ApiError}
            />
          )}
          {selectedTab === YamlTabs.WorkloadHpaYaml && (
            <WorkloadHpaYaml name={name} yaml={hpaYaml} isLoading={hpaYamlIsLoading} error={hpaYamlError as ApiError} />
          )}
        </div>
      </div>
    </div>
  );
};
export default WorkloadYamlsTab;
