import { GridRenderCellParams } from "@mui/x-data-grid";
import Tooltip from "../Tooltip";
import TooltipList, { TooltipListIcon } from "../TooltipList";
import ExploreWorkloadsMessage, { ExploreEntityMessage } from "./NodeOverview/ExploreWorkloadsMessage";
import { defaultTooltipTitle } from "./NodesBreakdownTab";
import { convertReasonToTextToDisplay, NodeStatsResponseTypeSchema } from "./Utils";

interface Props {
  params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>;
}

const NodeNameCell = ({ params }: Props) => {
  return (
    <div className="w-full flex gap-2 items-center">
      <TooltipList
        iconType={TooltipListIcon.Warning}
        list={params.row.extraWarnBlockingReasons}
        title="Scale down blockers"
        renderListItemFnc={NodeNameBlockingReasonsParsingFunc}
        footer={<ExploreWorkloadsMessage message={ExploreEntityMessage.ExploreNode} />}
      />
      {(!params.row.extraWarnBlockingReasons || !params.row.extraWarnBlockingReasons.length) && (
        <TooltipList
          iconType={TooltipListIcon.Info}
          list={params.row.extraInfoBlockingReasons}
          title="Scale down constraints"
          footer={<ExploreWorkloadsMessage message={ExploreEntityMessage.ExploreNode} />}
        />
      )}
      <p className="truncate w-full">
        <Tooltip maxWidth={770} title={defaultTooltipTitle(params.row.name)}>
          <p className="truncate">{params.row.name}</p>
        </Tooltip>
      </p>
    </div>
  );
};

const NodeNameBlockingReasonsParsingFunc = (option: string) => {
  switch (option) {
    case "PodPlacement-UnevictableWorkload":
      return (
        <>
          Node cannot scale down due to an <b>unevictable</b> workload
        </>
      );
    case "PodPlacement-OptimizedUnevictableWorkload":
      return (
        <>
          Node cannot scale down due to an <b>optimized unevictable</b> workload
        </>
      );
    case "UnevictableWorkloadDueToPdb":
      return (
        <>
          Node cannot scale down due to an <b>unevictable</b> workload caused by <b>PodDisruptionBudget (PDB)</b>
        </>
      );
    case "PodPlacement-PodWithoutOwner":
      return (
        <>
          Node cannot scale down due to an <b>unevictable pod without an owner</b>
        </>
      );
    case "PodPlacement-PodWithLocalStorage":
      return (
        <>
          Node cannot scale down due to an <b>unevictable</b> pod with <b>local storage</b>
        </>
      );
    case "PodWithLocalStorage":
      return (
        <>
          Node cannot scale down due to an <b>unevictable</b> pod with <b>local storage</b>
        </>
      );
    case "UnevictableWorkloadDueToAnnotation":
      return (
        <>
          Node cannot scale down due to an <b>unevictable</b> workload with a <b>blocking annotation</b>
        </>
      );
    case "PodPlacement-KubeSystemWorkloads":
      return (
        <>
          Node cannot scale down due to <b>unevictable</b> workloads in the <b>kube-system namespace</b>
        </>
      );
    case "MinReplicasReached":
      return (
        <>
          Node cannot scale down as the <b>minimum replicas configured in Cluster Autoscaler</b> have been reached,
          preventing eviction of workloads
        </>
      );
    case "PodOwnerReachedToMinimumReplicas":
      return (
        <>
          Node cannot scale down as the <b>minimum replicas configured in Cluster Autoscaler</b> have been reached,
          preventing eviction of workloads
        </>
      );
    case "InsufficientAllocationForDynamicResources":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>insufficient allocation for dynamic resources</b>
        </>
      );
    case "UnsatisfiedPvcRequirements":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>unsatisfied PVC requirements</b>
        </>
      );
    case "UnsatisfiedPvcVolumeRestrictions":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>unsatisfied PVC volume restrictions</b>
        </>
      );
    case "UnsatisfiedPvcZoneRequirements":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>unsatisfied PVC zone requirements</b>
        </>
      );
    case "PodAffinityConstraint":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>pod affinity constraints</b>
        </>
      );
    case "PodAnti-affinityConstraint":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>pod anti-affinity constraints</b>
        </>
      );
    case "NodeAffinityConstraint":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>node affinity constraints</b>
        </>
      );
    case "NodeSelectorConstraint":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>node selector constraints</b>
        </>
      );
    case "UnsatisfiedNodeName":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to an{" "}
          <b>unsatisfied node name constraint</b>
        </>
      );
    case "UnsatisfiedAvailablePort":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>unsatisfied available port requirements</b>
        </>
      );
    case "InsufficientAllocationForResourceRequests":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>insufficient allocation for resource requests</b>
        </>
      );
    case "NodeWithAnUnschedulableTaint":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to a{" "}
          <b>node with an unschedulable taint</b>
        </>
      );
    case "TopologySpreadConstraints":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to{" "}
          <b>topology spread constraints</b>
        </>
      );
    case "NodeWithAnUntoleratedTaint":
      return (
        <>
          Node cannot scale down because it has pods that cannot run on other nodes due to a{" "}
          <b>node with an untolerated taint</b>
        </>
      );
    case "MissingKarpenterNodeLabels":
      return (
        <>
          Node cannot scale down due to <b>missing required Karpenter node labels</b>
        </>
      );
    case "KarpenterNodeAnnotationConstraint":
      return (
        <>
          Node cannot scale down due to a <b>Karpenter node annotation constraint</b>
        </>
      );
    case "InvalidNodePool":
      return (
        <>
          Node cannot scale down because it does not belong to a <b>valid node pool</b>
        </>
      );
    case "KarpenterDisruptionBlocked":
      return (
        <>
          Node cannot scale down due to a <b>Karpenter disruption event</b>
        </>
      );
    case "MissingProvisioner":
      return (
        <>
          Node cannot scale down due to a <b>missing provisioner label</b>
        </>
      );
    case "ProvisionerConsolidationDisabled":
      return (
        <>
          Node cannot scale down because <b>provisioner consolidation is disabled</b>
        </>
      );
    case "EmptinessTimestampMissing":
      return (
        <>
          Node cannot scale down due to a <b>missing emptiness timestamp label</b>
        </>
      );
    case "UnexpectedEmptinessStructure":
      return (
        <>
          Node cannot scale down due to an <b>unexpected value in the emptiness key label</b>
        </>
      );
    case "NodePoolRequiredEmptinessDuration":
      return (
        <>
          Node cannot scale down because it has not met the <b>required emptiness duration for its node pool</b>
        </>
      );
    case "WhenEmptyNodePoolConfiguration":
      return (
        <>
          Node cannot scale down because the node pool has a <b>'when empty' configuration</b>, but the node still has
          running pods
        </>
      );
    case "MissingNodePool":
      return (
        <>
          Node cannot scale down because its <b>node pool label value does not match any defined node pool</b>
        </>
      );
    case "NodePoolConsolidationDisabled":
      return (
        <>
          Node cannot scale down because <b>node pool consolidation is disabled</b>
        </>
      );
    case "NodePoolBudgetConstraint":
      return (
        <>
          Node cannot scale down because the associated node pool does not meet the <b>required ready node count</b>{" "}
          defined by the <b>budget constraint</b>
        </>
      );
    case "NodeOverUtilized":
      return (
        <>
          Node cannot scale down because it is <b>over allocated</b>
        </>
      );
    case "LowClusterAutoscalerScalingDownAllocationThreshold":
      return (
        <>
          Node cannot scale down because it does not meet the{" "}
          <b>Cluster Autoscaler’s scaling-down allocation threshold</b>
        </>
      );
    case "ClusterAutoScalerAnnotationConstraint":
      return (
        <>
          Node cannot scale down due to <b>annotations blocking scale-down</b>
        </>
      );
    case "ReachedMinimumNodeGroupSize":
      return (
        <>
          Node cannot scale down because the <b>user-defined minimum node group size</b> has been reached
        </>
      );
    case "NotUnreadyLongEnough":
      return (
        <>
          Node cannot scale down because <b>Cluster Autoscaler parameter is delaying node scale down</b>
        </>
      );
    case "DeletionInProgress":
      return (
        <>
          Node is already in the process of <b>scaling down</b>
        </>
      );
    case "NodePendingRemoval":
      return (
        <>
          Node is pending removal and will <b>scale down soon</b>
        </>
      );
    case "UninitializedNode":
      return (
        <>
          Node cannot scale down because it is missing the <b>required initialization label</b>
        </>
      );
    case "UnexpectedError":
      return (
        <>
          Node cannot scale down due to an <b>unexpected error</b>
        </>
      );
    case "ScalerLabelConstraint":
      return (
        <>
          Node cannot scale down because it has a <b>label constraint</b> preventing the scale down
        </>
      );
    case "ScalerAnnotationConstraint":
      return (
        <>
          Node cannot scale down because it has an <b>annotation constraint</b> preventing the scale down
        </>
      );
    case "NodeHighAllocation":
      return (
        <>
          Node cannot scale down due to <b>high resource allocation</b>
        </>
      );
    case "NotSafeToEvictLabel":
      return (
        <>
          Node cannot scale down due to <b>label blocking scale-down</b>
        </>
      );

    default:
      return convertReasonToTextToDisplay(option);
  }
};

export default NodeNameCell;
