import { useEffect, useState } from "react";
import { SCALEOPS_COLORS } from "../../../../../colors";
import ComposeChart from "../../../../../components/ComposeChart/ComposeChart";
import { ChartData, ChartElement, SetDate, YAxisTickFormatterType } from "../../../../../components/ComposeChart/utils";
import { WORKLOAD_EBPF_CHARTS_SYNC_ID, XAxisIntervalBase } from "./utils";

interface Props {
  graphData: ChartData;
  isLoading: boolean;
  setDate: SetDate;
}

const ErrorChart = ({ graphData, isLoading, setDate }: Props) => {
  const [elements, setElements] = useState<ChartElement[]>([]);

  useEffect(() => {
    if (graphData) {
      const uniqueKeys = [...new Set(graphData.flatMap((el) => Object.keys(el.values)))].filter(
        (key) => key !== "timestamp"
      );

      const elementsToSet: ChartElement[] = uniqueKeys.map((key, index) => {
        return {
          key,
          color: SCALEOPS_COLORS.errors[index],
          name: key,
        };
      });

      setElements(elementsToSet);
    } else {
      setElements([]);
    }
  }, [graphData]);

  return (
    <ComposeChart
      data={graphData}
      title={
        <>
          Errors <span className="text-text-darkGray text-[0.625rem]"> (err/s)</span>
        </>
      }
      isLoading={isLoading}
      elements={elements}
      yAxisTickFormatterType={YAxisTickFormatterType.ErrorsPerSecond}
      XAxisIntervalBase={XAxisIntervalBase}
      syncId={WORKLOAD_EBPF_CHARTS_SYNC_ID}
      setDate={setDate}
      yAxisDomain={[0, "dataMax"]}
    />
  );
};

export default ErrorChart;
