import FormInput from "../../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../../components/formComponents/FormTitleAndDescription";
import PolicyValue from "./PolicyValue";
import { cpuQuantityToScalar, memoryQuantityToScalar, PolicyType } from "./utils";

interface Props {
  selectedPolicy?: PolicyType;
}

const MinimumResourceBoundaries = ({ selectedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 pb-2">
      <FormTitleAndDescription
        title="Minimum resource requests boundaries"
        description="Determine the minimum CPU and memory requests."
      />
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col gap-1">
          <FormInput label="Min CPU" name="cpuMinAllowed" type="number" placeholder="No override" />
          <PolicyValue
            value={
              cpuQuantityToScalar(
                selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.minAllowed
              ) ?? 0
            }
          />
        </div>
        <div className={"flex flex-col gap-1"}>
          <FormInput label="Min memory (GiB)" name="memoryMinAllowed" type="number" placeholder={"No override"} />
          <PolicyValue
            value={
              memoryQuantityToScalar(
                selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.minAllowed
              ) ?? ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MinimumResourceBoundaries;
