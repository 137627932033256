import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { ArrayParam, ObjectParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetPolicyTuningConfigParamsResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import BetaChip from "../../../components/BetaChip";
import Dialog from "../../../components/Dialog";
import SnapshotWrapper from "../../../components/SnapshotWrapper";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import WorkloadEvents from "../../../components/WorkloadStatusByNamespaceSection/WorkloadEvents";
import WorkloadYamlsTab, {
  YamlTabs,
} from "../../../components/WorkloadStatusByNamespaceSection/WorkloadYamls/WorkloadYamlsTab";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespaceSection/useHpaOptimizationEnabled";
import {
  HAS_GPU_SUPPORT,
  HAS_NEW_TROUBLESHOOT_DESIGN,
  HAS_WORKLOAD_HISTORY_EVENTS_CHART,
  HAS_WORKLOAD_OBSERVABILITY,
} from "../../../utils/developmentFeatureFlags";
import { getDisplayWorkloadName } from "../../../utils/namesUtils";
import { enableFilterByUrlParam, FilterByUrlParam } from "../../../utils/queryParams";
import { getWorkloadType, ScaleOpsProduct } from "../../../utils/typesUtils";
import { SELECTED_CONTAINER_KEY } from "./ContainerFilter";
import PolicyTuningContainer from "./PolicyTuningContainer";
import PolicyTuningDialogueTitle from "./PolicyTuningDialogueTitle";
import PolicyTuningHpa from "./PolicyTuningHpa";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";
import WorkloadAnalytics from "./WorkloadAnalytics/WorkloadAnalytics";
import WorkloadHistoryEventsContainer from "./WorkloadHistoryEvents/WorkloadHistoryEventsContainer";
import WorkloadNetwork from "./WorkloadNetwork/WorkloadNetwork";
import WorkloadObservabilityContainer from "./WorkloadObservabilityContainer/WorkloadObservabilityContainer";
import WorkloadOverrides from "./WorkloadOverrides/WorkloadOverrides";
import WorkloadTroubleshootContainer from "./WorkloadTroubleshoot/WorkloadTroubleshootContainer";
import {
  ChartComponents,
  Policy,
  POLICY_TUNING_DATES_URL_PARAM,
  PolicyTuningTabs,
  useViewPeriodQueryParams,
  ViewPeriodOptions,
} from "./utils";

export const POLICY_TUNING_SELECTED_TAB_QUERY_KEY = "policyTuningSelectedTab";
const HAS_WORKLOAD_ANALYTICS_TAB = true;
const HAS_WORKLOAD_NETWORK_TAB = true;
const MIN_TAB_CONTENT_HEIGHT = 608;

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setSelectedWorkload: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  fetchWorkloads: () => void;
  hideWorkloadSuffix?: boolean;
  displayName?: string;
  scaleOpsProduct: ScaleOpsProduct | undefined;
}

const setWorkloadName = (
  displayName: string,
  name: string,
  type: string,
  namespace: string,
  hideWorkloadSuffix: boolean
) => {
  let workloadDisplayName = displayName ?? name;
  let workloadType;
  switch (type) {
    case "kedascaledjob":
      workloadType = "KedaScaledJob";
      break;
    case "cronjob":
      workloadType = "CronJob";
      break;
    default:
      workloadType = type;
  }
  workloadType = getWorkloadType(workloadDisplayName, workloadType, false) || workloadType;
  workloadDisplayName = getDisplayWorkloadName(workloadDisplayName, hideWorkloadSuffix);
  return getDisplayWorkloadName(`${workloadType}: ${namespace}/${workloadDisplayName}`, hideWorkloadSuffix);
};

const PolicyTuning = ({
  selectedWorkload,
  isOpen,
  onClose,
  fetchWorkloads,
  hideWorkloadSuffix,
  displayName,
  setSelectedWorkload,
  scaleOpsProduct,
}: Props) => {
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();
  const ENABLE_HPA_RECOMMENDATION =
    enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION) && selectedWorkload.hasHpa;
  const HAS_HPA_TAB = ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled;

  const [isRightsizeAutomate, setIsRightsizeAutomate] = useState<boolean>(
    scaleOpsProduct === ScaleOpsProduct.HPA ? Boolean(selectedWorkload.rightsizeAuto) : Boolean(selectedWorkload.auto)
  );
  const [isHPAAutomate, setIsHPAAutomate] = useState<boolean>(
    scaleOpsProduct === ScaleOpsProduct.HPA ? Boolean(selectedWorkload.auto) : Boolean(selectedWorkload.hpaAuto)
  );

  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [, setSelectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const workloadName = setWorkloadName(
    displayName ?? selectedWorkload.workloadName,
    selectedWorkload.workloadName,
    selectedWorkload.type,
    selectedWorkload.namespace,
    hideWorkloadSuffix ?? false
  );
  const [isOptimizationTypeApplied] = useQueryParam("hpaOptimizationType", ArrayParam);

  const [selectedTab, setSelectedTab] = useQueryParam(
    POLICY_TUNING_SELECTED_TAB_QUERY_KEY,
    withDefault(
      StringParam,
      scaleOpsProduct === ScaleOpsProduct.HPA ? PolicyTuningTabs.Hpa : PolicyTuningTabs.PodRightsizing
    )
  );
  const [selectedYamlTab, setSelectedYamlTab] = useQueryParam(
    "WorklaodYamlsTab",
    withDefault(StringParam, YamlTabs.WorkloadYaml)
  );

  const [viewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();
  const [tuningParams, setTuningParams] = useState<GetPolicyTuningConfigParamsResponse>({
    cpuPolicyTuningParams: undefined,
    memoryPolicyTuningParams: undefined,
  });

  const [selectedPolicy, setSelectedPolicy] = useState<Policy | undefined>({
    name:
      scaleOpsProduct === ScaleOpsProduct.HPA
        ? String(selectedWorkload.rightsizePolicyName ?? "")
        : selectedWorkload.policyName,
    displayName:
      scaleOpsProduct === ScaleOpsProduct.HPA
        ? String(selectedWorkload.rightsizePolicyName ?? "")
        : selectedWorkload.policyName,
  });

  const [selectedHPAPolicy, setSelectedHPAPolicy] = useState<string | undefined>(
    scaleOpsProduct === ScaleOpsProduct.HPA ? selectedWorkload.policyName : selectedWorkload.hpaPolicyName
  );

  const [selectedChartComponents, setSelectedChartComponents] = useState<ChartComponents[]>([
    ChartComponents.AverageUsage,
    ChartComponents.RecommendedRequest,
    ChartComponents.CurrentRequest,
  ]);

  const [selectedHpaChartComponents, setSelectedHpaChartComponents] = useState<HpaChartComponent[]>([]);

  const [cpuCappingConfig, setCpuCappingConfig] = useState<
    components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined
  >(undefined);

  const [memoryCappingConfig, setMemoryCappingConfig] = useState<
    components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined
  >(undefined);

  useEffect(() => {
    if (!viewPeriod) {
      setSelectedViewPeriod(ViewPeriodOptions["1 day"]);
    }
  }, []);

  const handleClose = () => {
    setDates(undefined);
    setSelectedContainer(undefined);
    setSelectedTab(undefined);
    setSelectedYamlTab(undefined);
    onClose();
  };

  useEffect(() => {
    if (isOptimizationTypeApplied && isOptimizationTypeApplied.length > 0) setSelectedTab(PolicyTuningTabs.Hpa);
  }, [isOptimizationTypeApplied]);

  useEffect(() => {
    if (selectedWorkload.isReadyRecommendation === false) {
      handleClose();
    }
  }, [selectedWorkload]);

  useEffect(() => {
    if (selectedTab !== PolicyTuningTabs.Yamls) {
      setTimeout(() => {
        setSelectedYamlTab(undefined);
      }, 0);
    }
  }, [selectedTab]);

  const recommendationName = selectedWorkload.type.toLowerCase() + "-" + selectedWorkload.workloadName;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <PolicyTuningDialogueTitle
          workloadName={workloadName}
          name={selectedWorkload.workloadName}
          namespace={selectedWorkload.namespace}
          kind={selectedWorkload.type}
        />
      }
      dialogContentStyle={{
        padding: "0px",
      }}
      minWidth="1200px"
    >
      <div className="flex flex-col gap-[10px] pt-[10px] bg-background-chipHover">
        <div className="mt-[6px] pt-[30px]">
          <div className="bg-white px-[20px] py-[20px] relative">
            <div className={clsx(TABS_CONTAINER_CLASS_NAME, "w-full top-[-36px] left-0")}>
              {Object.entries(PolicyTuningTabs).map(([key, value]) => {
                if (value === PolicyTuningTabs.Analytics && !HAS_WORKLOAD_ANALYTICS_TAB) return null;
                if (value === PolicyTuningTabs.Network && !HAS_WORKLOAD_NETWORK_TAB) return null;
                if (value === PolicyTuningTabs.APIs && !HAS_WORKLOAD_OBSERVABILITY) return null;
                if (value === PolicyTuningTabs.Gpu) {
                  if (!HAS_GPU_SUPPORT) {
                    return null;
                  }
                }

                let isDisabled = false;

                let tooltipContent: undefined | React.ReactNode = undefined;

                if (value === PolicyTuningTabs.Hpa) {
                  switch (true) {
                    case !selectedWorkload?.hasHpa:
                      isDisabled = true;
                      tooltipContent = (
                        <Typography variant="caption">
                          HPA optimization is enabled only for workloads with an attached HPA.{" "}
                          <strong>No HPA was found for this workload</strong>.
                        </Typography>
                      );
                      break;
                    case selectedWorkload?.type !== "Deployment":
                      isDisabled = true;
                      tooltipContent = (
                        <Typography variant="caption">
                          HPA optimization is currently supported only for <strong>Deployments</strong>. The current
                          workload type is: <strong>{selectedWorkload?.type}</strong>.
                        </Typography>
                      );
                      break;
                    default:
                      break;
                  }
                }

                if (value === PolicyTuningTabs.Gpu && !selectedWorkload?.hasGPU) {
                  isDisabled = true;
                  tooltipContent = (
                    <Typography variant="caption">
                      GPU insights are enabled only for workloads with an attached GPU.
                    </Typography>
                  );
                }

                return (
                  <SnapshotWrapper
                    wrappedType="tab"
                    inactive={
                      (value !== PolicyTuningTabs.Hpa && value !== PolicyTuningTabs.Overrides) ||
                      (value === PolicyTuningTabs.Hpa && HAS_HPA_TAB)
                    }
                  >
                    <Tab
                      key={key}
                      isSelected={selectedTab === value}
                      onClick={() => {
                        setSelectedTab(value);
                      }}
                      name={value}
                      dataTestId={`policy-tuning-${key}-tab`}
                      disabled={isDisabled}
                      tooltipContent={tooltipContent}
                      tabIcon={
                        value === PolicyTuningTabs.Hpa || value === PolicyTuningTabs.APIs ? <BetaChip /> : undefined
                      }
                      hasSelectedUnderline
                    />
                  </SnapshotWrapper>
                );
              })}
            </div>
            {(selectedTab === PolicyTuningTabs.PodRightsizing || selectedTab === PolicyTuningTabs.Gpu) && (
              <PolicyTuningContainer
                selectedWorkload={selectedWorkload}
                fetchWorkloads={fetchWorkloads}
                tuningParams={tuningParams}
                setTuningParams={setTuningParams}
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
                selectedChartComponents={selectedChartComponents}
                setSelectedChartComponents={setSelectedChartComponents}
                cpuCappingConfig={cpuCappingConfig}
                setCpuCappingConfig={setCpuCappingConfig}
                memoryCappingConfig={memoryCappingConfig}
                setMemoryCappingConfig={setMemoryCappingConfig}
                isAutomate={isRightsizeAutomate}
                setIsAutomate={setIsRightsizeAutomate}
                isGpu={selectedTab === PolicyTuningTabs.Gpu}
                scaleOpsProduct={scaleOpsProduct}
              />
            )}
            {selectedTab === PolicyTuningTabs.Hpa && (
              <SnapshotWrapper inactive={HAS_HPA_TAB} noDesign>
                <PolicyTuningHpa
                  selectedWorkload={selectedWorkload}
                  fetchWorkloads={fetchWorkloads}
                  tuningParams={tuningParams}
                  setTuningParams={setTuningParams}
                  selectedChartComponents={selectedHpaChartComponents}
                  setSelectedChartComponents={setSelectedHpaChartComponents}
                  isAutomate={isHPAAutomate}
                  setIsAutomate={setIsHPAAutomate}
                  selectedHPAPolicy={selectedHPAPolicy}
                  setSelectedHPAPolicy={setSelectedHPAPolicy}
                  scaleOpsProduct={scaleOpsProduct}
                />
              </SnapshotWrapper>
            )}
            {selectedTab === PolicyTuningTabs.Events && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                {HAS_WORKLOAD_HISTORY_EVENTS_CHART ? (
                  <WorkloadHistoryEventsContainer
                    kind={selectedWorkload.type}
                    name={selectedWorkload.workloadName}
                    namespace={selectedWorkload.namespace}
                    recommendationName={recommendationName}
                  />
                ) : (
                  <WorkloadEvents namespace={selectedWorkload.namespace} name={recommendationName} />
                )}
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Analytics && !HAS_NEW_TROUBLESHOOT_DESIGN && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <WorkloadAnalytics
                  selectedWorkload={selectedWorkload}
                  policyName={selectedPolicy?.name}
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  setCpuCappingConfig={setCpuCappingConfig}
                  setMemoryCappingConfig={setMemoryCappingConfig}
                />
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Analytics && HAS_NEW_TROUBLESHOOT_DESIGN && (
              <WorkloadTroubleshootContainer
                selectedWorkload={selectedWorkload}
                selectedPolicy={selectedPolicy}
                selectedChartComponents={selectedChartComponents}
                setSelectedChartComponents={setSelectedChartComponents}
                cpuCappingConfig={cpuCappingConfig}
                setCpuCappingConfig={setCpuCappingConfig}
                memoryCappingConfig={memoryCappingConfig}
                setMemoryCappingConfig={setMemoryCappingConfig}
                isAutomate={isRightsizeAutomate}
                setIsAutomate={setIsRightsizeAutomate}
                isGpu={false}
              />
            )}
            {selectedTab === PolicyTuningTabs.Network && (
              <WorkloadNetwork selectedWorkload={selectedWorkload} setSelectedWorkload={setSelectedWorkload} />
            )}
            {selectedTab === PolicyTuningTabs.Yamls && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <WorkloadYamlsTab
                  namespace={selectedWorkload.namespace}
                  name={selectedWorkload.workloadName}
                  kind={selectedWorkload.type}
                  hasHpa={!!selectedWorkload.hasHpa}
                  selectedTab={(selectedYamlTab as YamlTabs) ?? YamlTabs.WorkloadYaml}
                  setSelectedTab={setSelectedYamlTab}
                />
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Overrides && (
              <SnapshotWrapper noDesign>
                <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                  <WorkloadOverrides
                    namespace={selectedWorkload.namespace}
                    name={selectedWorkload.workloadName}
                    kind={selectedWorkload.type}
                    selectedPolicy={selectedPolicy}
                    setSelectedPolicy={setSelectedPolicy}
                    smartPolicyName={selectedWorkload.smartPolicyName}
                  />
                </div>
              </SnapshotWrapper>
            )}
            {selectedTab === PolicyTuningTabs.APIs && (
              <WorkloadObservabilityContainer
                namespace={selectedWorkload.namespace}
                name={selectedWorkload.workloadName}
                kind={selectedWorkload.type}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PolicyTuning;
