import { AxisDomain } from "recharts/types/util/types";
import { components } from "../../../../api/schema";
import { Elements, LayoutEntry } from "../../../../components/GridLayout/utils";
import { TooltipTrigger, UpdateActiveTooltips } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import AttachPolicyUnevictablePodsGraph from "./graphs/AttachPolicyUnevictablePodsGraph";
import AutomatedGraph from "./graphs/AutomatedGraph";
import CpuNoisyNeighborsUnderProvisionedWorkloadsGraph from "./graphs/CpuNoisyNeighborsUnderProvisionedWorkloadsGraph";
import CPUThrottlingGraph from "./graphs/CPUThrottlingGraph";
import HPACPUGraph from "./graphs/HPACPUGraph";
import HPAMemoryGraph from "./graphs/HPAMemoryGraph";
import HpaReplicasByTriggerGraph from "./graphs/HpaReplicasByTriggerGraph";
import LivenessProbeFailuresGraph from "./graphs/LivenessProbeFailuresGraph";
import MemoryNoisyNeighborsUnderProvisionedWorkloadsGraph from "./graphs/MemoryNoisyNeighborsUnderProvisionedWorkloadsGraph";
import NodeCPUUtilizationGraph from "./graphs/NodeCPUUtilizationGraph";
import NodeLifeCycleGraph from "./graphs/NodeLifeCycleGraph";
import NodeMemoryUtilizationGraph from "./graphs/NodeMemoryUtilizationGraph";
import OutOfMemoryGraph from "./graphs/OutOfMemoryGraph";
import PodCpuRequestGraph from "./graphs/PodCpuRequestGraph";
import PodCpuUsageGraph from "./graphs/PodCpuUsageGraph";
import PodDistributionReasonsGraph from "./graphs/PodDistributionReasonsGraph";
import PodMemoryRequestGraph from "./graphs/PodMemoryRequestGraph";
import ReplicasGraph from "./graphs/ReplicasGraph";
import ScaleOpsOptimizationEvictionsGraph from "./graphs/ScaleOpsOptimizationEvictionsGraph";

export const WORKLOAD_TROUBLESHOOT_DASHBOARD_CATEGORY = "workload_troubleshoot_dashboard_category";
export const WORKLOAD_TROUBLESHOOT_SYNC_ID = "general_sync_id";
export const NUMBER_OF_WORKLOAD_TROUBLESHOOT_GRAPH_ELEMENTS = 5;
export const PERCENTAGE_Y_AXIS_DOMAIN = [0, 100];
export const ROUNDED_TICK_FORMATTER = (value: number) => String(Math.round(value * 100) / 100);
export const NUMERIC_CPU_Y_AXIS_DOMAIN: AxisDomain = [0, (dataMax: number) => (dataMax < 0 ? 0 : dataMax * 1.2)];
export const NUMERIC_MEMORY_Y_AXIS_DOMAIN: AxisDomain = [0, (dataMax: number) => (dataMax < 0 ? 0 : dataMax * 1.2)];

export const getWorkloadTypeForWlAnalytics = (wl: components["schemas"]["UtilsWorkload"]) => wl?.type;
export interface CommonGraphProps {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  tooltipTrigger?: TooltipTrigger;
  disabledZoom?: boolean;
  updateActiveTooltips: UpdateActiveTooltips | undefined;
}

export enum Graph {
  NodeCpuUtilization = "Node CPU Utilization (%)",
  NodeMemoryUtilization = "Node Memory Utilization (%)",
  Automated = "Automated",
  Replicas = "Replicas",
  CpuThrottling = "CPU Throttling",
  OutOfMemory = "Out of Memory",
  LivenessPropFailure = "Liveness Prop Failure",
  ScaleOpsOptimizationEvictions = "ScaleOps Optimization Evictions",
  HpaCpu = "HPA CPU",
  HpaMemory = "HPA Memory",
  CpuNoisyNeighborsUnderProvisionedWorkloads = "CPU Noisy Neighbors (Under-Provisioned Workloads)",
  MemoryNoisyNeighborsUnderProvisionedWorkloads = "Memory Noisy Neighbors (Under-Provisioned Workloads)",
  PodCpuRequest = "Pod CPU Request",
  PodMemoryRequest = "Pod Memory Request",
  PodCpuUsage = "Pod CPU Usage",
  PodMemoryUsage = "Pod Memory Usage",
  NodeLifeCycle = "Node Life Cycle",
  PodDistributionReasons = "Pod Distribution Reasons",
  AttachPolicyUnevictablePods = "Attach Policy Unevictable Pods",
  HpaReplicasByTrigger = "HPA Replicas By Trigger",
}

export const getGraphElements = (props: CommonGraphProps) => {
  const graphElements: Elements = {
    [Graph.NodeCpuUtilization]: <NodeCPUUtilizationGraph {...props} />,
    [Graph.NodeMemoryUtilization]: <NodeMemoryUtilizationGraph {...props} />,
    [Graph.Automated]: <AutomatedGraph {...props} />,
    [Graph.Replicas]: <ReplicasGraph {...props} />,
    [Graph.CpuThrottling]: <CPUThrottlingGraph {...props} />,
    [Graph.OutOfMemory]: <OutOfMemoryGraph {...props} />,
    [Graph.LivenessPropFailure]: <LivenessProbeFailuresGraph {...props} />,
    [Graph.ScaleOpsOptimizationEvictions]: <ScaleOpsOptimizationEvictionsGraph {...props} />,
    [Graph.HpaCpu]: <HPACPUGraph {...props} />,
    [Graph.HpaMemory]: <HPAMemoryGraph {...props} />,
    [Graph.CpuNoisyNeighborsUnderProvisionedWorkloads]: <CpuNoisyNeighborsUnderProvisionedWorkloadsGraph {...props} />,
    [Graph.MemoryNoisyNeighborsUnderProvisionedWorkloads]: (
      <MemoryNoisyNeighborsUnderProvisionedWorkloadsGraph {...props} />
    ),
    [Graph.PodCpuRequest]: <PodCpuRequestGraph {...props} />,
    [Graph.PodMemoryRequest]: <PodMemoryRequestGraph {...props} />,
    [Graph.PodCpuUsage]: <PodCpuUsageGraph {...props} />,
    [Graph.PodMemoryUsage]: <PodCpuUsageGraph {...props} />,
    [Graph.NodeLifeCycle]: <NodeLifeCycleGraph {...props} />,
    [Graph.PodDistributionReasons]: <PodDistributionReasonsGraph {...props} />,
    [Graph.AttachPolicyUnevictablePods]: <AttachPolicyUnevictablePodsGraph {...props} />,
    [Graph.HpaReplicasByTrigger]: <HpaReplicasByTriggerGraph {...props} />,
  };
  return graphElements;
};

export enum CustomDashboard {
  Performance = "Performance",
  HPA = "HPA",
  Resources = "Resources",
}

export const READ_ONLY_LAYOUTS: Record<string, LayoutEntry[]> = {
  [CustomDashboard.Performance]: [
    {
      x: 1,
      y: 0,
      i: "Node Memory Utilization (%)",
    },
    {
      x: 0,
      y: 0,
      i: "Node CPU Utilization (%)",
    },
    {
      x: 0,
      y: 1,
      i: "ScaleOps Optimization Evictions",
    },
    {
      x: 1,
      y: 1,
      i: "Pod Distribution Reasons",
    },
    {
      x: 0,
      y: 2,
      i: "CPU Noisy Neighbors (Under-Provisioned Workloads)",
    },
    {
      x: 1,
      y: 2,
      i: "Memory Noisy Neighbors (Under-Provisioned Workloads)",
    },
    {
      x: 0,
      y: 3,
      i: "Out of Memory",
    },
    {
      x: 1,
      y: 3,
      i: "Liveness Prop Failure",
    },
    {
      x: 0,
      y: 4,
      i: "CPU Throttling",
    },
    {
      x: 1,
      y: 4,
      i: "Automated",
    },
  ],
  [CustomDashboard.HPA]: [
    {
      x: 0,
      y: 2,
      i: "Replicas",
    },
    {
      x: 1,
      y: 1,
      i: "Automated",
    },
    {
      x: 0,
      y: 1,
      i: "HPA Replicas By Trigger",
    },
    {
      x: 1,
      y: 0,
      i: "HPA Memory",
    },
    {
      x: 0,
      y: 0,
      i: "HPA CPU",
    },
  ],
  [CustomDashboard.Resources]: [
    {
      x: 0,
      y: 4,
      i: "Node Life Cycle",
    },
    {
      x: 1,
      y: 3,
      i: "Out of Memory",
    },
    {
      x: 0,
      y: 3,
      i: "CPU Throttling",
    },
    {
      x: 1,
      y: 2,
      i: "Pod Memory Usage",
    },
    {
      x: 0,
      y: 2,
      i: "Pod CPU Usage",
    },
    {
      x: 1,
      y: 1,
      i: "Pod Memory Request",
    },
    {
      x: 0,
      y: 1,
      i: "Pod CPU Request",
    },
    {
      x: 1,
      y: 0,
      i: "Node Memory Utilization (%)",
    },
    {
      x: 0,
      y: 0,
      i: "Node CPU Utilization (%)",
    },
  ],
};

export const categoryOptions = [
  {
    categoryName: "Performance",
    options: [
      Graph.CpuThrottling,
      Graph.OutOfMemory,
      Graph.LivenessPropFailure,
      Graph.CpuNoisyNeighborsUnderProvisionedWorkloads,
      Graph.MemoryNoisyNeighborsUnderProvisionedWorkloads,
      Graph.PodDistributionReasons,
    ],
  },
  {
    categoryName: "General",
    options: [Graph.Replicas, Graph.PodCpuRequest, Graph.PodMemoryRequest, Graph.PodCpuUsage, Graph.PodMemoryUsage],
  },
  {
    categoryName: "Optimization",
    options: [Graph.Automated, Graph.ScaleOpsOptimizationEvictions, Graph.AttachPolicyUnevictablePods],
  },
  {
    categoryName: "Nodes",
    options: [
      Graph.NodeCpuUtilization,
      Graph.NodeMemoryUtilization,
      Graph.NodeLifeCycle,
      Graph.AttachPolicyUnevictablePods,
    ],
  },
  {
    categoryName: "HPA",
    options: [Graph.HpaCpu, Graph.HpaMemory, Graph.HpaReplicasByTrigger],
  },
];
