import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MAIN_YELLOW } from "../colors";
import InfoIcon from "../Icons/InfoIcon";
import WarningIcon from "../Icons/WarningIcon";
import ArrowLine from "./ArrowLine";
import Tooltip from "./Tooltip";

export enum TooltipListIcon {
  Info = "info",
  Warning = "warning",
}

interface Props {
  title: React.ReactNode;
  list: string[] | undefined;
  subTitle?: React.ReactNode;
  iconType?: TooltipListIcon;
  customIcon?: React.ReactNode;
  renderListItemFnc?: (item: string) => React.ReactNode;
  footer?: React.ReactNode;
}

const TooltipList = ({ title, list, iconType, subTitle, customIcon, renderListItemFnc, footer }: Props) => {
  const [icon, setIcon] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    switch (true) {
      case iconType === TooltipListIcon.Warning:
        setIcon(<WarningIcon width={14} height={14} fill={MAIN_YELLOW} />);
        break;
      case iconType === TooltipListIcon.Info:
        setIcon(<InfoIcon width={16} height={16} />);
        break;
      case customIcon:
        setIcon(customIcon);
        break;
      default:
        break;
    }
  }, [iconType, customIcon]);

  if (!list || list.length === 0) return null;

  return (
    <Tooltip
      maxWidth={770}
      title={
        <div className="flex flex-col gap-5">
          <div className="bg-primary-lightPurpleBlue  h-[30px] p-[5px] flex gap-[10px] justify-left items-center w-full rounded-sm">
            {icon}
            <Typography variant="caption" fontWeight={700}>
              {title}
            </Typography>
          </div>
          <Typography variant="caption">
            {subTitle && <p className="underline">{subTitle}</p>}
            {list.map((item, index) => (
              <ArrowLine key={index} content={renderListItemFnc ? renderListItemFnc(item) : item} />
            ))}
          </Typography>
          {footer}
        </div>
      }
    >
      {icon}
    </Tooltip>
  );
};

export default TooltipList;
