import { Checkbox, ListItemText, MenuItem, Chip as MUIChip } from "@mui/material";
import WarningIcon from "../../Icons/WarningIcon";
import { components } from "../../api/schema";
import { MAIN_YELLOW } from "../../colors";
import MultiSelect from "../MultiSelect";
import { BlockedNodeReasons } from "../unevictable/BlockedNodesTable/utils";
import { ConvertArrayParamToStringArray } from "./NodeFilterBar";
import { convertReasonToTextToDisplay } from "./Utils";

export const BLOCKED_NODES_CATEGORY_ORDER = [
  BlockedNodeReasons.OptimizeUnevictablePods,
  BlockedNodeReasons.ExploreNodeRestriction,
  BlockedNodeReasons.ChangeClusterAutoScalerConfiguration,
  BlockedNodeReasons.ExploreSchedulingReasons,
];

const sortByBlockedNodeReasonsCategory = (
  a: string,
  b: string,
  _?: "asc" | "desc",
  availableScaleDownReasonsWarn?: components["schemas"]["NodeGroupsScaleDownReason"][]
) => {
  const aCategory = String(availableScaleDownReasonsWarn?.find((entity) => entity.reason == a)?.category ?? "");
  const bCategory = String(availableScaleDownReasonsWarn?.find((entity) => entity.reason == b)?.category ?? "");

  if (aCategory && bCategory) {
    return (
      BLOCKED_NODES_CATEGORY_ORDER.indexOf(aCategory as BlockedNodeReasons) -
      BLOCKED_NODES_CATEGORY_ORDER.indexOf(bCategory as BlockedNodeReasons)
    );
  }
  return 0;
};

interface Props {
  selected: (string | null)[] | null | undefined;
  setSelected: (props: (string | undefined)[]) => void;
  options: { reason: string; count: number }[];
  filterTitle?: string;
  filterIcon?: React.ReactNode;
  className?: string;
}

const ScaleDownFilter = ({
  selected,
  setSelected,
  options,
  filterTitle = "scale down blockers",
  filterIcon,
  className,
}: Props) => (
  <MultiSelect
    selected={ConvertArrayParamToStringArray(selected)}
    setSelected={setSelected as (props: (string | undefined)[]) => void}
    options={options
      .map((entity) => entity.reason)
      .sort(
        (a, b) =>
          Number(options.find((entity) => entity.reason == b)?.count ?? 0) -
          Number(options.find((entity) => entity.reason == a)?.count ?? 0)
      )}
    sortFnc={sortByBlockedNodeReasonsCategory}
    className={className}
    sort="desc"
    customIcon={
      <MUIChip
        variant={"outlined"}
        sx={{
          background: selected?.length ? "#595959" : undefined,
          color: selected?.length ? "white" : "rgba(0, 0, 0, 0.87)",
          borderColor: "rgba(0, 0, 0, 0.87)",
        }}
        label={
          <div className="flex items-center justify-center gap-1">
            {filterIcon ?? <WarningIcon fill={MAIN_YELLOW} />}
            {filterTitle}
          </div>
        }
      />
    }
    optionRenderFunction={(option: string, index: number, selected: (string | undefined)[], dataTestId?: string) => {
      const displayText =
        option === "PodOwnerReachedToMinimumReplicas" ? "Pod has no owner" : convertReasonToTextToDisplay(option);

      return (
        <MenuItem
          value={option}
          key={`${index}-multi-select-option`}
          data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
        >
          <Checkbox checked={selected.indexOf(option) > -1} />
          <ListItemText primary={displayText} />
          <span className="px-4 bg-guideline-darkGreen font-bold text-white text-[10px] rounded-lg ml-2">
            {options.find((entity) => entity.reason == option)?.count}
          </span>
        </MenuItem>
      );
    }}
  />
);

export default ScaleDownFilter;
