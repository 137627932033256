import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import InfoIcon from "../../../../Icons/InfoIcon";
import { useMainContext } from "../../../../MainContext";
import { GetTopK, GetTopKParams, GetTopKResponse } from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import ChartTooltipItemsCount from "../../../../components/ChartTooltipItemsCount";
import ChartTooltipTitle from "../../../../components/ChartTooltipTitle";
import InfoTooltip from "../../../../components/Tooltip";
import LinkableTooltipElement from "../../../../components/common/LinkableTooltipElement";
import useWindowSize from "../../../../components/useWindowSize";
import { getMaxFlatArray } from "../../../../utils/arrayUtils";
import { adjustedDayjs } from "../../../../utils/dateAndTimeUtils";
import { HAS_NEW_CHARTS_DESIGN } from "../../../../utils/developmentFeatureFlags";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { MAX_Y_AXIS_DOMAIN, OverviewLinkType } from "../../../../utils/graphUtils";
import {
  DOT_RADIUS,
  DOT_STROKE_WIDTH,
  LINE_CHART_TYPE,
  NO_OUTLINE,
  SCROLLABLE_TROUBLESHOOT_TOOLTIP,
  TOOLTIP_WRAPPER_CLASS_NAME,
} from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType, getParsedData } from "../utils";
import { ANALYTICS_PAGE_DAILY_SYNC_ID } from "./graphUtils";
import FreezeTooltipWarning from "./hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "./hooks/useFreezeTooltip";
import { FrozenTooltipType } from "./hooks/utils";
import { PayloadItem, SecondaryValueFnc } from "./topKUtils";

const MAX_ELEMENTS_PER_TOOLTIP = 10;
const HEIGHT = "h-[200px]";
const TOTAL_SUM_KEY = "totalSum";
export const SUM = "#sum";
export const QUANTILE = "#quantile";
const { queryFn, queryKey } = GetTopK();

const defaultRenderNameFunction = (name: string | undefined) => {
  switch (true) {
    case name === SUM:
      return "total";
    case name?.includes(QUANTILE):
      return `${String(name?.replace(QUANTILE, ""))}90th-percentile`;
    default:
      return name;
  }
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: PayloadItem[];
  label?: string;
  renderNameFunction: (name: string | undefined) => string | undefined;
  tooltipTrigger: TooltipTrigger | undefined;
  valueFormatter?: (tick: string) => string;
  frozenTooltipType?: FrozenTooltipType;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
  enableCopyTextOnClick?: boolean;
  targetResource?: OverviewLinkType;
  getSecondaryValue?: SecondaryValueFnc;
  valueTitle?: string;
  isMultiCluster?: boolean;
  keyParser?: (key: string | undefined) => string | undefined;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  valueFormatter,
  tooltipTrigger,
  renderNameFunction,
  frozenTooltipType = FrozenTooltipType.FrozenAndClickable,
  tooltipId,
  updateActiveTooltips,
  enableCopyTextOnClick,
  targetResource = OverviewLinkType.Workload,
  valueTitle,
  isMultiCluster,
  keyParser,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  if (active && payload && payload.length) {
    const payloadData = payload?.[0].payload as Record<string, number> | undefined;
    const sortedPayload = Object.entries(payloadData || {})
      ?.filter(([key]) => key !== "date" && key !== TOTAL_SUM_KEY)
      ?.sort((a, b) => b[1] - a[1])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, number>);

    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        {label && <ChartTooltipTitle timestamp={label} valueTitle={valueTitle} />}
        <div className={SCROLLABLE_TROUBLESHOOT_TOOLTIP}>
          {Object.entries(sortedPayload).map(([key, value], index) => {
            if (key === "date" || key === TOTAL_SUM_KEY) {
              return null;
            }
            const renderedName = renderNameFunction(key);

            const parsedKey = keyParser ? keyParser(key) : key;

            return (
              <LinkableTooltipElement
                key={index}
                value={valueFormatter ? valueFormatter(String(value)) : String(value)}
                label={<div className="max-w-[277px] truncate rtl">{renderedName}</div>}
                rawLabel={String(parsedKey)}
                disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
                textToCopyOnClick={enableCopyTextOnClick ? renderedName : undefined}
                targetResource={targetResource}
                source={isMultiCluster ? OverviewLinkType.MultiCluster : OverviewLinkType.Workload}
                hasLimitedWidth
              />
            );
          })}
        </div>
        <ChartTooltipItemsCount count={sortedPayload.length} maxToShow={MAX_ELEMENTS_PER_TOOLTIP} />
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

interface Props {
  title: React.ReactNode;
  queryParams: GetTopKParams;
  showPercentage?: boolean;
  wrapDivClassName?: string;
  setDate: (date: DateType) => void;
  YAxisDomain?: AxisDomain | undefined;
  noGroupBy?: boolean;
  YAxisFormatter?: (tick: string) => string;
  isMulticluster?: boolean;
  topK?: number;
  withSum?: boolean;
  infoTooltip?: React.ReactNode;
  infoTooltipMaxWidth?: number;
  syncId?: string | null;
  renderNameFunction?: (name: string | undefined) => string | undefined;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  color?: string;
  dotted?: boolean;
  enableCopyTextOnClick?: boolean;
  YAxisType?: "number" | "category";
  yTickLine?: boolean;
  allowDecimals?: boolean;
  getParsedDataFixedValue?: number;
  targetTooltipLink?: OverviewLinkType;
  tooltipValueTitle?: string;
  children?: React.ReactNode;
  keyParser?: (key: string | undefined) => string | undefined;
}

const TopKSummaryLineChart = ({
  title,
  queryParams,
  showPercentage,
  wrapDivClassName,
  setDate,
  YAxisDomain,
  noGroupBy,
  YAxisFormatter = (tick: string) => tick,
  isMulticluster,
  topK,
  withSum,
  infoTooltip,
  infoTooltipMaxWidth = 660,
  syncId = ANALYTICS_PAGE_DAILY_SYNC_ID,
  renderNameFunction = defaultRenderNameFunction,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType = FrozenTooltipType.Disabled,
  updateActiveTooltips,
  color = SCALEOPS_COLORS.guideline.darkPurpleHover,
  dotted,
  enableCopyTextOnClick,
  YAxisType,
  yTickLine,
  allowDecimals,
  getParsedDataFixedValue,
  targetTooltipLink,
  tooltipValueTitle,
  children,
  keyParser,
}: Props) => {
  const { currentCluster, didClusterChanged } = useMainContext();

  const filtersQueryParams = useFilterQueryParams();
  const { width: windowWidth } = useWindowSize();

  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [viewPeriodInHours, setViewPeriodInHours] = useState<number>(3 * 24);

  const { data, isLoading, error, isError } = useQuery<GetTopKResponse, Error>({
    queryKey: [
      queryKey,
      queryParams,
      filtersQueryParams,
      isMulticluster ? "multicluster" : currentCluster,
      topK ? `topK-${topK}` : undefined,
      withSum ? "withSum" : undefined,
    ],
    queryFn: () =>
      queryFn({
        ...queryParams,
        multiCluster: isMulticluster,
        ...filtersQueryParams,
        topK,
        withSum,
        timeoutSeconds: timeoutSeconds,
      }),
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
    enabled: isQueryEnabled,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    if (data?.values) {
      const firstDate = adjustedDayjs(data.values[0].timestamp);
      const lastDate = adjustedDayjs(data.values[data.values.length - 1].timestamp);
      setViewPeriodInHours(lastDate.diff(firstDate, "hours"));
    }
  }, [queryKey, queryParams, filtersQueryParams, isMulticluster, topK, withSum]);

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [queryParams, filtersQueryParams]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div
        className={clsx(
          HEIGHT,
          "bg-white border border-border rounded min-h-full w-full flex justify-center items-center"
        )}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error && didClusterChanged) {
    toast.error(`${queryParams?.queryKey ?? ""} chart could not load as for this cluster`);
    console.log("error loading chart", title, error);
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData = getParsedData(
    data,
    showPercentage,
    false,
    queryParams.from ? Number(queryParams.from) : undefined,
    queryParams.to ? Number(queryParams.to) : undefined,
    undefined,
    noGroupBy,
    false,
    true,
    getParsedDataFixedValue
  )?.map((item) => {
    const totalSum = Object.entries(item).reduce((acc, [key, value]) => {
      if (key !== "date" && !Number.isNaN(Number(value))) {
        return (acc ?? 0) + Number(value);
      }
      return acc;
    }, undefined as number | undefined);

    return {
      ...item,
      date: item.date,
      totalSum: totalSum,
    };
  });

  const maxDataPoint = Math.round(
    getMaxFlatArray(
      graphData.map((item) => {
        return Object.values(item)
          .filter((value) => !Number.isNaN(Number(value)))
          .map((value) => Number(value));
      })
    ) * MAX_Y_AXIS_DOMAIN
  );

  const firstXPointString = String(graphData[0]?.date);
  const firstXPointEpoch = adjustedDayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = adjustedDayjs(lastXPointString).unix() * 1000;

  return (
    <div
      className={clsx(wrapDivClassName, "bg-white min-h-full border rounded unselectable-svg-text py-8 pr-8", {
        "border-black": HAS_NEW_CHARTS_DESIGN,
        "border-border": !HAS_NEW_CHARTS_DESIGN,
      })}
    >
      <div className={HEIGHT}>
        <Typography
          variant="body2"
          fontWeight={HAS_NEW_CHARTS_DESIGN ? 700 : undefined}
          className="w-full flex justify-center items-center gap-1"
        >
          {title}
          {infoTooltip && (
            <InfoTooltip title={infoTooltip} placement="top" maxWidth={infoTooltipMaxWidth}>
              <InfoIcon width={14} height={14} />
            </InfoTooltip>
          )}
        </Typography>
        <ResponsiveContainer width="100%" height="100%" className="pr-4">
          <ComposedChart
            syncId={syncId ? syncId : undefined}
            data={graphData}
            onMouseDown={(e) => {
              e.activeLabel &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  from: adjustedDayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              selectPosition?.from &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  to: adjustedDayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  toX: e.activeLabel,
                });
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to) {
                if (selectPosition?.from < selectPosition?.to) {
                  setSelectPosition({
                    ...selectPosition,
                    to: lastXPointEpoch,
                    toX: lastXPointString,
                  });
                } else {
                  setSelectPosition({
                    to: selectPosition.from,
                    toX: selectPosition.fromX,
                    from: firstXPointEpoch,
                    fromX: firstXPointString,
                  });
                }
              }
            }}
          >
            <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
            <Tooltip
              content={
                <CustomTooltip
                  valueFormatter={YAxisFormatter}
                  renderNameFunction={renderNameFunction}
                  frozenTooltipType={frozenTooltipType}
                  tooltipTrigger={tooltipTrigger}
                  tooltipId={queryKey}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick={enableCopyTextOnClick}
                  targetResource={targetTooltipLink}
                  valueTitle={tooltipValueTitle}
                  isMultiCluster={isMulticluster}
                  keyParser={keyParser}
                />
              }
              trigger={tooltipTrigger}
              wrapperStyle={NO_OUTLINE}
            />
            <XAxis
              dataKey="date"
              style={{ fontSize: "x-small" }}
              interval={Math.floor(graphData.length / (Number(windowWidth) / 300))}
              strokeWidth={2}
              tickFormatter={(value) => {
                const epochValue = adjustedDayjs(String(value)).unix();
                return getTimeFormatFromEpochAndPeriodInHours(epochValue, viewPeriodInHours);
              }}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={YAxisDomain ?? [0, maxDataPoint || Infinity]}
              type={YAxisType}
              strokeWidth={2}
              tickFormatter={(tick) => YAxisFormatter(String(tick))}
              tickLine={yTickLine}
              allowDecimals={allowDecimals}
            />
            <Area
              type={LINE_CHART_TYPE}
              strokeWidth={2}
              dataKey={TOTAL_SUM_KEY}
              name={"max"}
              stroke={color}
              fill={color}
              fillOpacity={0.4}
              radius={dotted ? DOT_RADIUS : 0}
              dot={{ r: dotted ? 1 : 0, strokeWidth: dotted ? DOT_STROKE_WIDTH : 0, color }}
              isAnimationActive={!dotted}
            />
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="#3B8BFF"
                fill="#3B8BFF"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {children}
    </div>
  );
};

export default TopKSummaryLineChart;
