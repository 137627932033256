import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetConfig } from "../../utils/ConfigHelper";
import { FORCE_NO_CLUSTER_MODE } from "../../utils/developmentFeatureFlags";
import DiscoverBanner from "./DiscoverBanner";
import GuideSection from "./GuideSection";
import Terms from "./Terms";
import TopPurpleBanner from "./TopPurpleBanner";
import { TabType } from "./utils";
import Users from "../../components/users";
import { Typography } from "@mui/material";
import UserIcon from "../../Icons/UserIcon";
import RocketIcon from "../../Icons/RocketIcon";

const DarkBackground = () => <div className="bg-black opacity-80 fixed top-0 right-0 w-[100vw] h-[100vh] z-[1000]" />;

interface Props {
  currentCluster: string | undefined;
  inCloudMode: boolean;
}
const InstallContainer = ({ currentCluster, inCloudMode }: Props) => {
  const [selectedTab, setSelectedTab] = useQueryParam("kind", withDefault(StringParam, TabType.INSTALL)) as [
    TabType,
    (newValue: TabType) => void
  ];

  const confData = GetConfig();
  const isInCloudAdmin = confData.userGroups?.includes("Admin") || confData.userGroups?.includes("ScaleOpsInternal");
  const customerPrefix = confData.customerName
    ? confData.customerName.toLowerCase().replaceAll(" ", "-")
    : "<CUSTOMER_NAME>";

  if (!FORCE_NO_CLUSTER_MODE && (currentCluster || !inCloudMode)) return null;
  return (
    <>
      <DarkBackground />
      <div className="fixed flex justify-center bg-green top-0 right-1/2 transform translate-x-1/2 w-[65rem] px-16 py-2 z-[1000] first-letter:uppercase bg-white overflow-y-auto h-[100vh]">
        <div className="w-[98%] max-w-full flex flex-col gap-[1rem] py-[1.25rem] relative">
          <div className="flex flex-col w-full gap-2 items-end">
            {isInCloudAdmin && (
              <a
                className="p-2 text-white bg-primary-purpleBlue hover:bg-main-blue rounded-lg scaleopsShadow border border-black w-fit"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  selectedTab === TabType.INSTALL ? setSelectedTab(TabType.INVITE) : setSelectedTab(TabType.INSTALL);
                }}
              >
                <Typography variant="caption" className="flex gap-2 items-center">
                  {selectedTab === TabType.INSTALL ? (
                    <>
                      <UserIcon width={16} height={16} />
                      Invite Users
                    </>
                  ) : (
                    <>
                      <RocketIcon />
                      Install
                    </>
                  )}
                </Typography>
              </a>
            )}
          </div>
          {selectedTab === TabType.INVITE && <Users />}
          {selectedTab === TabType.INSTALL && (
            <>
              <TopPurpleBanner companyName={confData.customerName} />
              <DiscoverBanner />
              <GuideSection
                token={confData.token || "<SCALEOPS_TOKEN>"}
                customerPrefix={customerPrefix}
                useScaleOpsRegistry={confData.token?.startsWith("soc_") || false}
                inCloudUrl={confData.inCloudUrl}
              />
            </>
          )}
          {selectedTab === TabType.INSTALL && <Terms />}
        </div>
      </div>
    </>
  );
};

export default InstallContainer;
