import {useQuery} from "@tanstack/react-query";
import {ReactNode, useEffect, useState} from "react";
import {getConfig, GetConfigResponse} from "../../../api/fetcher";
import AuthOptionBox from "./AuthOptionBox";
import NoAuthenticationContainer from "./NoAuthenticationContainer";
import SimpleAuthContainer from "./SimpleAuthContainer";
import SSOWithAuthorization from "./SSOWithAuthorization";
import SSOWithoutAuthorization from "./SSOWithoutAuthorization";
import TopContainer from "./TopContainer";
import {AuthMethod, getSSOIntegrationFromString, GetSSOSetupConf, SSOIntegration} from "./utils";
import Button from "../../../components/Button";
import {Form, Formik} from "formik";
import useOverrideAuthSettings from "./useOverrideAuthSettings";
import Tooltip from "../../../components/Tooltip";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import {ADMIN_ROLE} from "../../../utils/typesUtils";

const configApi = getConfig();

const PermissionsContainer = () => {
  const [currentAuthMethod, setCurrentAuthMethod] = useState<AuthMethod | undefined>(undefined);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState<AuthMethod | undefined>(undefined);
  const [selectedProvider, setSelectedProvider] = useState<string | undefined>(undefined);
  const [submitTooltipText, setSubmitTooltipText] = useState<ReactNode>("Save changes");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
  const overrideAuthSettings = useOverrideAuthSettings();
  const ssoSetupConf = GetSSOSetupConf();
  const { userRole } = useGetUserRole();

  const { data } = useQuery<GetConfigResponse, Error>({
    queryKey: [configApi.queryKey],
    queryFn: configApi.queryFn,
  });

  useEffect(() => {
    if (data) {
      const provider = data?.ssoConf?.provider;
      const rbacEnabled = !!data?.rbacEnabled;

      switch (true) {
        case provider === "none":
          setCurrentAuthMethod(AuthMethod.DISABLED);
          break;
        case provider === "simpleAuth":
          setCurrentAuthMethod(AuthMethod.BUILT_IN);
          break;
        case rbacEnabled:
          setCurrentAuthMethod(AuthMethod.SSO_WITH_AUTHORIZATION);
          break;
        default:
          setCurrentAuthMethod(AuthMethod.SSO_WITHOUT_AUTHORIZATION);
          break;
      }
    }
  }, [data]);

  useEffect(() => {
    if (!selectedAuthMethod) {
      setSelectedAuthMethod(currentAuthMethod);
    }
  }, [currentAuthMethod]);

  useEffect(() => {
    if (userRole !== ADMIN_ROLE) {
      setSubmitTooltipText(<><b>Insufficient Permissions</b>: Only admins can edit SSO configuration</>);
      setSubmitButtonDisabled(true)
    } else if (selectedAuthMethod === AuthMethod.DISABLED && currentAuthMethod !== AuthMethod.DISABLED) {
      setSubmitTooltipText("Cannot disable authentication via UI");
      setSubmitButtonDisabled(true)
    } else if ((currentAuthMethod === AuthMethod.DISABLED && selectedAuthMethod === AuthMethod.DISABLED) || (currentAuthMethod === AuthMethod.BUILT_IN && selectedAuthMethod === AuthMethod.BUILT_IN)) {
      setSubmitTooltipText("");
      setSubmitButtonDisabled(true)
    } else {
      setSubmitTooltipText(
        <div style={{fontSize: 12}} className={"text-[10px] italic text-text-darkGray"}>
          Note: Will cause dashboard restart
        </div>
      )
      setSubmitButtonDisabled(false)
    }
  }, [selectedAuthMethod, userRole]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        defaultAdminGroup: "",
        issuerUrl: "",
        clientID: "",
        clientSecret: "",
        groupsClaim: "",
        tenantID: "",
        server: "",
        port: "",
        baseDN: "",
        bindUserName: "",
        bindUserPassword: "",
        credentials: "",
      }}
      onSubmit={(values) => {
        let authType = 0;
        let rolesEnabled = false;
        let selectedFormProvider = selectedProvider;
        interface IStringIndex {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [key: string]: any
        }
        const conf: IStringIndex = {}
        switch (selectedFormProvider) {
          case SSOIntegration.Okta:
          case SSOIntegration.OAuth2:
            authType = 1;
            conf["issuerUrl"] = values.issuerUrl;
            conf["clientID"] = values.clientID;
            conf["clientSecret"] = values.clientSecret;
            conf["groupsClaim"] = values.groupsClaim;
            conf["scopes"] = ["openid", "profile", "email"];
            break;
          case SSOIntegration.Azure:
            authType = 1;
            conf["issuerUrl"] = `https://login.microsoftonline.com/${values.tenantID}/v2.0/`;
            conf["clientID"] = values.clientID;
            conf["clientSecret"] = values.clientSecret;
            conf["groupsClaim"] = values.groupsClaim;
            conf["scopes"] = ["openid", "profile", "email"];
            break;
          case SSOIntegration.Github:
            conf["clientID"] = values.clientID;
            conf["clientSecret"] = values.clientSecret;
            authType = 3;
            break;
          case SSOIntegration.Google:
            conf["clientID"] = values.clientID;
            conf["clientSecret"] = values.clientSecret;
            conf["credentials"] = values.credentials;
            authType = 2;
            break;
          case SSOIntegration.Openshift:
            conf["clientID"] = values.clientID;
            conf["clientSecret"] = values.clientSecret;
            authType = 4;
            break;
          case SSOIntegration.LDAP:
            conf["server"] = values.server;
            conf["baseDN"] = values.baseDN;
            conf["bindUserName"] = values.bindUserName;
            conf["bindUserPassword"] = values.bindUserPassword;
            conf["port"] = values.port;
            authType = 8;
            break;
        }
        switch (selectedAuthMethod) {
          case AuthMethod.DISABLED:
            authType = 0;
            selectedFormProvider = "Disabled";
            break;
          case AuthMethod.BUILT_IN:
            selectedFormProvider = "Builtin Auth";
            authType = 7;
            break;
          case AuthMethod.SSO_WITHOUT_AUTHORIZATION:
            rolesEnabled = false;
            break;
          case AuthMethod.SSO_WITH_AUTHORIZATION:
            rolesEnabled = true;
            break;
        }
        overrideAuthSettings.mutate({
          type: authType,
          rolesEnabled: rolesEnabled,
          selectedProvider: selectedFormProvider,
          conf: JSON.stringify(conf),
          adminGroups: [values.defaultAdminGroup],
          operatorGroups: [],
          viewerGroups: [],
        });
      }}
    >
      <Form>
        <div className="flex flex-col gap-10">
          <TopContainer authType={currentAuthMethod} />
          <div className="flex flex-wrap gap-[30px]">
            <AuthOptionBox
              Title="DISABLED"
              description={
                <>
                  <b>No authentication</b> and <b>authorization</b>
                </>
              }
              isDefault={currentAuthMethod === AuthMethod.DISABLED}
              isSelected={selectedAuthMethod === AuthMethod.DISABLED}
              onClick={() => {
                setSelectedAuthMethod(AuthMethod.DISABLED);
              }}
              providerName={"This"}
            />
            <AuthOptionBox
              Title="SCALEOPS BUILT-IN"
              description={
                <>
                  <b>Built-in authentication</b> and <b>authorization</b>
                </>
              }
              isDefault={currentAuthMethod === AuthMethod.BUILT_IN}
              isSelected={selectedAuthMethod === AuthMethod.BUILT_IN}
              onClick={() => {
                setSelectedAuthMethod(AuthMethod.BUILT_IN);
              }}
              providerName={"ScaleOps Built-in"}
            />
            <AuthOptionBox
              Title="SSO"
              description={
                <>
                  AWS, Google, Okta, Azure, <b>and more</b>.
                </>
              }
              isDefault={currentAuthMethod === AuthMethod.SSO_WITHOUT_AUTHORIZATION}
              isSelected={selectedAuthMethod === AuthMethod.SSO_WITHOUT_AUTHORIZATION}
              onClick={() => {
                setSelectedAuthMethod(AuthMethod.SSO_WITHOUT_AUTHORIZATION);
              }}
              providerName={getSSOIntegrationFromString(data?.ssoConf?.providerName || "This").toString()}
            />
            <AuthOptionBox
              Title="SSO & AUTHORIZATION"
              description={
                <>
                  <b>SSO authentication</b> and <b>authorization</b>.
                </>
              }
              isDefault={currentAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION}
              isSelected={selectedAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION}
              onClick={() => {
                setSelectedAuthMethod(AuthMethod.SSO_WITH_AUTHORIZATION);
              }}
              providerName={getSSOIntegrationFromString(data?.ssoConf?.providerName || "This").toString()}
            />
          </div>
          {currentAuthMethod && (
            <>
              {selectedAuthMethod === AuthMethod.DISABLED && (
                <NoAuthenticationContainer currentAuthMethod={currentAuthMethod} />
              )}
              {selectedAuthMethod === AuthMethod.BUILT_IN && (
                <SimpleAuthContainer currentAuthMethod={currentAuthMethod} />
              )}
              {selectedAuthMethod === AuthMethod.SSO_WITHOUT_AUTHORIZATION && (
                <SSOWithoutAuthorization currentAuthMethod={currentAuthMethod}
                                         updateProvider={(provider: string) => {setSelectedProvider(provider)}}
                                         ssoSetupConf={ssoSetupConf}/>
              )}
              {selectedAuthMethod === AuthMethod.SSO_WITH_AUTHORIZATION && (
                <SSOWithAuthorization currentAuthMethod={currentAuthMethod}
                                      updateProvider={(provider: string) => {setSelectedProvider(provider)}}
                                      ssoSetupConf={ssoSetupConf}/>
              )}
            </>
          )}

          <div className="flex py-1 w-full justify-end">
            <Tooltip title={submitTooltipText} disabled={submitTooltipText === ""} className="truncate cursor-default w-fit max-w-[100%] pl-[17px]">
              <Button type="submit" label="Save" disabled={submitButtonDisabled} />
            </Tooltip>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default PermissionsContainer;
