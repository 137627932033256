import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  getConfLogin,
  UpdateClusterAuth,
  UpdateClusterAuthParams,
} from "../../../api/fetcher";
import { TOAST_SETTINGS } from "../../Ingress/mutations/utils";

const { queryFn } = UpdateClusterAuth();

const useOverrideAuthSettings = () => {
  const RAW_ELEMENT_HTML = `<div id="scaleopsIsBlocked" style="position: fixed; z-index: 999999; top: 0px; left: 0px; width: 100vw; height: 100vh; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.8);"><div class="bg-white px-16 py-8 text-black flex flex-col justify-end items-center gap-4 rounded-lg"><p class="MuiTypography-root MuiTypography-body1 css-197pl4-MuiTypography-root">Dashboard is restarting...</p></div></div>`;

  return useMutation((params: UpdateClusterAuthParams) => queryFn(params), {
    onError: (error: Error) => {
      toast.error(`Error configuring cluster auth: ${error.message}`);
    },
    onSuccess: (_, values) => {
      console.log(values)
      toast.success(`Successfully changed cluster ${values?.rolesEnabled ? "authorization" : "authentication"} to ${values?.selectedProvider ?? ""}`, TOAST_SETTINGS);
      const newElement = document.createElement("div");
      newElement.innerHTML = RAW_ELEMENT_HTML;
      document.body.appendChild(newElement);
      const intervalId = setInterval(() => {
        getConfLogin()
          .queryFn()
          .then((response) => {
            console.log(response);
            document.body.removeChild(newElement);
            clearInterval(intervalId);
            localStorage.clear();
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }, 3000);
    },
  });
};

export default useOverrideAuthSettings;
