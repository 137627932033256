import { Form, Formik } from "formik";
import {
  booleanToNumber,
  cpuQuantityToScalar,
  getLimitStrategy,
  getRightSizePolicy,
  getUpdatePolicy,
  memoryQuantityToScalar,
  NO_OVERRIDE_NUMERIC_VALUE,
  NO_OVERRIDE_STRING_VALUE,
  OverridePolicy,
  PolicyType,
  POLICY_VALUES,
} from "./utils";
import * as Yup from "yup";
import { nullablePositiveLimitValidation, nullablePositiveNumberValidation } from "../../../../../utils/yupUtils";
import SideMenu, { MenuItem } from "../../../../../components/SideMenu/SideMenu";
import RequestsHeadroom from "./RequestsHeadroom";
import HistogramRequestPercentileConfiguration from "./HistogramRequestPercentileConfiguration";
import MinimumResourceBoundaries from "./MinimumResourceBoundaries";
import MaximumResourceBoundaries from "./MaximumResourceBoundaries";
import KeepRequest from "./KeepRequest";
import LimitsStrategy from "./LimitsStrategy";
import ApplyingRecommendationSettings from "./ApplyingRecommendationSettings";
import BasicSettingsIcon from "../../../../../Icons/BasicSettingsIcon";
import LimitIcon from "../../../../../Icons/LimitIcon";
import AutomationIcon from "../../../../../Icons/AutomationIcon";
import { useEffect, useState } from "react";
import {
  getPolicies,
  GetPoliciesResponse,
  OverrideRecommendationPolicy,
  OverrideRecommendationPolicyParams,
} from "../../../../../api/fetcher";
import { components } from "../../../../../api/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Policy } from "../../utils";
import ActionButtons from "../ActionButtons";
import { toast } from "react-toastify";
import { ApiError } from "openapi-typescript-fetch";

enum MenuItemType {
  REQUEST = "Request",
  LIMIT = "Limit",
  AUTOMATION = "Automation",
}

const menuItems: MenuItem[] = [
  {
    id: MenuItemType.REQUEST,
    title: MenuItemType.REQUEST,
    icon: <BasicSettingsIcon />,
  },
  {
    id: MenuItemType.LIMIT,
    title: MenuItemType.LIMIT,
    icon: <LimitIcon className="mt-[-3px]" />,
  },
  {
    id: MenuItemType.AUTOMATION,
    title: MenuItemType.AUTOMATION,
    icon: (
      <div className="min-w-5 min-h-5">
        <AutomationIcon />
      </div>
    ),
  },
];

interface Props {
  namespace: string;
  name: string;
  kind: string;
  isLoading: boolean;
  overridePolicy?: OverridePolicy;
  selectedPolicy: Policy | undefined;
  setSuccessfulSave: (value: boolean) => void;
}

const PolicyTab = ({ namespace, name, kind, isLoading, overridePolicy, selectedPolicy, setSuccessfulSave }: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItemType.REQUEST);

  const policies = getPolicies();
  const { data: policiesData } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policies.queryKey, selectedPolicy?.name],
    queryFn: policies.queryFn,
  });

  const [selectedPolicyData, setSelectedPolicyData] = useState<PolicyType | undefined>(undefined);
  useEffect(() => {
    const policies = policiesData as GetPoliciesResponse;
    const test = policies?.policies as components["schemas"]["V1alpha1Policy"][];
    const policy = test?.filter((policy) => (policy?.metadata?.name || "") === selectedPolicy?.name)[0];
    setSelectedPolicyData(policy);
  }, [policiesData, selectedPolicy]);

  const { queryFn: overrideQueryFn } = OverrideRecommendationPolicy();
  const mutation = useMutation((params: OverrideRecommendationPolicyParams) => overrideQueryFn(params), {
    onSuccess: (data) => {
      setSuccessfulSave(true);
      const message = "Override successfully saved!";
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      console.log(message, data);
    },
    onError: (error: ApiError) => {
      const message = "Error saving override";
      toast.error(message);
      console.log(message, error.message);
    },
  });

  const handleSubmit = (values: POLICY_VALUES) => {
    setSuccessfulSave(false);
    const originalOverridePolicy: OverridePolicy = overridePolicy || {};

    const newOverridePolicy: OverridePolicy = {
      ...originalOverridePolicy,
      rightSizePolicy: getRightSizePolicy(values, originalOverridePolicy),
      updatePolicy: getUpdatePolicy(values, originalOverridePolicy),
    };

    mutation.mutate({ overridePolicy: newOverridePolicy, name: `${kind.toLowerCase()}-${name}`, namespace });
  };

  return (
    <>
      {!isLoading && (
        <Formik
          initialValues={{
            cpuHeadroom:
              overridePolicy?.rightSizePolicy?.requestsConfigs?.cpu?.headroomPercentage ?? NO_OVERRIDE_NUMERIC_VALUE,
            memoryHeadroom:
              overridePolicy?.rightSizePolicy?.requestsConfigs?.memory?.headroomPercentage ?? NO_OVERRIDE_NUMERIC_VALUE,
            histogramCPUPercentile:
              overridePolicy?.rightSizePolicy?.requestsConfigs?.cpu?.percentilePercentage ?? NO_OVERRIDE_NUMERIC_VALUE,
            histogramMemoryPercentile:
              overridePolicy?.rightSizePolicy?.requestsConfigs?.memory?.percentilePercentage ??
              NO_OVERRIDE_NUMERIC_VALUE,
            cpuMinAllowed: cpuQuantityToScalar(overridePolicy?.rightSizePolicy?.requestsConfigs?.cpu?.minAllowed),
            memoryMinAllowed: memoryQuantityToScalar(
              overridePolicy?.rightSizePolicy?.requestsConfigs?.memory?.minAllowed
            ),
            cpuMaxAllowed: cpuQuantityToScalar(overridePolicy?.rightSizePolicy?.requestsConfigs?.cpu?.maxAllowed),
            memoryMaxAllowed: memoryQuantityToScalar(
              overridePolicy?.rightSizePolicy?.requestsConfigs?.memory?.maxAllowed
            ),
            limitStrategyCpu: getLimitStrategy(overridePolicy?.rightSizePolicy?.limitConfigs?.cpu),
            cpuLimitsSetLimitValue: cpuQuantityToScalar(overridePolicy?.rightSizePolicy?.limitConfigs?.cpu?.setLimit),
            cpuLimitsSetLimitRequestRatioValue:
              overridePolicy?.rightSizePolicy?.limitConfigs?.cpu?.setLimitRequestRatio,
            limitStrategyMemory: getLimitStrategy(overridePolicy?.rightSizePolicy?.limitConfigs?.memory),
            memoryLimitsSetLimitValue: memoryQuantityToScalar(
              overridePolicy?.rightSizePolicy?.limitConfigs?.memory?.setLimit
            ),
            memoryLimitsSetLimitRequestRatioValue:
              overridePolicy?.rightSizePolicy?.limitConfigs?.memory?.setLimitRequestRatio,
            deploymentRecommendationType:
              overridePolicy?.updatePolicy?.updateByTypeMode?.deployment ?? NO_OVERRIDE_STRING_VALUE,
            statefulSetRecommendationType:
              overridePolicy?.updatePolicy?.updateByTypeMode?.statefulSet ?? NO_OVERRIDE_STRING_VALUE,
            daemonSetRecommendationType:
              overridePolicy?.updatePolicy?.updateByTypeMode?.daemonSet ?? NO_OVERRIDE_STRING_VALUE,
            deploymentConfigRecommendationType:
              overridePolicy?.updatePolicy?.updateByTypeMode?.deploymentConfig ?? NO_OVERRIDE_STRING_VALUE,
            argoRolloutRecommendationType:
              overridePolicy?.updatePolicy?.updateByTypeMode?.argoRollout ?? NO_OVERRIDE_STRING_VALUE,
            familyRecommendationType:
              overridePolicy?.updatePolicy?.updateByTypeMode?.family ?? NO_OVERRIDE_STRING_VALUE,
            keepCpuRequest: booleanToNumber(overridePolicy?.rightSizePolicy?.requestsConfigs?.cpu?.keepRequest),
            keepMemoryRequest: booleanToNumber(overridePolicy?.rightSizePolicy?.requestsConfigs?.memory?.keepRequest),
            historyWindowCpu: overridePolicy?.rightSizePolicy?.windowByResource?.cpu,
            historyWindowMemory: overridePolicy?.rightSizePolicy?.windowByResource?.memory,
            selectedPolicy: selectedPolicyData,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            cpuMinAllowed: nullablePositiveLimitValidation,
            memoryMinAllowed: nullablePositiveLimitValidation,
            cpuMaxAllowed: nullablePositiveLimitValidation,
            memoryMaxAllowed: nullablePositiveLimitValidation,
            cpuLimitsSetLimitValue: nullablePositiveLimitValidation,
            cpuLimitsSetLimitRequestRatioValue: nullablePositiveNumberValidation,
            memoryLimitsSetLimitRequestRatioValue: nullablePositiveNumberValidation,
          })}
          enableReinitialize
        >
          {({ dirty, resetForm }) => (
            <Form className="flex flex-col gap-5 grow">
              <div className="flex w-full grow min-h-[485px]">
                <SideMenu
                  selectedMenuItem={selectedMenuItem}
                  setSelectedMenuItem={(value: string) => setSelectedMenuItem(value as MenuItemType)}
                  menuItems={menuItems}
                  className="shadow-none md:shadow-none border border-r border-border rounded rounded-r-none rounded-tl-none pr-6 bg-white "
                  noPaddingForFirstLevel={true}
                />
                <div
                  className={
                    "flex border border-l-0 border-border rounded rounded-l-none py-10 px-16 w-full overflow-y-auto h-[485px]"
                  }
                >
                  {selectedMenuItem === MenuItemType.REQUEST && (
                    <div className="flex flex-col gap-5 h-fit">
                      <RequestsHeadroom selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <HistogramRequestPercentileConfiguration selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <MinimumResourceBoundaries selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <MaximumResourceBoundaries selectedPolicy={selectedPolicyData} />
                      <hr className="mt-2" />
                      <KeepRequest />
                    </div>
                  )}
                  {selectedMenuItem === MenuItemType.LIMIT && <LimitsStrategy selectedPolicy={selectedPolicyData} />}
                  {selectedMenuItem === MenuItemType.AUTOMATION && (
                    <ApplyingRecommendationSettings selectedPolicy={selectedPolicyData} />
                  )}
                </div>
              </div>
              <ActionButtons resetForm={resetForm} isFormDirty={dirty} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default PolicyTab;
