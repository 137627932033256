import PermissionsContainer from "./PermissionsContainer";
import { PAGE_CONTENT_CLASS_NAME } from "../../../utils/styleUtils";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import clsx from "clsx";
import * as React from "react";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import Paper from "@mui/material/Paper";
import CloseIcon from "../../../Icons/CloseIcon";
import Users from "../../../components/users";
import {useEffect} from "react";
import {useQueryParam} from "use-query-params";
import {GetConfig} from "../../../utils/ConfigHelper";
import useIsReadOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import {ADMIN_ROLE} from "../../../utils/typesUtils";

enum TabOptions {
  SETUP = "Setup",
  USERS = "Users",
  GROUPS = "Groups",
}

const AuthContainer = () => {
  const conf = GetConfig();
  const isReadOnlyFrontEnd = useIsReadOnlyFrontEnd();
  const { userRole, isError } = useGetUserRole();
  const [selectedQueryTab, setSelectedQueryTab] = useQueryParam("selectedQueryTab");
  const [selectedTab, setSelectedTab] = React.useState<TabOptions>((selectedQueryTab as TabOptions) ?? TabOptions.SETUP);

  useEffect(() => {
    setSelectedQueryTab(selectedTab as string);
  }, [selectedTab]);

  if (isError)
    return (
      <Paper variant="outlined" className="w-full h-[70vh] bg-white flex justify-center items-center gap-2">
        <CloseIcon />
        <p>Error fetching permissions</p>
      </Paper>
    );

  return (
    <div className={clsx("relative mt-[36px] rounded-tl-none p-[50px]", PAGE_CONTENT_CLASS_NAME)}>
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-0")}>
        {Object.entries(TabOptions).map(([key, value]) => {
          let isTabDisabled = false;
          let toolTipContent = undefined;
          const isSSODisabled = (value === TabOptions.GROUPS || value === TabOptions.USERS) && conf?.ssoConf?.provider === "none";
          const isSimpleAuth = value === TabOptions.GROUPS && conf?.ssoConf?.provider === "simpleAuth";
          const isAuthentication = (value === TabOptions.GROUPS || value === TabOptions.USERS) && !conf?.rbacEnabled && conf?.ssoConf?.provider !== "simpleAuth";
          const notAdmin = userRole !== ADMIN_ROLE
          if (notAdmin) {
            isTabDisabled = true
            toolTipContent = <>
              You <b>don't have permission</b> to access this tab.
              <br />
              Only <b>Admins</b> can access this tab.
            </>;
          } else if (isSimpleAuth) {
            isTabDisabled = true
            toolTipContent = <>
              This tab is <b>disabled</b> because you are using <b>Simple Auth</b> as your authentication method.
            </>
          } else if (isReadOnlyFrontEnd) {
            isTabDisabled = true
            toolTipContent = <>
              This tab is <b>disabled</b> because you are in <b>read-only mode</b>.
            </>
          } else if (isAuthentication) {
            isTabDisabled = true
            toolTipContent = <>
              This tab is <b>disabled</b> because you don't have <b>Authorization</b> enabled.
            </>
          } else if (isSSODisabled) {
            isTabDisabled = true
            toolTipContent = <>
              This tab is <b>disabled</b> because you don't have <b>SSO</b> enabled.
            </>
          }
          return (
            <Tab
              key={key}
              tooltipContent={toolTipContent}
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={value}
              dataTestId={`settings-tab-${key}-tab`}
              disabled={isTabDisabled}
            />
          );
        })}
      </div>
      {TabOptions.SETUP === selectedTab && (
        <PermissionsContainer />
      )}
      {TabOptions.USERS === selectedTab && (
        <Users />
      )}
      {TabOptions.GROUPS === selectedTab && (
        <Users showGroups />
      )}
    </div>
  );
}
export default AuthContainer;