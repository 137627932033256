import CircularProgress from "@mui/material/CircularProgress";
import YamlViewer from "../../common/YamlViewer";
import { ApiError } from "openapi-typescript-fetch";

interface Props {
  name: string;
  yaml: string;
  isLoading: boolean;
  error: ApiError | null;
}

const WorkloadHpaYaml = ({ name, yaml, isLoading, error }: Props) => {
  if (error) {
    console.error("Failed fetching YAML: ", error.message, error.data);
    return <div className="flex items-center justify-center h-[570px]">Failed fetching YAML</div>;
  }
  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center h-[570px]">
          <CircularProgress />
        </div>
      )}
      {!isLoading && <YamlViewer yaml={yaml} maxLines={30} minLines={30} exportFileName={name} />}
    </>
  );
};

export default WorkloadHpaYaml;
