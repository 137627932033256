import dayjs from "dayjs";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";

export type DateType = {
  from?: number;
  to?: number;
  range?: string;
};

export enum GroupBy {
  Hour = "hour",
  Day = "day",
  Week = "week",
}

export const GroupByToTimePeriod = (groupBy: GroupBy) => {
  switch (groupBy) {
    case GroupBy.Hour:
      return "Hourly";
    case GroupBy.Day:
      return "Daily";
    case GroupBy.Week:
      return "Weekly";
    default:
      return "";
  }
};

export const getFromDate = (daysAgo: number, currentDate: number) => {
  const date = new Date(currentDate);
  date.setDate(date.getDate() - daysAgo);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export const getMemoryValue = (memory: number) => {
  const memoryInGiB = memory / 1024 / 1024 / 1024;
  const memoryInMiB = memory / 1024 / 1024;
  if (memoryInGiB >= 1) {
    return { value: memoryInGiB, unit: "GiB" };
  } else if (memoryInMiB >= 1) {
    return { value: memoryInMiB, unit: "MiB" };
  } else {
    return { value: memory, unit: "KiB" };
  }
};

export const getShortNumber = (number: number) => {
  const numberInK = number / 1000;
  const numberInM = number / 1000000;
  const numberInB = number / 1000000000;
  if (numberInB >= 1) {
    return { value: numberInB, unit: "B" };
  } else if (numberInM >= 1) {
    return { value: numberInM, unit: "M" };
  } else if (numberInK >= 1) {
    return { value: numberInK, unit: "K" };
  } else {
    return { value: number, unit: "" };
  }
};

export const CORE_ELEMENT_CLASS_NAME = "w-full rounded-lg bg-white shadow-sm relative";

export const getNearestSunday = (epochTime: number): number => {
  const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  const date = new Date(epochTime);
  const dayOfWeek = date.getDay();
  return epochTime - dayOfWeek * ONE_DAY_IN_MILLISECONDS;
};

type ParseDataType = {
  values?:
    | {
        timestamp?: string | undefined;
        countValues?:
          | {
              [key: string]: number | undefined;
            }
          | undefined;
        values?:
          | {
              [key: string]: number | undefined;
            }
          | undefined;
        secondaryValues?:
          | {
              [key: string]: number | undefined;
            }
          | undefined;
        tooltipData?: {
          [key: string]:
            | {
                [key: string]: string | number | undefined;
              }
            | undefined;
        };
      }[]
    | undefined;
};

export const getMissingData = (
  originalData:
    | {
        date: string;
      }[],
  startDate: number,
  endDate: number,
  groupBy?: string
):
  | {
      [key: string]: string | number;
    }[]
  | undefined => {
  if (groupBy === GroupBy.Week) {
    startDate = getNearestSunday(startDate);
    endDate = getNearestSunday(endDate);
  }

  const allProperties = originalData.reduce((acc, entry) => {
    const properties = Object.keys(entry).filter((key) => key !== "date");
    return [...acc, ...properties];
  }, [] as string[]);

  const initialProperties = allProperties.reduce((acc, property) => {
    return {
      ...acc,
      [property]: 0,
    };
  }, {} as { [key: string]: number });

  let dates: (string | number)[] = [];
  let currentDate = endDate;
  while (currentDate >= startDate) {
    dates = [...dates, currentDate];
    if (groupBy === GroupBy.Day) {
      currentDate = currentDate - 86400000;
    } else if (groupBy === GroupBy.Hour) {
      currentDate = currentDate - 3600000;
    } else if (groupBy === GroupBy.Week) {
      currentDate = currentDate - 7 * 86400000;
    }
  }

  dates = dates.sort((a, b) => Number(a) - Number(b));

  dates = dates.map((date) => dayjs(date).format(groupBy === GroupBy.Hour ? "DD/MMM/YYYY HH:mm" : "DD/MMM/YYYY"));

  const newDate = dates.map((date) => {
    const originalDataPoint = originalData.find((entry) => entry.date === date);
    return originalDataPoint ? originalDataPoint : { date: date, ...initialProperties };
  });

  return newDate;
};

export type ParsedDataResponse =
  | {
      [key: string]: string | number | ({ name: string; value: number } | null)[];
    }[]
  | {
      secondaryValues?:
        | {
            [key: string]: number | undefined;
          }
        | undefined;
      toolTipData?:
        | {
            [key: string]:
              | {
                  [key: string]: string | number | undefined;
                }
              | undefined;
          }
        | ({ name: string; value: number } | null)[]
        | undefined;
      date: string;
    }[];

export const getParsedData = (
  data: ParseDataType,
  showPercentage?: boolean,
  shouldRoundValues?: boolean,
  startDate?: number,
  endDate?: number,
  groupBy?: string,
  isDemoVersion?: boolean,
  shouldGetMissingData?: boolean,
  withHours?: boolean,
  fixedValue?: number
) => {
  const originalData = data?.values?.map((entry) => {
    const parsedValues =
      entry.values &&
      Object.entries(entry.values).reduce((acc, [key, value]) => {
        if (value && value < 0) value = Math.max(value, 0);
        let displayValue = value ? Math.round(value * 100) / 100 : 0;
        displayValue = showPercentage ? Math.round(displayValue * 100) : displayValue;
        displayValue = shouldRoundValues ? Math.round(displayValue) : displayValue;
        return {
          ...acc,
          [key]: displayValue,
        };
      }, {});
    return {
      date:
        isDemoVersion || withHours
          ? adjustedDayjs(entry.timestamp).format("DD/MMM/YYYY HH:mm")
          : adjustedDayjs(entry.timestamp).format("DD/MMM/YYYY"),
      ...(entry?.tooltipData ? { tooltipData: entry?.tooltipData } : {}),
      ...Object.entries(parsedValues ?? {}).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: fixedValue ? fixedValue : value,
        };
      }, {}),
      countValues: entry.countValues,
      ...(entry.secondaryValues
        ? {
            secondaryValues: Object.entries(entry.secondaryValues).reduce((acc, [key, value]) => {
              return {
                ...acc,
                [key]: value,
              };
            }, {}),
          }
        : {}),
    };
  });

  if (isDemoVersion) {
    return originalData ?? [];
  }

  let finalData;
  if (shouldGetMissingData) {
    const startDateParam = startDate ?? adjustedDayjs(originalData?.[0]?.date).unix() * 1000;
    const endDateParam = endDate ?? adjustedDayjs(originalData?.[originalData.length - 1]?.date).unix() * 1000;
    finalData =
      originalData && startDateParam && endDateParam && groupBy
        ? getMissingData(originalData, startDateParam, endDateParam, groupBy)
        : [];
  } else {
    finalData = originalData;
  }

  return finalData ?? [];
};
