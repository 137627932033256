const Terms = () => (
  <div className="text-xs text-center py-8">
    * Please note that by installing the ScaleOps platform, you are accepting our{" "}
    <a
      href="https://scaleops.com/wp-content/uploads/2024/11/ScaleOps-Terms-of-Use-November-19-2024-clean.pdf"
      className="text-main-blue"
    >
      Terms of use
    </a>{" "}
    and{" "}
    <a
      href="https://scaleops.com/wp-content/uploads/2024/11/ScaleOps-Privacy-Policy-November-10-2024.pdf"
      className="text-main-blue"
    >
      Privacy policy
    </a>
  </div>
);

export default Terms;
