import FormSingleSelect from "../../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../../components/formComponents/FormTitleAndDescription";
import { getDisabledByKeepRequestTooltipContent, NO_OVERRIDE_NUMERIC_VALUE, PolicyType } from "./utils";
import { useFormikContext } from "formik";
import PolicyValue from "./PolicyValue";

const options: [string, number][] = [
  ["No override", NO_OVERRIDE_NUMERIC_VALUE],
  ["0%", 0],
  ["5%", 5],
  ["10%", 10],
  ["15%", 15],
  ["20%", 20],
  ["50%", 50],
  ["100%", 100],
  ["200%", 200],
];

interface Props {
  selectedPolicy?: PolicyType;
}

const RequestsHeadroom = ({ selectedPolicy }: Props) => {
  const { values } = useFormikContext<{
    keepCpuRequest: number;
    keepMemoryRequest: number;
  }>();

  return (
    <div className="flex flex-col gap-4 pb-2">
      <FormTitleAndDescription
        title="Request headroom"
        description="Define requests headroom for container resources."
        info={
          <>
            This value calculated by the <b>resource request percentiles</b> and can be editing on advanced tab.
          </>
        }
      />
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col gap-1">
          <FormSingleSelect
            label="CPU %"
            name="cpuHeadroom"
            options={options}
            disabled={values.keepCpuRequest > 0}
            tooltipContent={values.keepCpuRequest > 0 ? getDisabledByKeepRequestTooltipContent("CPU") : undefined}
          />
          <PolicyValue
            value={`${
              selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.headroomPercentage ?? ""
            }%`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <FormSingleSelect
            label="Memory (%)"
            name="memoryHeadroom"
            options={options}
            disabled={values.keepMemoryRequest > 0}
            tooltipContent={values.keepMemoryRequest > 0 ? getDisabledByKeepRequestTooltipContent("memory") : undefined}
          />
          <PolicyValue
            value={`${
              selectedPolicy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.headroomPercentage ?? ""
            }%`}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestsHeadroom;
