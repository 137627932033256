import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, ObjectParam, useQueryParam } from "use-query-params";
import {
  GetNetworkObservabilityEnabled,
  GetNetworkObservabilityEnabledResponse,
  GetObservabilityWorkloadApis,
  GetObservabilityWorkloadApisResponse,
} from "../../../../api/fetcher";
import CodeSnippet, { THEME } from "../../../../components/CodeSnippet";
import { ChartData, SetDate } from "../../../../components/ComposeChart/utils";
import CustomSelectedFilterChip from "../../../../components/CustomSelectedFilterChip";
import MultiSelectByQueryParams from "../../../../components/MultiSelectByQueryParams";
import BinocularsIcon from "../../../../Icons/BinocularsIcon";
import useGetCustomerToken from "../../../Cost/hooks/useGetCustomerToken";
import useGetVersion from "../../../Cost/hooks/useGetVersion";
import SelectViewPeriod from "../SelectViewPeriod";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import ErrorChart from "./Charts/ErrorChart";
import LatencyChart from "./Charts/LatencyChart";
import RequestChart from "./Charts/RequestChart";
import { eBPFTableRow } from "./utils";
import WorkloadEbpfTable from "./WorkloadEbpfTable/WorkloadEbpfTable";

const { queryKey, queryFn } = GetObservabilityWorkloadApis();

interface Props {
  kind: string;
  name: string;
  namespace: string;
}

const WorkloadObservabilityContainer = ({ kind, name, namespace }: Props) => {
  kind = kind.toLowerCase();
  const [selectedViewPeriod] = useViewPeriodQueryParams();
  const [urlDates, setPolicyTuningDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);

  const [tableData, setTableData] = useState<eBPFTableRow[]>([]);
  const [requestChartData, setRequestChartData] = useState<ChartData>([]);
  const [errorChartData, setErrorChartData] = useState<ChartData>([]);
  const [latencyChartData, setLatencyChartData] = useState<ChartData>([]);

  const [allPaths, setAllPaths] = useState<string[]>([]);
  const [path, setPath] = useQueryParam("paths", ArrayParam);
  const [isPathsExclude] = useQueryParam("isPathsExclude", BooleanParam);

  const { data: customerTokenData } = useGetCustomerToken();
  const { data: versionData } = useGetVersion();

  const { data: networkObservabilityData } = useQuery<GetNetworkObservabilityEnabledResponse, Error>({
    queryKey: ["networkObservabilityEnabled"],
    queryFn: GetNetworkObservabilityEnabled,
  });

  const { data: pathData } = useQuery<GetObservabilityWorkloadApisResponse, Error>({
    queryKey: [queryKey, kind, name, namespace, urlDates?.from, urlDates?.to, selectedViewPeriod, undefined],
    queryFn: () =>
      queryFn({
        kind,
        name,
        namespace,
        paths: undefined,
        from:
          dayjs(
            urlDates?.from
              ? Number(urlDates.from) * 1000
              : dayjs.utc().subtract(Number(selectedViewPeriod ?? 1), "hour")
          ).unix() * 1000,
        to: dayjs(urlDates?.to ? Number(urlDates.to) * 1000 : dayjs.utc()).unix() * 1000,
      }),
  });

  const { data, isLoading } = useQuery<GetObservabilityWorkloadApisResponse, Error>({
    queryKey: [queryKey, kind, name, namespace, urlDates?.from, urlDates?.to, selectedViewPeriod, path],
    queryFn: () =>
      queryFn({
        kind,
        name,
        namespace,
        paths: path && path.length > 0 ? path.filter((p): p is string => p !== null) : undefined,
        from:
          dayjs(
            urlDates?.from
              ? Number(urlDates.from) * 1000
              : dayjs.utc().subtract(Number(selectedViewPeriod ?? 1), "hour")
          ).unix() * 1000,
        to: dayjs(urlDates?.to ? Number(urlDates.to) * 1000 : dayjs.utc()).unix() * 1000,
      }),
  });

  const setDate: SetDate = ({ from, to }) => {
    setPolicyTuningDates({ from: String(Math.round(Number(from))), to: String(Math.round(Number(to))) });
  };

  useEffect(() => {
    /**
     * set the path data
     */

    const uniquePaths = new Set<string>();

    if (pathData?.httpAPIs) {
      pathData.httpAPIs.forEach((api) => {
        if (!api.path) return;
        uniquePaths.add(api.path);
      });
    }

    setAllPaths(Array.from(uniquePaths));
  }, [pathData]);

  useEffect(() => {
    /**
     * Set table data
     */

    if (data?.httpAPIs) {
      const tableDataToSet: eBPFTableRow[] = data.httpAPIs.map((api) => {
        return {
          id: api.path?.split("/").join("-") ?? "",
          path: api.path ?? "",
          method: api.method ?? "",
          protocol: api.protocol ?? "",
          // secure: api.secure ?? false, // TODO is optional
          errorRate: api.dataPoint?.errorRate,
          p50Latency: api.dataPoint?.p50Latency,
          p95Latency: api.dataPoint?.p95Latency,
          // p99Latency: api.dataPoint?.p99Latency,
          requestsPerSecond: api.dataPoint?.requestsPerSecond,
          // timestamp: api.dataPoint?.timestamp,
          // totalRequests: api.dataPoint?.totalRequests,
        };
      });

      setTableData(tableDataToSet);
    }

    /**
     * Set request chart data
     */

    if (data?.graphData?.requests) {
      const requestChartDataToSet: ChartData = data.graphData.requests.map((dataPoint) => {
        return {
          timestamp: dayjs(dataPoint.timestamp).toString(),
          values: {
            requests: Number((Number(dataPoint.values?.requests) / 60).toFixed(2)),
            ok: Number((Number(dataPoint.values?.ok) / 60).toFixed(2)),
            errors: Number((Number(dataPoint.values?.errors) / 60).toFixed(2)),
          },
        };
      });

      setRequestChartData(requestChartDataToSet);
    }

    /**
     * Set error chart data
     */

    if (data?.graphData?.errors?.values) {
      const errorChartDataToSet: ChartData = data.graphData.errors.map((dataPoint) => {
        const values = dataPoint.values
          ? Object.entries(dataPoint.values).reduce((acc, [key, value]) => {
              acc[key] = Number((Number(value) / 60).toFixed(2));
              return acc;
            }, {} as Record<string, number>)
          : {};
        return {
          timestamp: dayjs(dataPoint.timestamp).toString(),
          values,
        };
      });

      setErrorChartData(errorChartDataToSet);
    }

    /**
     * Set latency chart data
     */

    if (data?.graphData?.latency?.values) {
      const latencyChartDataToSet: ChartData = data.graphData.latency.map((dataPoint) => {
        let values = {};
        ["p50", "p95"].forEach((key) => {
          if (!Number(dataPoint.values?.[key])) {
            return;
          }
          values = {
            ...values,
            [key]: Number(dataPoint.values?.[key]),
          };
        });

        return {
          timestamp: dayjs(dataPoint.timestamp).toString(),
          values,
        };
      });

      setLatencyChartData(latencyChartDataToSet);
    }
  }, [data]);

  return (
    <div className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <div className="flex gap-10 w-full">
          <BinocularsIcon width={40} height={40} />
          <Typography variant="body2">
            <b>APIs</b>
            <p>Explore your workload APIs performance</p>
          </Typography>
        </div>
        {networkObservabilityData?.networkObservabilityEnabled && !isLoading && (
          <>
            <div className="h-16 w-[1px] bg-border" />
            <SelectViewPeriod />
          </>
        )}
      </div>

      {!networkObservabilityData?.networkObservabilityEnabled && !isLoading ? (
        <>
          <div className="border border-border rounded p-4">
            <Typography variant="caption">
              To enable this feature <b>add the following to the installation command</b>:
            </Typography>
          </div>
          <CodeSnippet
            codeSnippet={`
helm repo add scaleops --username scaleops \\
  --password ${customerTokenData?.token ?? "<SCALEOPS_TOKEN>"} \\
  ${versionData?.helmCommandConfig?.repositoryURL ?? "<SCALEOPS_REPOSITORY>"} \\
  --force-update

helm repo update scaleops
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${versionData?.namespace ?? "<SCALEOPS_NAMESPACE>"} -oyaml | \\
helm upgrade scaleops scaleops/scaleops -n ${
              versionData?.namespace ?? "<SCALEOPS_NAMESPACE>"
            } --set global.enableApiObservability=true -f -
`}
            theme={THEME.light}
            className="w-full"
          />
          <span className="text-[10px] italic text-text-darkGray">
            Note: This operation will <b>install a DaemonSet</b>.
          </span>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center gap-4">
            <RequestChart graphData={requestChartData} isLoading={isLoading} setDate={setDate} />
            <ErrorChart graphData={errorChartData} isLoading={isLoading} setDate={setDate} />
            <LatencyChart graphData={latencyChartData} isLoading={isLoading} setDate={setDate} />
          </div>

          <div className="w-full flex flex-col gap-2">
            <MultiSelectByQueryParams
              options={allPaths}
              queryKey="paths"
              excludeQueryKey="isPathsExclude"
              hasIsExclude
              hasVirtualizedList
              enableAddCustomValue
            />
          </div>
          {path && path.length && (
            <div className="flex items-center gap-2 w-full">
              {path?.map((p) => (
                <CustomSelectedFilterChip
                  key={p}
                  label={p}
                  onClick={() => {
                    setPath(path.filter((value) => value !== p));
                  }}
                />
              ))}
            </div>
          )}
          <WorkloadEbpfTable
            tableData={
              Array.isArray(path) && path.length > 0
                ? tableData.filter((value) => (isPathsExclude ? !path.includes(value.path) : path.includes(value.path)))
                : tableData
            }
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default WorkloadObservabilityContainer;
