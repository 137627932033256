import ChartTooltipElement from "../../ChartTooltipElement";
import ChartTooltipTime from "../../ChartTooltipTime";
import Styles, { AverageUsageColor, DIFF_COLOR } from "../ResourceOverTime/Styles";
import { HpaChartComponent } from "../../../pages/Overview/PolicyTuning/UsageChart/UsageHpaChart";
import { spacedCamelCase } from "../../../utils/formatterUtils";
import {OriginReplicasColor} from "../../../pages/Overview/PolicyTuning/UsageChart/Styles";

interface ToolTipOptionProps {
  label: HpaChartComponent;
  value: number | string;
  color: string;
  includedChartComponents: HpaChartComponent[];
  selectedChartComponents: HpaChartComponent[];
  valueSuffix?: React.ReactNode;
  customPrefix?: string;
}

const TooltipOption = ({
  label,
  value,
  color,
  includedChartComponents,
  selectedChartComponents,
  valueSuffix,
  customPrefix,
}: ToolTipOptionProps) => {
  if (!includedChartComponents.includes(label) || !selectedChartComponents.includes(label)) return null;

  const displayLabel = spacedCamelCase(label);

  return (
    <ChartTooltipElement
      color={color}
      value={
        <>
          {value}
          {valueSuffix}
        </>
      }
      label={displayLabel}
      customPrefix={customPrefix}
    />
    // <p
    //   style={{
    //     color: color,
    //   }}
    //   className="flex gap-2 items-center"
    // >
    //   <div
    //     className="w-4 h-2"
    //     style={{
    //       backgroundColor: color,
    //     }}
    //   />
    //   {camelCaseToSnakeSpace(label)}: {value}
    //   {valueSuffix}
    // </p>
  );
};

export type CustomTooltipPayload = {
  timestamps: string;
  currentReplicas: number;
  recommendedReplicas: number;
  replicasByTrigger: number;
  originalReplicas: number;
  waste: number;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    payload: CustomTooltipPayload;
  }[];
  getWasteValue?: (payload: CustomTooltipPayload) => number;
  includedChartComponents: HpaChartComponent[];
  selectedChartComponents: HpaChartComponent[];
  timeFormat?: string | undefined;
  valueFormatter: (value: number) => string | number;
};

const CustomTooltip = ({
  active,
  payload,
  getWasteValue,
  includedChartComponents,
  selectedChartComponents,
  valueFormatter,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const { timestamps, currentReplicas, recommendedReplicas, replicasByTrigger, originalReplicas } = payload[0].payload;
    if (!timestamps) return null;

    let diff = getWasteValue ? getWasteValue(payload[0].payload) : 0;
    diff = Math.round(diff * 100) / 100;
    if (diff < 0) diff = 0;

    return (
      <div className="bg-[rgba(255,255,255,0.75)] text-black py-4 px-8 rounded-lg border border-border flex flex-col text-[10px]">
        <ChartTooltipTime timestamp={timestamps} />
        {/* <TooltipOption
          label={ChartComponents.Allocatable}
          value={valueFormatter(allocatable)}
          color={ALLOCATABLE_COLOR}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        /> */}
        <TooltipOption
          label={"currentReplicas"}
          value={valueFormatter(currentReplicas)}
          color={Styles.currentRequest.stroke}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
            label={"originalReplicas"}
            value={valueFormatter(originalReplicas)}
            color={OriginReplicasColor}
            includedChartComponents={includedChartComponents}
            selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={"recommendedReplicas"}
          value={valueFormatter(recommendedReplicas)}
          color={Styles.recommendedRequest.stroke}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        {/* <TooltipOption
          label={ChartComponents.RecommendedAllocatable}
          value={valueFormatter(recommendedAllocatable)}
          color={Styles.recommendedAllocatable.stroke}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />*/}
        <TooltipOption
          label={"waste"}
          value={valueFormatter(diff)}
          color={DIFF_COLOR}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={"replicasByTrigger"}
          value={valueFormatter(replicasByTrigger)}
          color={AverageUsageColor}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        {/* <TooltipOption
          label={ChartComponents.GpuUsedMemory}
          customPrefix={"used memory"}
          value={valueFormatter(gpuUsedMemory)}
          color={SCALEOPS_COLORS.gpu.usedMemory}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuTotalMemory}
          customPrefix={"total memory"}
          value={valueFormatter(gpuTotalMemory)}
          color={SCALEOPS_COLORS.gpu.totalMemory}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuSmActive}
          customPrefix={"average utilization"}
          value={valueFormatter(gpuSmActive)}
          color={SCALEOPS_COLORS.gpu.smActive}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        />
        <TooltipOption
          label={ChartComponents.GpuUtilization}
          customPrefix={"% of active GPUs"}
          value={valueFormatter(gpuUtilization)}
          color={SCALEOPS_COLORS.gpu.utilization}
          includedChartComponents={includedChartComponents}
          selectedChartComponents={selectedChartComponents}
        /> */}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
