import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";
import MultiSelectByQueryParams from "../../MultiSelectByQueryParams";
import Tooltip from "../../Tooltip";
import { QueryParamKey } from "./utils";

const getBlockedReasonDisplayName = (blockedReason: string) => camelCaseToSnakeSpace(blockedReason.replaceAll("-", ""));
interface Props {
  existingBlockedReasons: {
    reason: string;
    count: number;
  }[];
}

const BlockedNodesFilters = ({ existingBlockedReasons }: Props) => {
  const [search, setSearch] = useQueryParam(QueryParamKey.BlockedNodesSearchTerm, StringParam);

  return (
    <div className="flex items-center gap-5">
      <input
        placeholder="search..."
        value={search || ""}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        className="border border-border rounded-md px-2 py-1 focus:outline-none w-[250px]"
      />
      <MultiSelectByQueryParams
        options={existingBlockedReasons?.map((entity) => entity.reason) || []}
        queryKey={QueryParamKey.BlockedNodesReasons}
        name="blocked reasons"
        isSearchable={false}
        hasIsExclude={false}
        hasVirtualizedList
        disableSort
        optionRenderFunction={(
          option: string,
          index: number,
          selected: (string | undefined)[],
          dataTestId?: string
        ) => {
          return (
            <MenuItem
              value={option}
              key={`${index}-multi-select-option`}
              data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
            >
              <div className="min-w-[235px] flex items-center w-full">
                <Checkbox checked={selected.indexOf(option) > -1} />
                <Tooltip
                  title={getBlockedReasonDisplayName(option)}
                  maxWidth={700}
                  className="flex gap-2 items-center truncate w-full"
                >
                  <ListItemText
                    primary={<p className="truncate max-w-[430px] grow">{getBlockedReasonDisplayName(option)}</p>}
                  />
                  <span className="px-4 bg-guideline-darkGreen font-bold text-white text-[10px] rounded-lg ml-2">
                    {existingBlockedReasons.find((entity) => entity.reason === option)?.count}
                  </span>
                </Tooltip>
              </div>
            </MenuItem>
          );
        }}
      />
    </div>
  );
};

export default BlockedNodesFilters;
