import clsx from "clsx";
import { useEffect, useState } from "react";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import Button from "../Button";
import Tooltip from "../Tooltip";

const SaveButtonTooltipContent = {
  builtIn: <>Built-in dashboards cannot be modified or deleted.</>,
  customNotModified: <>Change the layout to enable the save button.</>,
  customModified: <>Save the current layout.</>,
};

interface Props {
  saveDashboard: () => void;
  wasLayoutChanged: boolean;
  setIsDuplicateDialogueOpen: (isDuplicateDialogueOpen: boolean) => void;
  isReadOnly?: boolean;
}

const SaveAndDuplicateButtons = ({
  saveDashboard,
  wasLayoutChanged,
  setIsDuplicateDialogueOpen,
  isReadOnly,
}: Props) => {
  const [saveButtonContent, setSaveButtonContent] = useState<React.ReactNode>(null);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState<boolean>(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setIsSaveButtonActive(!isReadOnly && wasLayoutChanged);
    }, 300);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [wasLayoutChanged, isReadOnly]);

  useEffect(() => {
    switch (true) {
      case isReadOnly:
        setSaveButtonContent(SaveButtonTooltipContent.builtIn);
        break;
      case wasLayoutChanged:
        setSaveButtonContent(SaveButtonTooltipContent.customModified);
        break;
      default:
        setSaveButtonContent(SaveButtonTooltipContent.customNotModified);
        break;
    }
  }, [wasLayoutChanged, isReadOnly]);

  return (
    <div className="flex gap-4 items-center">
      <Tooltip title={saveButtonContent} disabled={!isSaveButtonActive} className="relative">
        <Button
          onClick={saveDashboard}
          label="Save"
          disabled={!isSaveButtonActive}
          className={clsx({ "animate-bounce": wasLayoutChanged && isSaveButtonActive })}
          style={{
            animationIterationCount: 3,
          }}
        />
      </Tooltip>
      <Tooltip title="Duplicate dashboard">
        <DuplicateIcon
          onClick={() => {
            setIsDuplicateDialogueOpen(true);
          }}
          width={24}
          height={24}
          className="cursor-pointer"
        />
      </Tooltip>
    </div>
  );
};

export default SaveAndDuplicateButtons;
