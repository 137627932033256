import {
  OverrideRecommendationContainerData,
  OverrideRecommendationContainerDataParams,
} from "../../../../../api/fetcher";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ApiError } from "openapi-typescript-fetch";
import { Form, Formik } from "formik";
import ActionButtons from "../ActionButtons";
import { Container, ephemeralStorageQuantityToScalar } from "./utils";
import { useMemo } from "react";
import EphemeralStorage from "./EphemeralStorage";

interface Props {
  namespace: string;
  name: string;
  kind: string;
  setSuccessfulSave: (successfulSave: boolean) => void;
  containers?: Container[];
}

const EphemeralStorageTab = ({ namespace, name, kind, setSuccessfulSave, containers }: Props) => {
  const { queryFn: overrideQueryFn } = OverrideRecommendationContainerData();
  const mutation = useMutation((params: OverrideRecommendationContainerDataParams) => overrideQueryFn(params), {
    onSuccess: (data) => {
      setSuccessfulSave(true);
      const message = "Override successfully saved!";
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      console.log(message, data);
    },
    onError: (error: ApiError) => {
      const message = "Error saving override";
      toast.error(message);
      console.log(message, error.message);
    },
  });

  const handleSubmit = (values: { [key: string]: number | undefined }) => {
    setSuccessfulSave(false);

    const containerData = Object.keys(values).reduce((acc, containerName) => {
      acc[containerName] = {
        requests: {
          ephemeralStorage: values[containerName] ? `${values[containerName] ?? ""}Mi` : "",
        },
      };
      return acc;
    }, {} as { [key: string]: { requests: { ephemeralStorage: string } } });
    mutation.mutate({
      name: `${kind.toLowerCase()}-${name}`,
      namespace,
      containerData,
    });
  };

  const formInitialValues = useMemo(() => {
    const initialValues: { [key: string]: number | undefined } = {};
    containers?.forEach((container) => {
      const containerName = container?.containerName ?? "";
      initialValues[containerName] =
        typeof container?.requests?.["ephemeral-storage"] === "string"
          ? ephemeralStorageQuantityToScalar(container?.requests["ephemeral-storage"] as string) ?? undefined
          : undefined;
    });
    return initialValues;
  }, [containers]);
  return (
    <>
      <Formik
        key={JSON.stringify(formInitialValues)}
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ dirty, resetForm }) => (
          <Form className="flex flex-col gap-5 grow">
            <div
              className={
                "flex flex-col border border-border rounded rounded-l-none py-10 px-16 w-full overflow-y-auto h-[485px] gap-10"
              }
            >
              {!containers?.length && "No Containers"}
              {containers?.map((container) => {
                return (
                  <EphemeralStorage namespace={namespace} name={name} containerName={container?.containerName ?? ""} />
                );
              })}
            </div>
            <ActionButtons resetForm={resetForm} isFormDirty={dirty} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EphemeralStorageTab;
